import { Component, OnInit } from '@angular/core';
import { HostService } from 'src/app/services/host.service';
import { DanhmucService } from './../../services/danhmuc.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  CheckUserAD: boolean = false;
  objsidebar: any = {};
  dataitem : any = {};
  constructor(
    // Route:Routes,
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    // this.CheckView('TyTrongVung');
    // this.CheckView('CongTrinhVung');
    // this.CheckView('ChiSoGiaVung');
    this.dataitem.LoaiCSG = '';
    this.dataitem.Ten = JSON.parse(localStorage.getItem('currentUser')).UserName;
    if(JSON.parse(localStorage.getItem('thongtinuser')) != undefined && JSON.parse(localStorage.getItem('thongtinuser')) != {}){
      this.dataitem.LoaiCSG = JSON.parse(localStorage.getItem('thongtinuser')).LoaiTinhCSG;
    }
    this.CheckUser();
  }

  CheckUser() {
    this.DanhmucService.CheckUser().subscribe(res => {
      this.CheckUserAD = res;
    });
  }

  CheckView(TenView) {
    this.DanhmucService.CheckView(TenView).subscribe(res => {
      switch (TenView) {
        case "TyTrongVung":
          this.objsidebar.TyTrongVung = res;
          break;
        case "CongTrinhVung":
          this.objsidebar.CongTrinhVung = res;
          break;
        case "ChiSoGiaVung":
          this.objsidebar.ChiSoGiaVung = res;
          break;
      }
    });
  }

  // xulymenu(url) {
  //   return this.Route.isActive(url, false);
  // }
  fileHelp() {
    window.open(`${HostService.serverUrl}help_CSG/TieuDe.htm`);
  }
  dangNhap() {
    window.open(`${HostService.serverUrl}help_CSG/1.htm`);
  }
  danhSach() {
    window.open(`${HostService.serverUrl}help_CSG/2.htm`);
  }
  tinhChiSoGia() {
    window.open(`${HostService.serverUrl}help_CSG/3.htm`);
  }
  thuThapDuLieu() {
    window.open(`${HostService.serverUrl}help_CSG/4.htm`);
  }
  tyTrong() {
    window.open(`${HostService.serverUrl}help_CSG/5.htm`);
  }
  chiSoGia() {
    window.open(`${HostService.serverUrl}help_CSG/6.htm`);
  }
  baoCao() {
    window.open(`${HostService.serverUrl}help_CSG/7.htm`);
  }
  danhMuc() {
    window.open(`${HostService.serverUrl}help_CSG/8.htm`);
  }
}
