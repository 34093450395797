import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DanhmucService } from './../../../../services/danhmuc.service';

@Component({
  selector: 'app-capnhatdanhmucchung',
  templateUrl: './capnhatdanhmucchung.component.html',
  styleUrls: ['./capnhatdanhmucchung.component.css']
})
export class CapnhatDanhMucDungChungComponent implements OnInit {

  IDParent: any = {};
  ItemPOST: any = {};
  dmCongTrinhDienHinh: any = {};
  Nam: any = 0;
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  listkhoadulieu: any = [];
  IDThongTinUser : any = 0;
  LoaiDanhMuc : any = "";
  header: any ="";
  dataitem: any = {};
  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    this.ItemPOST = Object.assign({}, this.IDParent);
    this.Nam = Object.assign(this.Nam);
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    this.dataitem.Ten = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.IDUser = JSON.parse(localStorage.getItem('currentUser')).UserName;
    // this.IDUSER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser'))).Id;
    // console.log(JSON.parse(localStorage.getItem('currentUsers')).UserName)
    if(this.header == undefined || this.header == ""){
      this.header = "Thêm mới"
    }
  }

  XacNhan() {
    let data:any={
      "ID":this.ItemPOST.ID,
      "Ten":this.ItemPOST.Ten,
      "Nam":this.ItemPOST.Nam != undefined ? this.ItemPOST.Nam : this.dataitem.Nam,
      "GhiChu":this.ItemPOST.GhiChu,
      "LoaiDanhMuc": this.LoaiDanhMuc,
      "IDUser": this.dataitem.IDUser,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "IDdmTinh": this.IDdmTinh,
      "listdmLoaiCongTrinh": this.ItemPOST.listdmLoaiCongTrinh,
      "listdmVatLieuNhanCongMay": this.ItemPOST.listdmVatLieuNhanCongMay,
      "listdmCongTrinhDienHinh": this.ItemPOST.listdmCongTrinhDienHinh,
      "dmCongTrinhDienHinh": this.dmCongTrinhDienHinh,
    }
    this.DanhmucService.SetdmDungChung(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
