import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../services/api.service';
@Component({
  selector: 'app-loaicongtrinh',
  templateUrl: './loaicongtrinh.component.html',
  styleUrls: ['./loaicongtrinh.component.css']
})
export class LoaicongtrinhComponent implements OnInit {

  Listtree: any = [];

  listtinh: any = [];
  listkhuvuc: any = [];
  List: any = [];

  itemSELeCT: any = {};
  quyenxoa: boolean = false;
  CheckUserAD: boolean = false;
  dataitem: any = {};
  checkListtree: any = [];
  Listpost: any = [];
  dataCheck: any = {
    itemCheck: {},
    List_Check: [],
  }
  TimChaTree: any = [];
  constructor(
    private ApiService: ApiService,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined) {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    }
    this.CheckUser();
    this.GetListdmTinh();
  }

  CheckUser() {
    this.DanhmucService.CheckUser().subscribe(res => {
      this.CheckUserAD = res;
    });
  }
  GetListdmTinh() {
    this.dataitem.IDdmTinh = undefined;
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if (res.length > 0) {
        this.dataitem.IDdmTinh = res[0].ID;
      }
      this.GetListdmLoaiCongTrinh();
    });
  }
  GetListdmLoaiCongTrinh(isThemMoi?) {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetdsLoaiCongTrinh(data).subscribe(resds => {
      this.checkListtree = resds.items;
      this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(res => {
        res.filter(obj => {
          obj.collspan = true;
          obj.check = false;
          obj.select = false;
        });
        this.Listtree = Object.assign([], res);
        this.List = Object.assign([], res);
        for (let i = 0; i < this.Listtree.length; i++) {
          for (let j = 0; j < this.checkListtree.length; j++) {
            if (this.Listtree[i].ID == this.checkListtree[j].IDdmLoaiCongTrinh) {
              this.Listtree[i].check = true;
              this.Listtree[i].IDdatontai = this.checkListtree[j].ID;
              break;
            }
            else {
              this.Listtree[i].IDdatontai = 0;
            }
          }
        }

        this.TimChaTree = this.Listtree;
        if (isThemMoi === true) {
          this.itemSELeCT = this.Listtree.find(ele => ele.ID === this.dataCheck.itemCheck.ID)
          this.itemSELeCT.select = true;
        }
        else {
          if (this.itemSELeCT.ID === undefined || this.itemSELeCT.ID === null) {
            this.Listtree[0].select = true;
            this.itemSELeCT = this.Listtree[0];
          }
          else {
            let itemFind = res.filter(e => e.ID === this.itemSELeCT.ID);
            if (itemFind != undefined) {
              itemFind[0].select = true;
              this.itemSELeCT = itemFind[0];
            }

          }
        }
        this.Listtree = this.MyService.unflatten(this.Listtree);
        this.Listtree.forEach(element => {
          let dataFind = this.dataCheck.List_Check.find(ele => ele.ID == element.ID);
          if (dataFind !== undefined) {
            if (dataFind.collspan === false)
              element.collspan = false
            this.deQuydmLoaiCongTrinh(element.children, dataFind.children, element);
          }
        });
        // if (isThemMoi === true) {
        //   this.scrollToCenter();
        // }
      });
    });
  }

  deQuydmLoaiCongTrinh(listChild, listChildCheck, itemParent) {
    listChild.forEach(elChi => {
      let itemCheck = listChildCheck.find(ele => ele.ID == elChi.ID);

      if (itemParent.collspan == false) {
        elChi.collspan = false;
      }
      else if (itemCheck !== undefined) {
        if (itemCheck.collspan === false)
          elChi.collspan = false;
      }
      if (itemCheck === undefined)
        itemCheck = {};
      this.deQuydmLoaiCongTrinh(elChi.children, itemCheck.children, elChi);
    });
  }

  CheckTREE(item) {
    item.check = !item.check;
    if (item.check) {
      if (item.IDParent != 0) {
        this.DeQuyCheckTreeConCha(item)
        this.DeQuyCheckTreeChaCon(item.children, item)
      }
      else {
        this.DeQuyCheckTreeChaCon(item.children, item)
      }
      this.Listtree.forEach(element => {
        if (element.check == true) {
          this.DeQuyCheckTree(element);
        }
      });
    }
    else {
      if (item.IDdatontai > 0) {
        this.checkListtree.filter(obj => {
          if (obj.ID == item.IDdatontai) {
            obj.isXoa = true;
          }
        });
        this.Listpost = this.checkListtree;
      }
      else {
        for (let i = 0; i < this.Listpost.length; i++) {
          if (item.IDdmLoaiCongTrinh == this.Listpost[i].IDdmLoaiCongTrinh) {
            this.Listpost.splice(i, 1);
            break;
          }
        }
      }
    }
  }
  DeQuyCheckTree(item) {
    let data: any = {
      "ID": item.IDdatontai,
      "IDdmKhuVuc": this.dataitem.IDdmKhuVuc,
      "IDdmTinh": this.dataitem.IDdmTinh,
      "IDdmLoaiCongTrinh": item.IDdmLoaiCongTrinh,
      "isXoa": false,
      "Nam": this.dataitem.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.Listpost.push(data);
    item.children.forEach(i => {
      if (i.check == true) {
        this.DeQuyCheckTree(i);
      }
    });
  }
  DeQuyCheckTreeConCha(item) {
    this.TimChaTree.forEach(element => {
      if (element.ID == item.IDParent) {
        element.check = true;
        this.DeQuyCheckTreeConCha(element)
      }
    });
  }
  DeQuyCheckTreeChaCon(Listchildren, item) {
    Listchildren.forEach(element => {
      if (element.IDParent == item.ID) {
        element.check = true;
      }
      this.DeQuyCheckTreeChaCon(element.children, element)

    });
  }
  XacNhan() {
    this.ApiService.SetdsLoaiCongTrinh(this.Listpost).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  checkquyenxoa(item) {
    this.quyenxoa = item.Loai == 0 && this.CheckUserAD == true ? true : false;
  }

  SelectTREE(item) {
    this.checkquyenxoa(item);
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  themmoiTREE() {
    this.dataCheck.List_Check = this.Listtree;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
    modalRef.result.then((data) => {
      this.dataCheck.itemCheck = data.data;
      this.GetListdmLoaiCongTrinh(true);
    }, (reason) => {
      // không
    });
  }

  capnhatTREE() {
    this.dataCheck.List_Check = this.Listtree;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.Listtree = this.Listtree;
    modalRef.componentInstance.List = this.List;
    modalRef.result.then((data) => {
      this.GetListdmLoaiCongTrinh();
    }, (reason) => {
      // không
    });
  }

  xoaTREE() {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmLoaiCongTrinh(this.itemSELeCT).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.itemSELeCT = {}
          this.GetListdmLoaiCongTrinh();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }
  scrollToCenter() {
    let i = 0;
    for (let j = 0; j < this.Listtree.length; j++) {
      i++;
      if (this.Listtree[j].collspan === false)
        continue;
      if ((this.Listtree[j] || {}).ID == this.itemSELeCT.ID) {
        var elmnt = document.getElementById((i || 0).toString());
        if (elmnt === null)
          elmnt = document.getElementById(((i || 0) - 1).toString());
        elmnt.scrollIntoView();
        break;
      }
      if (this.Listtree[j].children.length > 0)
        i = this.dequytinhnode(this.Listtree[j], i);
    }
  }

  dequytinhnode(item, i) {
    for (let j = 0; j < item.children.length; j++) {
      i++;
      if (item.children[j].collspan === false)
        continue;
      if ((item.children[j] || {}).ID == this.itemSELeCT.ID) {
        var elmnt = document.getElementById((i || 0).toString());
        if (elmnt === null)
          elmnt = document.getElementById(((i || 0) - 1).toString());
        elmnt.scrollIntoView();
        break;
      }
      if (item.children[j].children.length > 0)
        i = this.dequytinhnode(item.children[j], i);
    }
    return i;
  }

  initTable() {
    var tables = document
      .getElementsByTagName("li");
    for (let i = 0; i < tables.length; i++) {
      var table = tables[i];
      table.setAttribute("id", (i + 1).toString());
    }
  }
}
