import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { MyService } from './services/my.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CSG';
  public checkLocalStorage: boolean = false;
  currentUser: any = {};

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private MyService: MyService,
  ) {
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  ngOnInit(): void {
    if (this.currentUser.Id !== undefined) {
      this.MyService.checklogin = true;
    }
    else {
      this.MyService.checklogin = false;
      this.router.navigate(['/Login']);
    }
  }

  OutputLogin(e) {
    this.checkLocalStorage = e;
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  logout() {
    this.MyService.checklogin = false;
    this.authService.logout();
  }
}