import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatdanhmucvungComponent } from './NgbModal/capnhatdanhmucvung/capnhatdanhmucvung.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-danhmucvung',
  templateUrl: './danhmucvung.component.html',
  styleUrls: ['./danhmucvung.component.css']
})
export class DanhmucvungComponent implements OnInit {

  ListVUNG: any = [];

  itemSELeCT: any = {};

  ListTINH: any = [];
  ListAllTINH: any = [];

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.GetListdmTinh();
    this.GetListdmVung();
  }

  GetListdmVung() {
    let data: any = {};
    this.DanhmucService.GetListdmVung().subscribe(res => {
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });

      this.ListVUNG = res;
      this.ListVUNG[0].select = true;
      this.itemSELeCT = this.ListVUNG[0];
      // this.GetListdmTinhTheoVung(this.ListVUNG[0].ID);
    });
  }

  GetListdmTinh() {
    this.DanhmucService.GetListdmTinh("").subscribe(res => {
      this.ListAllTINH = res;
    });
  }
  chonvung(item) {
    this.itemSELeCT = item;
    this.ListVUNG.filter(obj => {
      if (obj.ID == item.ID) {
        item.select = true;
        // this.GetListdmTinhTheoVung(item.ID);
      }
      else {
        obj.select = false;
      }
    });
  }

  reloadGetListdmVung() {
    let data: any = {};
    this.DanhmucService.GetListdmVung().subscribe(res => {
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.ListVUNG = res;
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(CapnhatdanhmucvungComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.ListAllTINH = this.ListAllTINH;
    modalRef.result.then((data) => {
      this.reloadGetListdmVung();
      this.chonvung(this.itemSELeCT);
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    this.DanhmucService.GetdmVung(item.ID).subscribe(res => {
      const modalRef = this.modalService.open(CapnhatdanhmucvungComponent, { backdrop: 'static' });
      modalRef.componentInstance.type = 'capnhat';
      modalRef.componentInstance.item = Object.assign({}, res);
      modalRef.componentInstance.IDParent = this.itemSELeCT;
      modalRef.componentInstance.ListAllTINH = this.ListAllTINH;
      modalRef.result.then((data) => {
        this.reloadGetListdmVung();
        this.chonvung(this.itemSELeCT);
      }, (reason) => {
        // không
      });
    });
  }

  xoa(item) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmVung(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.reloadGetListdmVung();
          this.chonvung(this.itemSELeCT);
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

}
