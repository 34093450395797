import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
@Component({
  selector: 'app-hienthicongtrinhdaidienmodal',
  templateUrl: './hienthicongtrinhdaidienmodal.component.html',
  styleUrls: ['./hienthicongtrinhdaidienmodal.component.css']
})
export class HienthicongtrinhdaidienmodalComponent implements OnInit {
  ListtreeNew: any = [];
  Listtree: any = [];
  ListBanDau: any = [];
  IDdmDungChung: any = 0;
  dataitem: any = {};
  dataVatLieu: any = [];
  dataNhanCong: any = [];
  dataMayThiCong: any = [];
  dataMuaSam: any = [];
  LoaiDanhMucDungChung: any = "";
  isCombobox: any = false;
  listDanhMucDungChung: any  = [];
  listDanhMucDungChungCopy: any  = [];
  Nam: any  = 0;
  IDdmKhuVuc: any  = 0;
  IDThongTinUser: any = 0;
  isNotTimKiem: any= false;
  isCongTrinhTrenCuc: any = 0;
  filter: any = {};
  listdmTinh: any = [];
  listdmLoaiCongTrinh: any = [];
  isHienThiThuVien: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.isHienThiThuVien = JSON.parse(localStorage.getItem('checkPM04')).isCheck;

    this.isCongTrinhTrenCuc = "0";
    this.listDanhMucDungChung = JSON.parse(JSON.stringify(this.listDanhMucDungChungCopy));
    this.DanhmucService.GetListdmTinhCucKinhTe().subscribe(res => {
      this.listdmTinh = res;
    });
    this.DanhmucService.GetListdmLoaiCongTrinhCucKinhTe().subscribe(res => {
      this.listdmLoaiCongTrinh = res;
    });
  }
  
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  XacNhanCongTrinhDienHinh(){
    if(parseInt(this.isCongTrinhTrenCuc)=== 0){
      console.log(this.dataitem.IDdmDungChung)
      this.DanhmucService.GetdsDungChungCongTrinhDienHinh(this.dataitem.IDdmDungChung, this.IDThongTinUser, this.Nam, this.IDdmKhuVuc || 0).subscribe(res => {
        if(res.MaKhongCo.length > 0 ){
          const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
          modalRef.componentInstance.thongbao = "Không tìm thấy tên " + res.MaKhongCo;
          modalRef.result.then((any) => {
          }, (reason) => {
          });
        }
        this.activeModal.close(
          {data:res}
        );
      });
    }
    else if(parseInt(this.isCongTrinhTrenCuc) === 1){
      this.DanhmucService.GetCongTrinhDienHinhTrenCuc(this.dataitem.IDdmDungChung).subscribe(res => {
        this.activeModal.close(
          {data:res}
        );
      });
    }
  }
  CongTrinhTrenCuc(){
    this.dataitem.IDdmDungChung  = "";
      this.DanhmucService.GetListDuAnCongTrinh(this.filter.IddmTinh || 0, this.filter.IddmLoaiCongTrinh || 0).subscribe(res => {
        let datapush = [];
        res.forEach(element => {
          let data : any = {
            IDdmDungChung: element.IdDuAn,
            Ten: element.TenDuAn,
            DiaDiem: element.DiaDiem,
            ChuDauTu: element.ChuDauTu,
            VonDauTu: element.TMDT,
            ThoiGianLapDuLieu: element.ThoiDiemXayDung
          }
          datapush.push(data)
        });
        this.listDanhMucDungChung = datapush;
    });
  }
  CongTrinhCaNhan(event){
    console.log(event)
    this.dataitem.IDdmDungChung  = "";
    this.listDanhMucDungChung = this.listDanhMucDungChungCopy;
  }
  Checkdm(item){
    this.listDanhMucDungChung.forEach(element => {
      element.isCheck = false;
    });
    item.isCheck = true;
    this.dataitem = item;
  }
}
