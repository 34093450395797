import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-themmoitree',
  templateUrl: './themmoitree.component.html',
  styleUrls: ['./themmoitree.component.css']
})
export class ThemmoitreeLuaChonComponent implements OnInit {

  public dataitem: any;

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  LoaidanhMuc: any = "";
  Listtree: any = [];
  List: any = [];
  LoaiCongTrinhSet: any = {};
  VatLieuNhanCongMaySet: any = {};
  IDdmTinh: any = 0;
  IDdmKhuVuc: any = 0;
  IDThongTinUser: any = 0;
  data :any ={}

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    if (this.type == 'themmoi') {
      this.header = "Thêm mới - " + this.IDParent.Ten.toString();
      if(this.IDParent.IDParent == -1){
        this.ItemPOST.cap = "capcon";
      }
      else{
        this.ItemPOST.cap = "cungcap";
      }
      if(this.LoaidanhMuc == "Loaivattu"){
        console.log(this.IDParent.LoaiDanhMuc)
        this.ItemPOST.LoaiDanhMuc = this.IDParent.LoaiDanhMuc.toString();
      }
    }
    else {
      this.header = "Cập nhật - " + this.IDParent.Ten.toString();
      this.ItemPOST = Object.assign({}, this.IDParent);
      if(this.IDParent.IDParent == -2 || this.IDParent.IDParent == -3 || this.IDParent.IDParent == -4 || this.IDParent.IDParent == -5){
        this.ItemPOST.IDParent = 0;
      }
      if(this.LoaidanhMuc == "Loaivattu"){
        this.ItemPOST.LoaiDanhMuc = this.ItemPOST.LoaiDanhMuc.toString();
      }
      this.List.filter(obj => { this.ItemPOST.IDParent == obj.ID });
    
    }
  }
  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ma": this.ItemPOST.Ma !== undefined && this.ItemPOST.Ma !== "" ? this.ItemPOST.Ma : "",
      "Ten": this.ItemPOST.Ten !== undefined && this.ItemPOST.Ten !== "" ? this.ItemPOST.Ten : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "IDdmTinh": this.IDdmTinh,
      "IDdmKhuVuc": this.ItemPOST.IDdmKhuVuc,
      "isCongTrinhRiengBiet": this.ItemPOST.isCongTrinhRiengBiet,
      "DonViTinh": this.ItemPOST.DonViTinh,
      "NhaCungCap": this.ItemPOST.NhaCungCap,
      "IDParent": this.ItemPOST.IDParent,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    data.LoaiDanhMuc = parseInt(this.ItemPOST.LoaiDanhMuc);
    if (this.type == 'themmoi') {
      if(this.IDParent.IDParent == -1){
        this.ItemPOST.IDParent = 0;
        data.IDParent = 0;
      }
      else{
        data.IDParent = this.ItemPOST.cap == 'cungcap' ? this.IDParent.IDParent : this.IDParent.ID;
      }
    }
    return data;
  }

  checkdata() {
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  XacNhan() {
    switch (this.LoaidanhMuc) {
      case "Loaicongtrinh":
        this.activeModal.close(
          {data:this.SetData()}
        );
        break;
      case "Loaivattu":
        this.activeModal.close(
          {data:this.SetData()}
        );
        break;
      default:
    }
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
