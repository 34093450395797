import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { Router } from "@angular/router";
import { LuaChonPhuongThucCSGComponent } from '../luachoncsg/luachoncsg.component';
import { ThemMoiDuAnDanhMucDungChungComponent } from '../themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { CongboComponent } from 'src/app/chisogia/khuvuc/NgbModal/congbo/congbo.component';
@Component({
  selector: 'app-luachoncsg',
  templateUrl: './luachon.component.html',
  styleUrls: ['./luachon.component.css']
})
export class LuaChonCSGComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  ListItemPost: any = [];
  

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.ItemPOST.IDThongTinUser = 0;
    this.listData();
    this.ItemPOST.luachon = '0';
  }

  checkdata() {
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  listData() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListThongTinUser(data).subscribe(res => {
      this.ListItemPost = res;
    });
  }
  themmoi() {
    const modalRef = this.modalService.open(LuaChonPhuongThucCSGComponent, {size:'sm',backdrop: 'static', centered: true});
    modalRef.result.then(() => {
    this.activeModal.close();
    }, (reason) => {
    });
  }
  luaChonLoaiCSG() {
    this.ListItemPost.forEach(element => {
      if (element.ID == this.ItemPOST.IDThongTinUser) {
        this.dataitemThongTinUser = element;    
      }
    });
    this.router.navigate(['/DanhMuc/DanhMucTinh']);  
    window.location.reload(); 

  }
  Onclose() {
    this.router.navigate(['/ThongTinUser']);  
    location.reload(); 
    this.activeModal.close();
  }

  Ondismiss() {
    location.reload(); 
    this.activeModal.dismiss();
  }
  CongTrinhDienHinh(){
    const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen',backdrop: 'static', scrollable: true});
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.result.then(() => {
    this.activeModal.close();
    }, (reason) => {
    });
  }
  XacNhan(){
    if(parseInt(this.ItemPOST.luachon) === 0)
      this.themmoi();
    else if(parseInt(this.ItemPOST.luachon) === 2)
      this.Onclose();
    else if(parseInt(this.ItemPOST.luachon) === 3)
      this.CongTrinhDienHinh();
    else if(parseInt(this.ItemPOST.luachon) === 4)
      this.CongBoChiSoGia();
  }
  CongBoChiSoGia(){
    let item: any ={
      ID: 0,
      TenChiSoGia: this.dataitem.TenChiSoGia,
      NamGoc: this.dataitem.NamGoc,
      NamCongBo: this.dataitem.Nam,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
    this.activeModal.close();
    this.router.navigate(['/ThongTinUser']);
    location.reload();
      // this.GetListdsCongBoChiSoGia();
    }, (reason) => {
      // không
    });
  }
}
