import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-themmoitree',
  templateUrl: './themmoitree.component.html',
  styleUrls: ['./themmoitree.component.css']
})
export class ThemmoitreeComponent implements OnInit {

  public dataitem: any;
  public itemADD: any = {};

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    // 0 : Thêm mới cùng cấp
    // 1 : Thêm mới cấp con
    this.itemADD.cap = 0;
    this.itemADD.id = Math.floor(Math.random() * 10) + 100;
    this.itemADD.parentid = this.dataitem.id;
    this.itemADD.collspan= true;
    this.itemADD.check= false;
    this.itemADD.select= false;
    this.itemADD.children= [];
  }

  Onclose() {
    this.activeModal.close(
      {
        itemADD: this.itemADD,
      }
    );
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
