import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { TreeNode } from 'src/app/scriptAll/TreeNode';

@Component({
  selector: 'app-tytrongvung',
  templateUrl: './tytrongvung.component.html',
  styleUrls: ['./tytrongvung.component.css']
})
export class TytrongvungComponent implements OnInit {

  listdata: any[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];
  ListVUNG: any = [];
  datatree: TreeNode[];;

  dataitem: any = {};
  uploader: FileUploader;
  CheckUserAD: boolean = false;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.getnam();
    this.GetListdmVung();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportTytrongVung(res[0].Name, this.dataitem.IDThongTinUser).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.GetListTyTrongVung();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  CheckUser() {
    this.DanhmucService.CheckUser().subscribe(res => {
      this.CheckUserAD = res;
    });
  }

  GetListdmVung() {
    this.dataitem.IDdmVung = undefined;
    this.ListVUNG = [];
    let data: any = {};
    this.DanhmucService.GetListdmVung().subscribe(res => {
      this.ListVUNG = res;
      this.dataitem.IDdmVung = this.ListVUNG[0].ID;
      this.GetListTyTrongVung();
    });
  }

  getnam() {
    this.dataitem.Nam = moment().year();
    for (let i = moment().year() - 10; i <= moment().year() + 1; i++) {
      let data: any = { nam: i }
      this.listnam.push(data);
    }
  }

  keydownenter(e, item) {
    this.SetTyTrongVung(item);
  }

  SetTyTrongVung(item) {
    this.ApiService.SetTyTrongVung(item).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.GetListTyTrongVung()
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  GetListTyTrongVung() {
    let data: any =
    {
      "Nam": this.dataitem.Nam,
      "IDdmVung": this.dataitem.IDdmVung,
    };
    this.ApiService.GetListTyTrongVung(data).subscribe(res => {
      this.datatree = res;
    });
  }

  // GetListdmKhuVuc(IDdmTinh) {
  //   this.DanhmucService.GetListdmKhuVuc(IDdmTinh).subscribe(res => {
  //     this.listkhuvuc = res
  //     this.dataitem.IDdmKhuVuc = res[0].ID;
  //     this.GetListTyTrongVung();
  //   });
  // }


  xuatfile() {
    let data: any = {
      IDdmVung: this.dataitem.IDdmVung,
      Nam: this.dataitem.Nam,
    };
    this.ApiService.ExportTyTrongVung(data).subscribe(res => {
      if (res.TenFile !== undefined) {        
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      } 
    });
  }

}
