import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dmloaicongtrinhmodal',
  templateUrl: './dmloaicongtrinhmodal.component.html',
  styleUrls: ['./dmloaicongtrinhmodal.component.css']
})
export class DmloaicongtrinhmodalComponent implements OnInit {

  ItemPOST: any = {};

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    
  }
 

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
}
