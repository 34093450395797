import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { HostService } from 'src/app/services/host.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-congbo',
  templateUrl: './congbo.component.html',
  styleUrls: ['./congbo.component.css']
})
export class CongboComponent implements OnInit {

  type: any = "";
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  listtinh: any = [];
  listthoigian = [
    // {
    //   Ma: 0,
    //   Ten: 'Năm',
    // },
    {
      Ma: 1,
      Ten: 'Quý',
    },
    // {
    //   Ma: 2,
    //   Ten: 'Tháng',
    // },
  ];
  listthang: any = [];
  listquy = [
    {
      Ma: 1,
      Ten: '1',
    },
    {
      Ma: 2,
      Ten: '2',
    },
    {
      Ma: 3,
      Ten: '3',
    },
    {
      Ma: 4,
      Ten: '4',
    },
  ]
  Nam: any = 0;
  dataitem: any = {};
  listDonViBanHanh: any = [];
  uploaderDuLieu: FileUploader;
  uploaderFileDinhKem: FileUploader;
  obj: any = { FileUpload: [], FileUploadFileDinhKem: [] }
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
    private MyService: MyService,
    private ApiService: ApiService,
  ) { }

  ngOnInit() {
    console.log(this.ItemPOST);
    
    this.GetListdmTinh();
    if (this.ItemPOST.NgayUnix > 0)
      this.ItemPOST.Ngay = this.MyService.unixToDate(this.ItemPOST.NgayUnix);
    if (this.ItemPOST.HieuLucUnix > 0)
      this.ItemPOST.HieuLuc = this.MyService.unixToDate(this.ItemPOST.HieuLucUnix);
    this.uploaderDuLieu = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploaderDuLieu.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploaderDuLieu.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploaderDuLieu.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploaderDuLieu.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);

    this.uploaderFileDinhKem = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploaderFileDinhKem.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploaderFileDinhKem.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploaderFileDinhKem.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploaderFileDinhKem.onCompleteItem = (item, response, status, headers) => this.onCompleteItemFileDinhKem(item, response, status, headers);
    this.ItemPOST.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.ItemPOST.IDUser = JSON.parse(localStorage.getItem('currentUsers')).UserName;
    this.ItemPOST.IDUser = JSON.parse(localStorage.getItem('currentUsers')).UserName;
    if (this.ItemPOST.ID == 0)
      this.ItemPOST.LoaiThoiGian = this.listthoigian[0].Ma;
    this.GetListdmTinh();
    this.DanhmucService.GetListDonViBanHanh().subscribe(res => {
      this.listDonViBanHanh = res;
    });
    if (this.ItemPOST.fileUploadFileDinhKem !== null)
      this.ItemPOST.fileUploadFileDinhKem.forEach(element => {
        let itemAdd = {
          NameLocal: element.TenGoc,
          Name: element.TenGui,
        }
        this.obj.FileUploadFileDinhKem.push(itemAdd);
      });
    if (this.ItemPOST.fileUpload !== null)
      this.ItemPOST.fileUpload.forEach(element => {
        let itemAdd = {
          NameLocal: element.TenGoc,
          Name: element.TenGui,
        }
        this.obj.FileUpload = [itemAdd];
        // this.obj.FileUpload = itemAdd;
      });
  }
  GetListdmTinh() {
    // this.listtinh = [];
    if (this.ItemPOST.IDThongTinUser > 0) {
      this.DanhmucService.GetListdmTinhTheoThongTinUser(this.ItemPOST.IDThongTinUser || 0).subscribe(res => {
        this.listtinh = res;
        if (this.listtinh.length > 0)
          this.ItemPOST.IDdmTinh = this.listtinh[0].ID;
      });
    }
    else {
      this.DanhmucService.GetListdmTinh("").subscribe(res => {
        this.listtinh = res;
        if (this.listtinh.length > 0)
        this.ItemPOST.IDdmTinh = this.listtinh[this.ItemPOST.IDdmTinh - 1].ID;
      });
    }
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    this.obj.FileUpload[0] = (res[0]);
    // this.obj.FileUpload = [res[0]];
  };
  onCompleteItemFileDinhKem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    this.obj.FileUploadFileDinhKem.push(res[0]);
  };
  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  chonthoigian() {
    if (this.ItemPOST.LoaiThoiGian == 0) {
      this.ItemPOST.ThoiGian = 0;
    }
    else if (this.ItemPOST.LoaiThoiGian == 1) {
      this.ItemPOST.ThoiGian = this.listquy[0].Ma;
    }
    else if (this.ItemPOST.LoaiThoiGian == 2) {
      this.ItemPOST.ThoiGian = this.listthang[0].Ma;
    }
  }

  SetDataTrenCuc() {
    let dmTinh = this.listtinh.find(ele => ele.ID == this.ItemPOST.IDdmTinh);
    if (dmTinh == undefined || dmTinh == null)
      dmTinh = {};
    let data: any =
    {
      "IDChiSoGia": 0,
      "HieuLucNew": this.ItemPOST.LoaiHieuLuc || 0,
      "MaTinh": dmTinh.Ma,
      "SoHieu": this.ItemPOST.SoHieu != undefined && this.ItemPOST.SoHieu != null ? this.ItemPOST.SoHieu : "",
      "IdDonViBanHanh": this.ItemPOST.IDCoQuan || "",
      "NguoiKy": this.ItemPOST.NguoiKy || "",
      "Ten": this.ItemPOST.TenChiSoGia || "",
      "TrichYeu": this.ItemPOST.TrichYeu || "",
      "NamGoc": this.ItemPOST.NamGoc,
      "NamApDung": this.ItemPOST.NamCongBo,
      "LoaiThoiGian": this.ItemPOST.LoaiThoiGian || 0,
      "IsPublic": false,
      "DungLuongTapTin": 0,
      "TongFile": 0,
      "TotalRow": 0,
      "ConHieuLuc": false,
      "NoiDung": this.ItemPOST.ThuyetMinh || "",
      "IDUser": this.ItemPOST.IDUser,
      "Files": "",
    }
    // data.NgayThangVanBan = `${this.ItemPOST.Ngay.day}/${this.ItemPOST.Ngay.month}/${this.ItemPOST.Ngay.year} 12:00:00 AM`;
    data.NgayThangVanBan = formatDate(this.ItemPOST.Ngay, "dd/MM/yyyy hh:mm:ss a", 'en-US');
    // data.HieuLuc = `${this.ItemPOST.HieuLuc.day}/${this.ItemPOST.HieuLuc.month}/${this.ItemPOST.HieuLuc.year} 12:00:00 AM`;
    data.HieuLuc = formatDate(this.ItemPOST.HieuLuc, "dd/MM/yyyy hh:mm:ss a", 'en-US');

    this.obj.FileUploadFileDinhKem.forEach(element => {
      data.Files += element.Name + ",";
    });
    // data.Files = this.obj.FileUploadFileDinhKem.map(ele => ele.Name)
    // if (data.Files.length > 2)
      // data.Files = data.Files.substring(0, data.Files.length - 2)
    let alo =   data.Files.split('');
    alo.pop();
    console.log(alo)
    data.Files = alo.join('');
    console.log(data)
    if (this.obj.FileUpload.length > 0) {
      data.TenTapTin = this.obj.FileUpload[0].Name;
      // data.TenTapTin = "BanHanhChiSoGia44518.4717317245.xlsx";
    }
    return data;
  }
  XacNhan(isCongBo?) {
    // debugger
    if (this.ItemPOST.Ngay === undefined || this.ItemPOST.Ngay === null) {
      this.toastr.error("Bạn chưa nhập ngày ban hành");
    }
    else if (this.ItemPOST.IDCoQuan === undefined || this.ItemPOST.IDCoQuan === null) {
      this.toastr.error("Bạn chưa chọn cơ quan ban hành");
    }
    else if (this.ItemPOST.SoHieu === undefined || this.ItemPOST.SoHieu === null) {
      this.toastr.error("Bạn chưa nhập số hiệu");
    }
    else if (this.ItemPOST.ThoiGian === undefined || this.ItemPOST.ThoiGian === null) {
      this.toastr.error("Bạn chưa chọn thời gian công bố");
    }
    else {
      if (this.ItemPOST.IDCoQuan !== undefined && this.ItemPOST.IDCoQuan !== null) {
        let coQuan = this.listDonViBanHanh.filter(e => e.Id === this.ItemPOST.IDCoQuan);
        if (coQuan !== undefined) {
          this.ItemPOST.CoQuan = coQuan[0].Ten;
        }
      }

      // this.ItemPOST.NgayUnix = (this.ItemPOST.Ngay != undefined && this.ItemPOST.Ngay != null) ? this.MyService.ghepdateUnix(this.ItemPOST.Ngay) : 0;
      // this.ItemPOST.HieuLucUnix = (this.ItemPOST.HieuLuc != undefined && this.ItemPOST.HieuLuc != null) ? this.MyService.ghepdateUnix(this.ItemPOST.HieuLuc) : 0;
      this.ItemPOST.NgayUnix = this.MyService.DateToUnix(this.ItemPOST.Ngay);
      this.ItemPOST.HieuLucUnix = this.MyService.DateToUnix(this.ItemPOST.HieuLuc);
      this.ItemPOST.fileUpload = [];
      this.ItemPOST.fileUploadFileDinhKem = [];
      this.obj.FileUpload.forEach(element => {
        let itemAdd = {
          TenGoc: element.NameLocal,
          TenGui: element.Name,
        }
        this.ItemPOST.fileUpload.push(itemAdd);
      });
      // this.obj.FileUploadFileDinhKem.forEach(element => {
      //   let itemAdd = {
      //     TenGoc: element.NameLocal,
      //     TenGui: element.Name,
      //   }
      //   this.ItemPOST.FileUploadFileDinhKem.push(itemAdd);
      // });
      this.ItemPOST.fileUploadFileDinhKem = this.obj.FileUploadFileDinhKem.map(ele => {
        return {
          TenGoc: ele.NameLocal,
          TenGui: ele.Name,
        }
      })
      if (isCongBo === true) {

        if (this.ItemPOST.ID > 0) {
          // console.log('CongBo =true ',this.ItemPOST.ID)
          // console.log(this.obj.FileUploadFileDinhKem)
          // console.log(this.SetDataTrenCuc())
          this.ApiService.SetCongBoChiSoGia(this.ItemPOST.ID).subscribe(res => {
            if (res.State === 1) {
              this.ApiService.ExportCongBoChiSoGiaTrenCuc(this.SetDataTrenCuc()).subscribe(res1 => {
                if (res1.Loai === 0) {
                  this.toastr.success("Cập nhật thành công");
                  this.Onclose();
                }
                else {
                  this.toastr.error("Xảy ra lỗi");
                }
              });
            }
            else {
              this.toastr.error("Xảy ra lỗi");
            }
          });
        }
        else {
          this.toastr.error("Bạn cần xác nhận trước!!!");
        }
      }
      else {
        console.log('CongBo = false ',this.ItemPOST.ID)
        // delete this.ItemPOST.Ngay;
        // delete this.ItemPOST.HieuLuc;
        this.ApiService.ExportCongBoChiSoGia(this.ItemPOST).subscribe(res => {
          if (res.TenFile0 !== undefined && res.TenFile1 !== undefined) {
            this.toastr.success("Cập nhật thành công");
            this.MyService.taixuong(res.TenFile0);
            this.MyService.taixuong(res.TenFile1);
            this.Onclose();
          }
          else if (res == "") {
            this.toastr.success("Cập nhật thành công");
            this.Onclose();
          }
          else {
            this.toastr.error("Xảy ra lỗi");
          }
        });
      }
    }
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

  deleteFile() {
    this.obj.FileUpload = []
  }

}
