import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import { NhaptytrongComponent } from './NgbModal/nhaptytrong/nhaptytrong.component';
import { LuachoncongtrinhComponent } from './NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-tytrongcongtrinh',
  templateUrl: './tytrongcongtrinh.component.html',
  styleUrls: ['./tytrongcongtrinh.component.css']
})
export class TytrongcongtrinhComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};
  uploader: FileUploader;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    this.GetListdmTinh();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      this.dataitem.IDdmTinh = res[0].ID;
      this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
    });
  }  
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    this.spinner.show();
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportTytrongCongTrinh(res[0].Name, this.dataitem.IDdmKhuVuc, 
        this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
          if (res.State === 1) {
            this.toastr.success(res.message);
            this.GetListTyTrongCongTrinh();
          }
          else {
            this.toastr.error(res.message);
          }
      this.spinner.hide();
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  GetListdmKhuVuc(IDdmTinh, IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetListTyTrongCongTrinh();
    });
  }
  TinhTyTrongCongTrinh() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.TinhTyTrongCongTrinh(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.GetListTyTrongCongTrinh();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  GetListTyTrongCongTrinh() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetListTyTrongCongTrinh(data).subscribe(res => {
      this.TreeVLNCM = res
    });
  }

  nhaptytrong(e) {
    if(e.GiamSatThietBi != null)
      e.GiamSatThietBi = e.GiamSatThietBi.toString().replace('.',',');
    if(e.GiamSatXayDung != null)
      e.GiamSatXayDung = e.GiamSatXayDung.toString().replace('.',',');
    if(e.Khac != null)
      e.Khac = e.Khac.toString().replace('.',',');
    if(e.KhaoSat != null)
      e.KhaoSat = e.KhaoSat.toString().replace('.',',');
    if(e.LapDat != null)
      e.LapDat = e.LapDat.toString().replace('.',',');
    if(e.MuaSam != null)
      e.MuaSam = e.MuaSam.toString().replace('.',',');
    if(e.NhanCong != null)
      e.NhanCong = e.NhanCong.toString().replace('.',',');
    if(e.PhanChiPhiKhac != null)
      e.PhanChiPhiKhac = e.PhanChiPhiKhac.toString().replace('.',',');
    if(e.PhanThietBi != null)
      e.PhanThietBi = e.PhanThietBi.toString().replace('.',',');
    if(e.PhanXayDung != null)
      e.PhanXayDung = e.PhanXayDung.toString().replace('.',',');
    if(e.QuanLyDuAn != null)
      e.QuanLyDuAn = e.QuanLyDuAn.toString().replace('.',',');
    if(e.ThietKe != null)
      e.ThietKe = e.ThietKe.toString().replace('.',',');
    if(e.XayDungCongTrinh != null)
      e.XayDungCongTrinh = e.XayDungCongTrinh.toString().replace('.',',');
    if(e.VatLieu != null)
      e.VatLieu = e.VatLieu.toString().replace('.',',');
    if(e.May != null)
      e.May = e.May.toString().replace('.',',');
    if(e.TuVanDauTu != null)
      e.TuVanDauTu = e.TuVanDauTu.toString().replace('.',',');
    const modalRef = this.modalService.open(NhaptytrongComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.IDParent = e;
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.componentInstance.IDThongTinUser = this.dataitem.IDThongTinUser;
    modalRef.result.then((data) => {
      this.GetListTyTrongCongTrinh();
    }, (reason) => {
      // không
    });
  }

  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
      IDdmTinh: this.dataitem.IDdmTinh,
    };
    this.ApiService.ExportTyTrongCongTrinh(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

  chonloai() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetdsLoaiCongTrinh(data).subscribe(res => {
      let data: any = {
        IDdmTinh: this.dataitem.IDdmTinh,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc,      
      };
      this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(restytrongcongtrinh => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res.items);
        modalRef.componentInstance.Listtree = Object.assign([], restytrongcongtrinh);
        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.result.then((data) => {
          this.GetListTyTrongCongTrinh();
        }, (reason) => {
          // không
        });
      });
    });
  } 

}
