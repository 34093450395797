import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { TytrongComponent } from './NgbModal/tytrong/tytrong.component';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { LuaChonVLCNMCongTrinhComponent } from './NgbModal/luachon-vlncm/luachon-vlncm.component';
import { LuachoncongtrinhComponent } from '../tytrongcongtrinh/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { ChiPhiVatLieuNhanCongMayComponent } from './NgbModal/chiphivatlieunhancongmay/chiphivatlieunhancongmay.component';
import { ThemMoiDuAnComponent } from './NgbModal/themmoiduan/themmoiduan.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-tytrong-vlncm',
  templateUrl: './tytrong-vlncm.component.html',
  styleUrls: ['./tytrong-vlncm.component.css']
})
export class TytrongVLNCMComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};

  files: TreeNode[];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;
  uploader: FileUploader;
  loaihienthi: any = [];
  
  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    this.getLoaiHienThi();
    this.GetListdmTinh();

  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    this.spinner.show();
    let res = JSON.parse(response);
    res[0].Id = 0;

    this.ApiService.ImportTytrongVLNCM(res[0].Name, this.dataitem.IDdmKhuVuc, this.dataitem.IDThongTinUser).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.GetListTyTrongPhanXayDung();
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();
     });

  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length === 0){
        this.GetListTyTrongPhanXayDung();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;      
        this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
      }
    });
  }
  GetListdmKhuVuc(IDdmTinh,IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetListTyTrongPhanXayDung();
    });
  }

  GetListTyTrongPhanXayDung() {
    this.SoCotCon = 0;
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      LoaiHienThi: this.dataitem.LoaiHienThi,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetHeaderTyTrongPhanXayDung(data).subscribe(res => {
      this.colparen = res.filter(obj => {
        if (obj.SoCotCon > 0) {
          this.SoCotCon += obj.SoCotCon;
          return obj;
        }
      });
      this.colschidren = res.filter(obj => {
        if (obj.SoCotCon == 0) {
          return obj;
        }
      });
      this.ApiService.GetListTyTrongPhanXayDung(data).subscribe(res => {
        this.files = [];
        this.files = res;
        console.log(this.files)
      });
    });
  }
  
  chonloai() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh, 
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetdsLoaiCongTrinh(data).subscribe(res => {
      let data: any = {
        IDdmTinh: this.dataitem.IDdmTinh,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc,      
      };
      this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(restytrongcongtrinh => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res.items);
        modalRef.componentInstance.Listtree = Object.assign([], restytrongcongtrinh);
        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.componentInstance.Nam = this.dataitem.Nam;
        modalRef.result.then((data) => {
          this.GetListTyTrongPhanXayDung();
        }, (reason) => {
          // không
        });
      });
    });
  }

  getLoaiHienThi() {
    this.loaihienthi = [
      {
        Ma: 0,
        Ten: 'Tỷ trọng',
      },
      {
        Ma: 1,
        Ten: 'Danh sách vật liệu',
      },
    ];
    this.dataitem.LoaiHienThi = this.loaihienthi[0].Ma;
  }
  
  chonthoigian() {
      this.GetListTyTrongPhanXayDung();
  }
 
  nhaptytrong(item, tenRow, check) {
    item.TyTrong = item.TyTrong.toString().replace('.',',');
    if (!check) {
      const modalRef = this.modalService.open(TytrongComponent, { backdrop: 'static', scrollable: true });
      modalRef.componentInstance.item = Object.assign({}, item);
      modalRef.componentInstance.tenRow = tenRow;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListTyTrongPhanXayDung();
      }, (reason) => {
        // không
      });
    }
  }

  GetdsVatLieuCongTrinh(item, tenRow, check) {

    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: moment().year(),
      IDdmCongTrinh : item.IDdmLoaiCongTrinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetListVatLieuTheoCongTrinh(data).subscribe(res => {
      this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
        const modalRef = this.modalService.open(LuaChonVLCNMCongTrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res);
        modalRef.componentInstance.Listtree = Object.assign([], resListdmVatLieuNhanCongMay);
        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
        modalRef.componentInstance.IDdsLoaiCongTrinh = item.ID;this.dataitem.IDdmTinh
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.result.then((data) => {
          this.GetListTyTrongPhanXayDung();
        }, (reason) => {
          // không
        });
      });
    });
  }
  GetChiPhiVatLieuNhanCongMay(item, itemRow, check) {
    // if(item.Level >3){
      let data: any = {
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
        IDdmTinh: this.dataitem.IDdmTinh,
        Nam: moment().year(),
        IDdmCongTrinh : item.IDdmLoaiCongTrinh,
        IDdmVatLieuNhanCongMay : itemRow.IDdmVatLieuNhanCongMay,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      };
      this.ApiService.GetChiPhiVatLieuNhanCongMay(data).subscribe(res => {
        const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.item = Object.assign([], res);
        modalRef.componentInstance.itemRow = Object.assign([], itemRow);
        modalRef.result.then((data) => {
          this.GetListTyTrongPhanXayDung();
        }, (reason) => {
          // không
        });
      });
    // }
  }
  ThemMoiDuAn(rowData){
    const modalRef = this.modalService.open(ThemMoiDuAnComponent, {size:'fullscreen', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.componentInstance.IDParent = rowData.IDdmLoaiCongTrinh;
    // modalRef.componentInstance.IDdmKhuVuc = rowData.IDdmLoaiCongTrinh;
    modalRef.result.then((data) => {
      this.GetListTyTrongPhanXayDung();
    }, (reason) => {
      // không
    });
  }
  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ExportTyTrongPhanXayDung(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
