import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-huongdantytrong',
  templateUrl: './huongdantytrong.component.html',
  styleUrls: ['./huongdantytrong.component.css']
})
export class HuongdantytrongComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
