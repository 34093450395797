import { Component, OnInit, DebugNode } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { MyService } from './../../../../services/my.service';
import { ThemmoitreeLuaChonComponent } from '../themmoitree/themmoitree.component';
import { UpdatekhuvucLuaChonComponent } from '../updatekhuvuc/updatekhuvuc.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { LuaChonPhuongThucCSGComponent } from '../luachoncsg/luachoncsg.component';
import { NgxSpinnerService } from "ngx-spinner";
import { HienThiVLNCMComponent } from 'src/app/danhmuc/danhmucdungchung/NgbModal/hienthi-vlncm/hienthi-vlncm.component';
@Component({
  selector: 'app-luachontinh',
  templateUrl: './luachontinh.component.html',
  styleUrls: ['./luachontinh.component.css']
})
export class LuaChonTinhCSGComponent implements OnInit {
  type: any = '';
  TeniconCongTrinh: any = '';
  TeniconVatLieu: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  ListTinhPost: any = [];
  ListKhuVucSet: any = [];
  ListTinhSet: any = [];
  ListTinhKhuVuc: any = [];
  Listtree: any = [];
  ListtreeVLNCM: any = [];
  ListLoaiCongTrinh: any = [];
  checkListtree: any = [];
  checkListtreeVLNCM: any = [];
  itemSELeCT: any = {};
  itemSELeCTVLNCM: any = {};
  ListVatLieuNhanCongMaySet: any = [];
  ListLoaiCongTrinhSet: any = [];
  IDLoaiCongTrinh: any = 0;
  IDVLCNM: any = 0;
  ListVLNCM: any = [];
  USER: any = {};
  Checkicon: any = 0;
  CheckboxAllCongTrinh: any = false;
  CheckboxAllVatLieu: any = false;
  CheckboxVatLieu: any = false;
  CheckboxNhanCong: any = false;
  CheckboxMay: any = false;
  CheckboxMuaSam: any = false;
  // CheckboxdmDungChungLCT: any = false;
  // CheckboxdmDungChungVL: any = false;
  listDanhMucDungChungVL: any = [];
  listDanhMucDungChungLCT: any = [];
  listThongTinUser: any = [];
  sFilter : any = "";
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private router: Router,
    public spinner: NgxSpinnerService,
  ) { }
  ngOnInit() {
    this.Checkicon = 0;
    this.TeniconVatLieu = 'Chọn tất cả';
    this.TeniconCongTrinh = 'Chọn tất cả';
    this.USER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    this.ListLoaiCongTrinh = this.Listtree;
    this.ListLoaiCongTrinh[0].select = true;
    this.Listtree = this.MyService.unflatten(this.ListLoaiCongTrinh);
    this.ListtreeVLNCM.filter(obj => {
      obj.collspan = true;
      obj.checkbox = false;
      obj.select = false;
    });
    this.ListVLNCM = this.ListtreeVLNCM;
    this.ListVLNCM[0].select = true;
    this.ListtreeVLNCM = this.unflatten(this.ListVLNCM);
    this.GetListdmDungChungLCT();
    this.GetListdmDungChungVL();
  }
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.ID] = arrElem;
      mappedArr[arrElem.ID]['children'] = [];
    }
    for (var ID in mappedArr) {
      if (mappedArr.hasOwnProperty(ID)) {
        mappedElem = mappedArr[ID];
        if (mappedElem.IDParent != -1) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);
        }
        else {

          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  GetListThongTinUser() {
    if(this.ItemPOST.Nam > 0 && this.ItemPOST.NamTinh > 0){
      let IDdmTinhFind : any = 0;
      if(this.ListTinhKhuVuc.length < 1)
        IDdmTinhFind = 0;
      else
        IDdmTinhFind = this.ListTinhKhuVuc[0].ID;
      this.DanhmucService.GetListThongTinUserNamTruoc(this.ItemPOST.NamTinh, this.ItemPOST.Nam, IDdmTinhFind, this.ItemPOST.LoaiTinhCSG).subscribe(res => {
        this.listThongTinUser = res
    });
    }
  }
  GetListdmDungChungVL() {
    this.dataitem.IDdmDungChungVL = 0;
    this.DanhmucService.GetListdmDungChung('LoaiVatLieuNhanCongMay',0, "", this.USER.UserName).subscribe(res => {
      this.listDanhMucDungChungVL = res;
      if(this.listDanhMucDungChungVL.length > 0)
        this.dataitem.IDdmDungChungVL = this.listDanhMucDungChungVL[0].ID;
    });
  }
  GetListdmDungChungLCT() {
    this.dataitem.IDdmDungChungLCT = 0;
    this.DanhmucService.GetListdmDungChung('LoaiCongTrinh',0,"", this.USER.UserName).subscribe(res => {
      this.listDanhMucDungChungLCT = res;
      if(this.listDanhMucDungChungLCT.length > 0)
        this.dataitem.IDdmDungChungLCT = this.listDanhMucDungChungLCT[0].ID;
    });
  }
  
  DeQuyCheckTreeConCha(item) {
    for (let i = 0; i < this.ListLoaiCongTrinh.length; i++) {
      if (this.ListLoaiCongTrinh[i].IDdmLoaiCongTrinh == item.IDParent) {
        this.ListLoaiCongTrinh[i].check = true;
        this.DeQuyCheckTreeConCha(this.ListLoaiCongTrinh[i]);
        break;
      }
    }
  }
  DeQuyCheckTreeChaCon(Listchildren, item, giatri) {
    Listchildren.forEach(element => {
      if (element.IDParent == item.IDdmLoaiCongTrinh) {
        element.check = giatri;
      }
      this.DeQuyCheckTreeChaCon(element.children, element, giatri)
    });
  }
  DeQuyCheckTree(item) {
    let data: any = {
      "ID": item.IDdatontai,
      "IDdmKhuVuc": this.dataitem.IDdmKhuVuc,
      "IDdmTinh": this.dataitem.IDdmTinh,
      "IDdmLoaiCongTrinh": item.IDdmLoaiCongTrinh,
      "TendmLoaiCongTrinh": item.Ten,
      "isXoa": false,
      "Nam": this.dataitem.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.ListLoaiCongTrinhSet.push(data);
    item.children.forEach(i => {
      if (i.check == true) {
        this.DeQuyCheckTree(i);
      }
    });
  }
  CheckTREE(item) {
    this.ListLoaiCongTrinhSet = [];
    item.check = !item.check;
    if (item.check) {
      this.DeQuyCheckTreeConCha(item)
    }
    if (item.children.length > 0) {
      this.DeQuyCheckTreeChaCon(item.children, item, item.check)
    }
  }
  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }
  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }
  //#endregion
  CheckGiaTriIcon(Giatri) {
    this.Checkicon = Giatri;
  }
  //#region dmTinh-KHUVUC
  CheckdmTinh(item) {
    item.check = !item.check;
    this.ListTinhSet = [];
    this.ListTinhKhuVuc = [];
    this.ListTinhPost.forEach(element => {
      if (this.ItemPOST.LoaiTinhCSG == 'DiaPhuong') {
        if (element.ID == item.ID) {
          this.ListTinhKhuVuc.push(element)
          this.dataitem.IDdmTinh = item.ID;
          let data = {
            "IDThongTinUser": this.dataitem.IDThongTinUser,
            "IDdmTinh": this.dataitem.IDdmTinh,
          }
          this.ListTinhSet.push(data)
          this.GetListThongTinUser()
        }
        else {
          element.check = false;
        }
      }
      else if (this.ItemPOST.LoaiTinhCSG == 'CaNuoc') {
        this.ListTinhKhuVuc.push(element)
        this.dataitem.IDdmTinh = item.ID;
        let data = {
          "IDThongTinUser": this.dataitem.IDThongTinUser,
          "IDdmTinh": this.dataitem.IDdmTinh,
        }
        this.ListTinhSet.push(data)
      }
      else {
        if (element.check == true) {
          this.ListTinhKhuVuc.push(element)
          this.dataitem.IDdmTinh = item.ID;
          let data = {
            "IDThongTinUser": this.dataitem.IDThongTinUser,
            "IDdmTinh": this.dataitem.IDdmTinh,
          }
          this.ListTinhSet.push(data)
        }
      }
    });
  }
  themmoiKHUVUC() {
    const modalRef = this.modalService.open(UpdatekhuvucLuaChonComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoiluachon';
    modalRef.componentInstance.IDParent = this.dataitem.IDdmTinh;
    // modalRef.componentInstance.IDThongTinUser = this.IDThongTinUser;    
    modalRef.result.then((data) => {
      this.ListKhuVucSet.push(data.data);
    }, (reason) => {
      // không
    });
  }
  //#endregion

  DeQuyCheckTreeConChaVLCNM(item) {
    for (let i = 0; i < this.ListVLNCM.length; i++) {
      if (this.ListVLNCM[i].IDdmVatLieuNhanCongMay == item.IDParent) {
        if (this.ListVLNCM[i].ID == -2) {
          this.CheckboxVatLieu = true;
        }
        else if (this.ListVLNCM[i].ID == -3) {
          this.CheckboxNhanCong = true;
        }
        else if (this.ListVLNCM[i].ID == -4) {
          this.CheckboxMay = true;
        }
        else if (this.ListVLNCM[i].ID == -5) {
          this.CheckboxMuaSam = true;
        }
        else {
          this.ListVLNCM[i].checkbox = true;
        }
        this.DeQuyCheckTreeConChaVLCNM(this.ListVLNCM[i]);
        break;
      }
    }
  }

  DeQuyCheckTreeVLCNM(item) {
    
    let data: any = {
      "ID": item.IDdatontai,
      "IDdmKhuVuc": this.dataitem.IDdmKhuVuc,
      "IDdmTinh": this.dataitem.IDdmTinh,
      "IDdmVatLieuNhanCongMay": item.IDdmVatLieuNhanCongMay,
      "TendmVatLieuNhanCongMay": item.Ten !== undefined ? item.Ten : item.TendmVatLieuNhanCongMay,
      "isXoa": false,
      "Nam": this.dataitem.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.ListVatLieuNhanCongMaySet.push(data);
    item.children.forEach(i => {
      if (i.checkbox == true) {
        this.DeQuyCheckTreeVLCNM(i);
      }
    });
  }
  CheckTREEVLCNM(item) {
    this.ListVatLieuNhanCongMaySet = [];
    item.checkbox = !item.checkbox;
    if (item.children.length > 0) {
      this.DeQuyCheckTreeChaConVLNCM(item.children, item, item.checkbox)
    }
    if (item.checkbox && item.IDParent != -1) {
      this.DeQuyCheckTreeConChaVLCNM(item)
    }
  }

  DeQuyCheckTreeChaConVLNCM(Listchildren, item, giatri) {
    Listchildren.forEach(element => {
      if (element.IDParent == item.IDdmVatLieuNhanCongMay) {
        element.checkbox = giatri;
      }
      this.DeQuyCheckTreeChaConVLNCM(element.children, element, giatri)

    });
  }

  SelectTREEVLCNM(item) {
    this.itemSELeCTVLNCM = item;
    item.select = true;
    for (let i = 0; i < this.ListtreeVLNCM.length; i++) {
      this.ListtreeVLNCM[i].select = this.ListtreeVLNCM[i].ID == item.ID ? item.select : false;
      if (this.ListtreeVLNCM[i].children.length > 0) {
        this.SelectchildrenTREE(this.ListtreeVLNCM[i].children, item);
      }
    }
  }
  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "IDUser": this.USER.Id,
      "Ten": this.ItemPOST.Ten,
      "NgayTao": this.type == 'themmoi' ? null : this.item.property,
      "MoTa": this.ItemPOST.MoTa !== undefined && this.ItemPOST.MoTa !== "" ? this.ItemPOST.MoTa : "",
      "Nam": this.ItemPOST.Nam !== undefined && this.ItemPOST.Nam !== "" ? this.ItemPOST.Nam : "",
      "NamTinh": this.ItemPOST.NamTinh !== undefined && this.ItemPOST.NamTinh !== "" ? this.ItemPOST.NamTinh : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "NoiDung": this.ItemPOST.NoiDung !== undefined && this.ItemPOST.NoiDung !== "" ? this.ItemPOST.NoiDung : "",
      "LoaiTinhCSG": this.ItemPOST.LoaiTinhCSG !== undefined && this.ItemPOST.LoaiTinhCSG !== "" ? this.ItemPOST.LoaiTinhCSG : "",
      "IDThongTinUserNamTruoc": this.ItemPOST.IDThongTinUserNamTruoc !== undefined && this.ItemPOST.IDThongTinUserNamTruoc !== "" ? this.ItemPOST.IDThongTinUserNamTruoc : "",
    }
    return data;
  }
  XacNhan() {
    if (this.ItemPOST.Ten == null || this.ItemPOST.Ten == undefined) {
      this.toastr.error("Bạn chưa nhập tên CSG");
    }
    else if (this.ItemPOST.NamTinh == null || this.ItemPOST.NamTinh == undefined) {
      this.toastr.error("Bạn chưa nhập năm tính");
    }
    else if (this.ItemPOST.Nam == null || this.ItemPOST.Nam == undefined) {
      this.toastr.error("Bạn chưa nhập năm gốc");
    }
    else if (this.ListTinhKhuVuc.length == 0 && this.ItemPOST.LoaiTinhCSG != 'CaNuoc' && this.ItemPOST.LoaiTinhCSG != 'CongTrinhDienHinh') {
      this.toastr.error("Bạn chưa chọn  tỉnh");
    }
    else if (this.ItemPOST.Nam < 0 || this.ItemPOST.NamTinh < 0) {
      this.toastr.error("Bạn chưa nhập sai định dạng năm");
    }
    else if (this.ItemPOST.Nam > this.ItemPOST.NamTinh || this.ItemPOST.Nam === this.ItemPOST.NamTinh) {
      this.toastr.error("Năm gốc lớn hơn năm tính");
    }
    else {
      this.spinner.show();
      // if (this.ListLoaiCongTrinhSet.length == 0 && this.ItemPOST.LoaiTinhCSG != 'CongTrinhDienHinh') {
      //   this.toastr.warning("Bạn chưa lựa chọn công trình ");
      // }
      // if (this.ListVatLieuNhanCongMaySet.length == 0) {
      //   this.toastr.warning("Bạn chưa lựa chọn vật liệu nhân công máy");
      // }
      if (this.ItemPOST.LoaiTinhCSG == 'CaNuoc') {
        this.ListTinhPost.forEach(element => {
            this.ListTinhKhuVuc.push(element)
            this.dataitem.IDdmTinh = element.ID;
            let data = {
              "IDThongTinUser": this.dataitem.IDThongTinUser,
              "IDdmTinh": this.dataitem.IDdmTinh,
            }
            this.ListTinhSet.push(data)
        });
      }
      this.ListtreeVLNCM.forEach(element => {
            this.DeQuyCheckTreeVLCNM(element);
      });
      this.Listtree.forEach(element => {
        if(element.check == true){
            this.DeQuyCheckTree(element);
        }
      });
      let data = {
        "ThongTinUser": this.SetData(),
        "listdmTinh": this.ListTinhKhuVuc,
        "listdsLoaiCongTrinh": this.ListLoaiCongTrinhSet,
        "listdsVatLieuNhanCongMay": this.ListVatLieuNhanCongMaySet,
        "IDdmDungChungVL": this.dataitem.IDdmDungChungVL,
        "IDdmDungChungLCT": this.dataitem.IDdmDungChungLCT,

      }
      localStorage.setItem('IDdmTinhUser', JSON.stringify(this.ListTinhSet[0]));
      this.router.navigate(['/ThongTinChung/GiaVatLieuNhanCongMay']);
      this.DanhmucService.SetThongTinLuaChon(data).subscribe(res => {
        if (res.State === 1) {
          this.activeModal.close();
          this.toastr.success(res.message);
          localStorage.setItem('thongtinuser', JSON.stringify(res.objectReturn));
          location.reload();
        }
        else {
          this.toastr.error(res.message);
        }
        this.spinner.hide();
      });
    }
  }
  Onclose() {
    this.router.navigate(['/ThongTinUser']);
    location.reload();
    this.activeModal.close();
  }
  QuayLai() {
    this.activeModal.close();
    const modalRef = this.modalService.open(LuaChonPhuongThucCSGComponent, { backdrop: 'static', centered: true });
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
  dequyCheckBox(listItem, checkbox) {
    listItem.forEach(element => {
      element.check = checkbox;
      this.dequyCheckBox(element.children, checkbox)
    });
  }
  CheckAllCongTrinh() {
    this.CheckboxAllCongTrinh = !this.CheckboxAllCongTrinh;
    if (this.CheckboxAllCongTrinh == true) {
      this.TeniconCongTrinh = 'Bỏ chọn tất cả';
      this.Listtree.forEach(element => {
        element.check = true;
        this.dequyCheckBox(element.children, true);
      });
    }
    else {
      this.TeniconCongTrinh = 'Chọn tất cả';
      this.Listtree.forEach(element => {
        element.check = false;
        this.dequyCheckBox(element.children, false)
      });
    }
  }
  dequyCheckBoxVL(listItem, checkbox) {
    listItem.forEach(element => {
      element.checkbox = checkbox;
      this.dequyCheckBoxVL(element.children, checkbox)
    });
  }
  CheckAllVatLieu() {
    this.CheckboxAllVatLieu = !this.CheckboxAllVatLieu;
    if (this.CheckboxAllVatLieu == true) {
      this.TeniconVatLieu = 'Bỏ chọn tất cả';
      this.ListtreeVLNCM.forEach(element => {
        element.checkbox = true;
        this.CheckboxVatLieu = true;
        this.CheckboxNhanCong = true;
        this.CheckboxMay = true;
        this.CheckboxMuaSam = true;
        this.dequyCheckBoxVL(element.children, true);
      });
    }
    else {
      this.TeniconVatLieu = 'Chọn tất cả';
      this.ListtreeVLNCM.forEach(element => {
        element.checkbox = false;
        this.CheckboxVatLieu = false;
        this.CheckboxNhanCong = false;
        this.CheckboxMay = false;
        this.CheckboxMuaSam = false;
        this.dequyCheckBoxVL(element.children, false)
      });
    }
  }
  CheckVatLieu() {
    this.CheckboxVatLieu = !this.CheckboxVatLieu;
    if (this.CheckboxVatLieu == true) {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 0) {
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 0) {
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
        }
      });
    }
  }
  CheckNhanCong() {
    this.CheckboxNhanCong = !this.CheckboxNhanCong;
    if (this.CheckboxNhanCong == true) {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 1) {
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 1) {
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
        }
      });
    }
  }
  CheckMay() {
    this.CheckboxMay = !this.CheckboxMay;
    if (this.CheckboxMay == true) {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 2) {
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 2) {
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
        }
      });
    }
  }
  CheckMuaSam() {
    this.CheckboxMuaSam = !this.CheckboxMuaSam;
    if (this.CheckboxMuaSam == true) {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 3) {
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else {
      this.ListtreeVLNCM.forEach(element => {
        if (element.LoaiDanhMuc == 3) {
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
        }
      });
    }
  }
  LuaChonDanhMucVL() {
    this.DanhmucService.GetListdsDungChung(this.dataitem.IDdmDungChungVL).subscribe(res => {
      const modalRef = this.modalService.open(HienThiVLNCMComponent, { backdrop: 'static', scrollable: true , size : 'xl'});
      modalRef.componentInstance.header = "";
      modalRef.componentInstance.isCombobox = true;
      modalRef.componentInstance.IDdmDungChung = this.dataitem.IDdmDungChungVL;
      modalRef.componentInstance.listDanhMucDungChung = this.listDanhMucDungChungVL;
      modalRef.componentInstance.LoaiDanhMucDungChung = 'LoaiVatLieuNhanCongMay';
      modalRef.componentInstance.Listtree = Object.assign([], res);
      modalRef.result.then((data) => {
        this.CheckboxAllVatLieu = false;
        this.ListVLNCM = [];
        this.ListtreeVLNCM = [];
        this.dataitem.IDdmDungChungVL = data.data.IDdmDungChung,
          this.ListtreeVLNCM = this.unflattenNew(data.data.listTree),
          this.ListVLNCM = data.data.listBanDau,
          this.ListtreeVLNCM.filter(obj => {
            obj.collspan = true;
            obj.check = false;
            obj.select = false;
          });
      }, (reason) => {
        // không
      });
    });

  }
  unflattenNew(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.IDLoaiDanhMuc] = arrElem;
      mappedArr[arrElem.IDLoaiDanhMuc]['children'] = [];
    }
    for (var IDLoaiDanhMuc in mappedArr) {
      if (mappedArr.hasOwnProperty(IDLoaiDanhMuc)) {
        mappedElem = mappedArr[IDLoaiDanhMuc];
        if (mappedElem.IDParent != -1) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);

        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  LuaChonDanhMucLCT() {
    this.DanhmucService.GetListdsDungChung(this.dataitem.IDdmDungChungLCT).subscribe(res => {
      const modalRef = this.modalService.open(HienThiVLNCMComponent, { backdrop: 'static', scrollable: true , size : 'xl'});
      modalRef.componentInstance.header = "";
      modalRef.componentInstance.isCombobox = true;
      modalRef.componentInstance.IDdmDungChung = this.dataitem.IDdmDungChungLCT;
      modalRef.componentInstance.listDanhMucDungChung = this.listDanhMucDungChungLCT;
      modalRef.componentInstance.LoaiDanhMucDungChung = 'LoaiCongTrinh';
      modalRef.componentInstance.Listtree = Object.assign([], res);
      modalRef.result.then((data) => {
        this.CheckboxAllCongTrinh = false;
        this.ListLoaiCongTrinh = [];
        this.Listtree = [];
        this.dataitem.IDdmDungChungLCT = data.data.IDdmDungChung,
          this.Listtree = data.data.listTree,
          this.ListLoaiCongTrinh = data.data.listBanDau,
          this.Listtree.filter(obj => {
            obj.collspan = true;
            obj.check = false;
            obj.select = false;
          });
      }, (reason) => {
        // không
      });
    });
  }
  listdmTinh() {
    this.ListTinhPost = []
    this.DanhmucService.GetListdmTinh(this.sFilter).subscribe(res => {
            this.ListTinhPost = res;
    });
  }
  refresh() {
    this.ListTinhPost = []
    this.DanhmucService.GetListdmTinh("").subscribe(res => {
            this.ListTinhPost = res;
    });
  }
}
