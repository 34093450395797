import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

export const httpOptions = {
    headers: new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        //'Accept':  'application/json',
        //'Origin': 'http://serverduan:2626'

        //,'Authorization': 'my-auth-token',
    })
    , withCredentials: true
};


@Injectable({
  providedIn: 'root'
})
export class HostService {

  constructor() { }

  // // // location
  public static serverUrl = 'https://csg.cuckinhtexd.gov.vn/CSG/';
  public static taianh = 'https://csg.cuckinhtexd.gov.vn/CSG/';

  // build //ng build  --baseHref=./ ng serve --host 10.0.8.72
  // public static serverUrl = '/CSG/';
  // public static taianh = '/CSG'; 
}
