import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { CongboComponent } from '../khuvuc/NgbModal/congbo/congbo.component';

@Component({
  selector: 'app-dscongbo',
  templateUrl: './dscongbo.component.html',
  styleUrls: ['./dscongbo.component.css']
})
export class DscongboComponent implements OnInit {

  listItem: any = [];
  listtinh: any = [];
  listnam: any = [];
  listthoigian: any = [];
  dataitem: any = {};
  listquy: any = [];
  listthang: any = [];

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenChiSoGia = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    this.getthang();
    this.getquy();
    this.getthoigian();
    this.GetListdmTinh();
  }

  getthang() {
    this.dataitem.Thang = moment().month();
    for (let i = 1; i <= 12; i++) {
      let data: any = { Ma: i, Ten: `${i}` }
      this.listthang.push(data);
    }
  }

  getquy() {
    this.listquy = [
      {
        Ma: 1,
        Ten: '1',
      },
      {
        Ma: 2,
        Ten: '2',
      },
      {
        Ma: 3,
        Ten: '3',
      },
      {
        Ma: 4,
        Ten: '4',
      },
    ]
  }

  getthoigian() {
    this.listthoigian = [
      // {
      //   Ma: 0,
      //   Ten: 'Năm',
      // },
      {
        Ma: 1,
        Ten: 'Quý',
      },
      // {
      //   Ma: 2,
      //   Ten: 'Tháng',
      // },
    ];
  }

  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if (res.length > 0) {
        this.dataitem.IDdmTinh = res[0].ID;
      }
      this.GetListdsCongBoChiSoGia();
    });
  }
  GetListdsCongBoChiSoGia() {
    let data: any = {
      "Nam": this.dataitem.Nam,
      "NamGoc": this.dataitem.NamGoc,
      "IDdmTinh": this.dataitem.IDdmTinh,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.ApiService.GetListdsCongBoChiSoGia(data).subscribe(res => {
      this.listItem = res;
      for (let i = 0; i < this.listItem.length; i++) {
        for (let j = 0; j < this.listthoigian.length; j++) {
          if (this.listItem[i].LoaiThoiGian == this.listthoigian[j].Ma) {
            this.listItem[i].TenLoaiThoiGian = this.listthoigian[j].Ten;
          }
        }
      }
    });
  }

  congbo() {
    let item: any ={
      ID: 0,
      TenChiSoGia: this.dataitem.TenChiSoGia,
      NamGoc: this.dataitem.NamGoc,
      NamCongBo: this.dataitem.Nam,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: true,
      IDThongTinUser: this.dataitem.IDThongTinUser
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    // modalRef.componentInstance.listtinh = this.listtinh;
    // modalRef.componentInstance.listthoigian = this.listthoigian;
    // modalRef.componentInstance.listthang = this.listthang;
    // modalRef.componentInstance.listquy = this.listquy;
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
      this.GetListdsCongBoChiSoGia();
    }, (reason) => {
      // không
    });
  }

  taixuong(tenfile) {
    this.MyService.taixuong(tenfile);
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.ApiService.DeleteCongBo(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdsCongBoChiSoGia();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }
  edit(item) {
    this.ApiService.GetdsCongBoChiSoGia(item.ID).subscribe(res => {
      const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
      modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(res));
      // modalRef.componentInstance.listtinh = this.listtinh;
      // modalRef.componentInstance.listthoigian = this.listthoigian;
      // modalRef.componentInstance.listthang = this.listthang;
      // modalRef.componentInstance.listquy = this.listquy;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListdsCongBoChiSoGia();
      }, (reason) => {
        // không
      });
    });
  }
}
