import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public Url = `${HostService.serverUrl}ChiSoGia`;

  GetBangGia(currentPage,TimKiem,IDdmTinh,Loai, Nam,soVanBan?:any) {
    // let api = `https://cuckinhtexd.gov.vn/BangGiaVatLieu/TimKiemBangGia?pageIndex=${currentPage}&soVanBan=&tenBangGia=&idTinh=${IDdmTinh}&nam=${Nam}&idLoaiBangGia=${TimKiem}&tuNgay=&denNgay=&loai=${Loai}`;
    let api = `https://cuckinhtexd.gov.vn/BangGiaVatLieu/TimKiemBangGia?pageIndex=${currentPage}&soVanBan=${soVanBan}&tenBangGia=${TimKiem}&idTinh=${IDdmTinh}&nam=${Nam}&idLoaiBangGia=''&tuNgay=&denNgay=&loai=${Loai}`;
    return this.http.get<any>(api, httpOptions);
  }
  GetChiTietVLNCM(currentPage,IDBangGia, tenVatLieu, IDdmTinh, Nam) {
    let api = `https://cuckinhtexd.gov.vn/BangGiaVatLieu/SearchBangGiaVatLieu?pageIndex=${currentPage}&tenVatLieu=${tenVatLieu}&idBangGia=${IDBangGia}&idTinh=${IDdmTinh}&idQuyThang=undefined&nam=&idLoaiBangGia=undefined&excel=false`;
    return this.http.get<any>(api, httpOptions);
  }
  GetChiTietNhanCong(currentPage,IDBangGia, tenVatLieu, IDdmTinh, Nam) {
    let api = `https://cuckinhtexd.gov.vn/BangGiaNhanCong/SearchBangGiaNhanCong?pageIndex=${currentPage}&tenVatLieu=${tenVatLieu}&idBangGia=${IDBangGia}&idTinh=${IDdmTinh}&idQuyThang=undefined&nam=&idLoaiBangGia=undefined&excel=false`;
    return this.http.get<any>(api, httpOptions);
  }
  GetChiTietMay(currentPage,IDBangGia, tenVatLieu, IDdmTinh, Nam) {
    let api = `https://cuckinhtexd.gov.vn/BangGiaMayThiCong/SearchBangGiaMayThiCong?pageIndex=${currentPage}&tenVatLieu=${tenVatLieu}&idBangGia=${IDBangGia}&idTinh=${IDdmTinh}&idQuyThang=undefined&nam=&idLoaiBangGia=undefined&excel=false`;
    return this.http.get<any>(api, httpOptions);
  }
  GetGia(data) {
    let api = `${this.Url}/GetGia`;
    return this.http.post<any>(api, data, httpOptions);
  }
  SetGia(data) {
    let api = `${this.Url}/SetGia`;
    return this.http.post<any>(api, data, httpOptions);
  }
  KiemTraTrungDonViTinh(data) {
    let api = `${this.Url}/KiemTraTrungDonViTinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  SetGiaApGiaThongBao(data) {
    let api = `${this.Url}/SetGiaApGiaThongBao`;
    return this.http.post<any>(api, data, httpOptions);
  }
  ImportGia(FileName, IDdmKhuVuc, IDThongTinUser, Nam) {
    let api = `${this.Url}/ImportGia?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongCongTrinh(FileName, IDdmKhuVuc, IDThongTinUser, Nam) {
    let api = `${this.Url}/ImportTytrongCongTrinh?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongVLNCM(FileName, IDdmKhuVuc, IDThongTinUser) {
    let api = `${this.Url}/ImportTytrongVLNCM?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(api, httpOptions);
  }

  ImportTytrongDiaPhuong(FileName, IDThongTinUser) {
    let api = `${this.Url}/ImportTytrongDiaPhuong?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(api, httpOptions);
  }

  GetListTyTrongCongTrinh(data) {
    let api = `${this.Url}/GetListTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongCongTrinh(data) {
    let api = `${this.Url}/SetTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongCongTrinh(data) {
    let api = `${this.Url}/ExportTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongDiaPhuong(data) {
    let api = `${this.Url}/ExportTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportTyTrongPhanXayDung(data) {
    let api = `${this.Url}/ExportTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportGia(data) {
    let api = `${this.Url}/ExportGia`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderTyTrongPhanXayDung(data) {
    let api = `${this.Url}/GetHeaderTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderTyTrongPhanThietBi(data) {
    let api = `${this.Url}/GetHeaderTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetHeaderChiPhiVatLieuNhanCongMay(data) {
    let api = `${this.Url}/GetHeaderChiPhiVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListTyTrongPhanXayDung(data) {
    let api = `${this.Url}/GetListTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }
  // GetListVLNCMCongTrinh(data) {
  //   let api = `${this.Url}/GetListTyTrongPhanXayDung`;
  //   return this.http.post<any>(api, data, httpOptions);
  // }
  SetdsCongTrinhVatLieu(data) {
    let api = `${this.Url}/SetdsCongTrinhVatLieu`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongPhanXayDung(data) {
    let api = `${this.Url}/SetTyTrongPhanXayDung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // GetdsVatLieuCongNhanMay(data) {
  //   let api = `${this.Url}/GetdsVatLieuCongNhanMay`;
  //   return this.http.post<any>(api, data, httpOptions);
  // }

  GetdsVatLieuNhanCongMay(data) {
    let api = `${this.Url}/GetdsVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetdsVatLieuNhanCongMay(data) {
    let api = `${this.Url}/SetdsVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetdsLoaiCongTrinh(data) {
    let api = `${this.Url}/GetdsLoaiCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetCongTrinhDienHinh(IDdsLoaiCongTrinh, IDdmKhuVuc, IDThongTinUser, Nam) {
    let api = `${this.Url}/GetCongTrinhDienHinh?IDdsLoaiCongTrinh=${IDdsLoaiCongTrinh}&IDdmKhuVuc=${IDdmKhuVuc}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  SetdsLoaiCongTrinh(data) {
    let api = `${this.Url}/SetdsLoaiCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListdsVatLieuCongNhanMayTheoKhuVuc(data) {
    let api = `${this.Url}/GetListdsVatLieuCongNhanMayTheoKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }
  
  GetListVatLieuTheoCongTrinh(data) {
    let api = `${this.Url}/GetListVatLieuTheoCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetChiPhiVatLieuNhanCongMay(data) {
    let api = `${this.Url}/GetChiPhiVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetHeaderTyTrongDiaPhuong(data) {
    let api = `${this.Url}/GetHeaderTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetListTyTrongDiaPhuong(data) {
    let api = `${this.Url}/GetListTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongDiaPhuong(data) {
    let api = `${this.Url}/SetTyTrongDiaPhuong`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaKhuVuc(data) {
    let api = `${this.Url}/GetChiSoGiaKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaDiaPhuongKhuVuc(data) {
    let api = `${this.Url}/GetChiSoGiaDiaPhuongKhuVuc`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderChiSoGiaDiaPhuongCongTrinh(data) {
    let api = `${this.Url}/GetHeaderChiSoGiaDiaPhuongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetChiSoGiaDiaPhuongCongTrinh(data) {
    let api = `${this.Url}/GetChiSoGiaDiaPhuongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongVLNCM(data) {
    let api = `${this.Url}/SetTyTrongVLNCM`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // ////////////////////////////////////////

  GetGiaThietBi(data) {
    let api = `${this.Url}/GetGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetGiaThietBi(data) {
    let api = `${this.Url}/SetGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportGiaThietBi(FileName, IDdmKhuVuc, IDThongTinUser, Nam) {
    let api = `${this.Url}/ImportGiaThietBi?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ExportGiaThietBi(data) {
    let api = `${this.Url}/ExportGiaThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  // -------------------------------

  GetListTyTrongPhanThietBi(data) {
    let api = `${this.Url}/GetListTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongThietBi(data) {
    let api = `${this.Url}/SetTyTrongThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportTytrongThietBi(FileName, IDdmKhuVuc, Nam) {
    let api = `${this.Url}/ImportTytrongThietBi?FileName=${FileName}&IDdmKhuVuc=${IDdmKhuVuc}&Nam=${Nam}`;
    return this.http.get<any>(api, httpOptions);
  }

  ExportTyTrongPhanThietBi(data) {
    let api = `${this.Url}/ExportTyTrongPhanThietBi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  TinhChiSoGia(data) {
    let api = `${this.Url}/TinhChiSoGia`;
    return this.http.post<any>(api, data, httpOptions);
  }

  TinhTyTrongCongTrinh(data) {
    let api = `${this.Url}/TinhTyTrongCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetListTyTrongVung(data) {
    let api = `${this.Url}/GetListTyTrongVung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  SetTyTrongVung(data) {
    let api = `${this.Url}/SetTyTrongVung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ImportTytrongVung(FileName, IDThongTinUser) {    
    let api = `${this.Url}/ImportTytrongVung?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(api, httpOptions);
  }  

  ExportTyTrongVung(data) {
    let api = `${this.Url}/ExportTyTrongVung`;
    return this.http.post<any>(api, data, httpOptions);
  }  

  GetChiSoGiaVung(data) {
    let api = `${this.Url}/GetChiSoGiaVung`;
    return this.http.post<any>(api, data, httpOptions);
  } 

  GetHeaderChiSoGiaVungTheoCongTrinh(data) {
    let api = `${this.Url}/GetHeaderChiSoGiaVungTheoCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }  

  ExportCongBoChiSoGia(data) {
    let api = `${this.Url}/ExportCongBoChiSoGia`;
    return this.http.post<any>(api, data, httpOptions);
  } 
  ExportCongBoChiSoGiaTrenCuc(data) {
    let api = `https://cuckinhtexd.gov.vn/api/pushdata/csg`;
    return this.http.post<any>(api, data, httpOptions);
  } 
  SetCongBoChiSoGia(ID) {
    let api = `${this.Url}/SetCongBo?ID=${ID}`;
    return this.http.get<any>(api, httpOptions);
  }
  GetListdsCongBoChiSoGia(data) {
    let api = `${this.Url}/GetListdsCongBoChiSoGia`;
    return this.http.post<any>(api, data, httpOptions);
  }   
  GetdsCongBoChiSoGia(Id) {
    let api = `${this.Url}/GetdsCongBoChiSoGia?Id=${Id}`;
    return this.http.get<any>(api, httpOptions);
  }   
  DeleteCongBo(data) {
    let api = `${this.Url}/DeleteCongBo`;
    return this.http.post<any>(api, data, httpOptions);
  }   
    
  ListKhoaDuLieu(data) {
    let api = `${this.Url}/ListKhoaDuLieu`;
    return this.http.post<any>(api, data, httpOptions);
  }   
  listLogin(data) {
    let api = `https://dmdgxd.cuckinhtexd.gov.vn/api/api/checkapp/check`;
    return this.http.post<any>(api,data, httpOptions);
  } 
  DeleteGia(data) {
    let api = `${this.Url}/DeleteGia`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListFileMau() {
    let url = `${this.Url}/GetListFileMau`;
    return this.http.get<any>(url, httpOptions);
  }
  SetFileMau(FileName, TenFileGoc) {
    let api = `${this.Url}/SetFileMau?FileName=${FileName}&TenFileGoc=${TenFileGoc}`;
    return this.http.get<any>(api, httpOptions);
  }
  CheckTinhTyTrong(data) {
    let api = `${this.Url}/CheckTinhTyTrong`;
    return this.http.post<any>(api,data, httpOptions);
  }
}