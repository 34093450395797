import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService, httpOptions } from './../../services/host.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-khuvuc',
  templateUrl: './khuvuc.component.html',
  styleUrls: ['./khuvuc.component.css']
})
export class KhuvucComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listquy: any = [];
  listthang: any = [];
  listnam: any = [];

  dataitem: any = {};

  uploader: FileUploader;

  listthoigian: any = [];

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.getthang();
    this.getquy();
    this.getthoigian();
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;

    this.GetListdmTinh();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportGia(res[0], this.dataitem.IDdmKhuVuc, this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
      this.GetChiSoGiaKhuVuc()
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  getthoigian() {
    this.listthoigian = [
      {
        Ma: 0,
        Ten: 'Năm',
      },
      {
        Ma: 1,
        Ten: 'Quý',
      },
      {
        Ma: 2,
        Ten: 'Tháng',
      },
    ];
    this.dataitem.ThoiGian = this.listthoigian[0].Ma;
  }
  getthang() {
    this.dataitem.Thang = moment().month();
    for (let i = 1; i <= 12; i++) {
      let data: any = { Ma: i, Ten: `${i}` }
      this.listthang.push(data);
    }
  }

  getquy() {
    this.listquy = [
      {
        Ma: 1,
        Ten: '1',
      },
      {
        Ma: 2,
        Ten: '2',
      },
      {
        Ma: 3,
        Ten: '3',
      },
      {
        Ma: 4,
        Ten: '4',
      },
    ]
  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length === 0){
        this.GetChiSoGiaKhuVuc();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;      
        this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
      }
    });
  }
  chonthoigian() {
    if (this.dataitem.ThoiGian == 0) {
      this.dataitem.Thang = undefined;
      this.dataitem.Quy = undefined;
    }
    else if (this.dataitem.ThoiGian == 1) {
      this.dataitem.Thang = undefined;
      this.dataitem.Quy = this.listquy[0].Ma;
    }
    else if (this.dataitem.ThoiGian == 2) {
      this.dataitem.Thang = this.listthang[0].Ma;
      this.dataitem.Quy = undefined;
    }
    this.GetChiSoGiaKhuVuc();
  }

  GetListdmKhuVuc(IDdmTinh,IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh,IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetChiSoGiaKhuVuc();
    });
  }

  refresh() {
    this.dataitem = {};
    this.dataitem.sFilter = "";
    this.getthang();
    this.getquy();
    this.getthoigian();
    this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
  }

  GetChiSoGiaKhuVuc() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      LoaiThoiGian: this.dataitem.ThoiGian,
      Nam: this.dataitem.Nam,
      Thang: this.dataitem.Thang != undefined ? this.dataitem.Thang : 0,
      Quy: this.dataitem.Quy != undefined ? this.dataitem.Quy : 0,
      IDThongTinUser: this.dataitem.IDThongTinUser,
      sFilter: this.dataitem.sFilter != undefined && this.dataitem.sFilter != null ? this.dataitem.sFilter : "",
    };
    this.ApiService.GetChiSoGiaKhuVuc(data).subscribe(res => {
      this.TreeVLNCM = res;
      // this.TreeVLNCM = [...res];
    });
  }
  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ExportGia(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

  TinhChiSoGia() {
    this.spinner.show();
    let data: any =
    {
      Nam: this.dataitem.Nam,
      IDdmTinh: this.dataitem.IDdmTinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
      NamGoc: this.dataitem.NamGoc,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
    };
    this.ApiService.TinhChiSoGia(data).subscribe(res => {
      if (res.State == 1) {
        this.toastr.success(res.message);
        this.GetChiSoGiaKhuVuc();
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();
    });
  }  
}
