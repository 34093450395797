import { Component, OnInit, DebugNode } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { MyService } from './../../../../services/my.service';
import { ThemmoitreeLuaChonComponent } from '../themmoitree/themmoitree.component';
import { UpdatekhuvucLuaChonComponent } from '../updatekhuvuc/updatekhuvuc.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { LuaChonPhuongThucCSGComponent } from '../luachoncsg/luachoncsg.component';
@Component({
  selector: 'app-luachondanhmucchung',
  templateUrl: './luachondanhmucchung.component.html',
  styleUrls: ['./luachondanhmucchung.component.css']
})
export class LuaChonDanhMucDungChungCSGComponent implements OnInit {
  type: any = '';
  TeniconCongTrinh: any = '';
  TeniconVatLieu: any = '';
  item: any = {};
  ItemPOST: any = {};
  thongtinuser: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem: any = {};
  dataitemThongTinUser: any = {};
  listdmDungChung: any = [];
  CheckboxAllCongTrinh: any = false;
  CheckboxAllVatLieu: any = false;
  CheckboxVatLieu: any = false;
  CheckboxNhanCong: any = false;
  CheckboxMay: any = false;
  CheckboxMuaSam: any = false;
  CheckboxdmDungChungLCT: any = false;
  CheckboxdmDungChungVL: any = false;
  listDanhMucDungChungVL: any = [];
  listDanhMucDungChungLCT: any = [];
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {}
  ngOnInit() {
  }
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.ID] = arrElem;
      mappedArr[arrElem.ID]['children'] = [];
    }
    for (var ID in mappedArr) {
      if (mappedArr.hasOwnProperty(ID)) {
        mappedElem = mappedArr[ID];
        if (mappedElem.IDParent != -1) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);
        }
        else {

          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  Onclose() {
    this.activeModal.close();
  }
  QuayLai(){
    this.activeModal.close();
    const modalRef = this.modalService.open(LuaChonPhuongThucCSGComponent, {backdrop: 'static', centered: true });
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
}
