import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import * as moment from 'moment';
import { cpuUsage } from 'process';

@Component({
  selector: 'app-chiphivatlieunhancongmay',
  templateUrl: './chiphivatlieunhancongmay.component.html',
  styleUrls: ['./chiphivatlieunhancongmay.component.css']
})
export class ChiPhiVatLieuNhanCongMayComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  Nam: any = 0;
  type: any = '';
  listvatlieu: any = [];
  listnhancong: any = [];
  listmaythicong: any = [];
  listchiphimuasam: any = [];
  IDdsLoaiCongTrinh: any = 0;
  isDungChung: any = false;
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
      obj.children = [];
    });
    if (this.checkListtree.length > 0) {
      for (let i = 0; i < this.Listtree.length; i++) {
        for (let j = 0; j < this.checkListtree.length; j++) {
          if (this.Listtree[i].IDdsVatLieuNhanCongMay == this.checkListtree[j].IDdsVatLieuNhanCongMay) {
            this.Listtree[i].check = true;
            this.Listtree[i].IDdatontai = this.checkListtree[j].IDdsVatLieuNhanCongMay;
          }
          else {
            this.Listtree[i].IDdatontai = 0;
          }
        }
      }
    }
    else {
      this.Listtree.filter(obj => {
        obj.check = false;
        obj.IDdatontai = 0;
      });
    }
    this.Listtree[0].select = true;
  }

  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  CheckTREE(item) {
    // 0 vật liệu
    // 1 Nhân công
    // 2 máy
    item.check = !item.check;
    if (item.check) {
      let data: any = {
        "ID": item.IDdatontai,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "IDdmTinh": this.IDdmTinh,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "IDdsVatLieuNhanCongMay": item.IDdsVatLieuNhanCongMay,
        // "IDdsVatLieuNhanCongMay": item.ID,
        "TendmVatLieuNhanCongMay": item.TendmVatLieuNhanCongMay,
        "Nam": this.Nam,
        "DonViTinh": item.DonViTinh,
      }
      if(this.isDungChung == true){
        data.IDdsVatLieuNhanCongMay = item.IDdsVatLieuNhanCongMay;
      }
      switch (this.type) {
        case "VatLieu":
          data.LoaiDanhMuc = 0;
          this.listvatlieu.push(data);
          break;
        case "NhanCong":
          data.LoaiDanhMuc = 1;
          this.listnhancong.push(data);
          break;
        case "ThiCong":
          data.LoaiDanhMuc = 2;
          this.listmaythicong.push(data);
          break;
        case "MuaSam":
          data.LoaiDanhMuc = 3;
          this.listchiphimuasam.push(data);
          break;
      }
    }
    else {
      switch (this.type) {
        case "VatLieu":
          this.spliceList(item, this.listvatlieu);
          break;
        case "NhanCong":
          this.Listpost = this.listnhancong;
          this.spliceList(item, this.Listpost);
          break;
        case "ThiCong":
          this.Listpost = this.listmaythicong;
          this.spliceList(item, this.Listpost);
          break;
        case "MuaSam":
          this.Listpost = this.listchiphimuasam;
          this.spliceList(item, this.Listpost);
          break;
      };
    }
  }

  spliceList(item, Listpost) {
    for (let i = 0; i < Listpost.length; i++) {
      if (item.IDdsVatLieuNhanCongMay == Listpost[i].IDdsVatLieuNhanCongMay) {
        switch (this.type) {
          case "VatLieu":
            item.IDdatontai = 0;
            this.listvatlieu.splice(i, 1);
            break;
          case "NhanCong":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
          case "ThiCong":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
          case "MuaSam":
            item.IDdatontai = 0;
            this.Listpost.splice(i, 1);
            break;
        };
        break;
      }
    }
  }

  Onclose() {
    this.activeModal.close(
      {
        vatlieu: this.listvatlieu,
        nhancong: this.listnhancong,
        maythicong: this.listmaythicong,
        chiphimuasam: this.listchiphimuasam,
      }
    );
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
