import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService, httpOptions } from './../../services/host.service';

@Component({
  selector: 'app-congtrinhtheovung',
  templateUrl: './congtrinhtheovung.component.html',
  styleUrls: ['./congtrinhtheovung.component.css']
})
export class CongtrinhtheovungComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];
  ListVUNG: any = [];

  dataitem: any = {};

  uploader: FileUploader;

  files: any = [];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() { 
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.getnam();
    this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).IDdmTinh;
    this.GetListdmVungTheoUser();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportGia(res[0], this.dataitem.IDdmKhuVuc, this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
      // this.GetGia()
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  getnam() {
    this.dataitem.Nam = moment().year();
    for (let i = moment().year() - 10; i <= moment().year() + 1; i++) {
      let data: any = { nam: i }
      this.listnam.push(data);
    }
  } 
  GetListdmVungTheoUser() {
    this.dataitem.IDdmVung = undefined;
    this.ListVUNG = [];
    let data: any = {};
    this.DanhmucService.GetListdmVungTheoUser().subscribe(res => {
      this.ListVUNG = res;
      this.dataitem.IDdmVung = this.ListVUNG[0].ID;
      this.GetChiSoGiaVung();      
    });
  } 
  GetChiSoGiaVung() {
    this.SoCotCon = 0;
    let data: any =
    {
      IDdmVung: this.dataitem.IDdmVung != undefined ? this.dataitem.IDdmVung : 0,
      Nam: this.dataitem.ThoiGian == 0 ? 0 : this.dataitem.Nam
    };
    this.ApiService.GetHeaderChiSoGiaVungTheoCongTrinh(data).subscribe(resHeader => {
      // this.colparen = res.filter(obj => {
      //   if (obj.SoCotCon > 0) {
      //     this.SoCotCon += obj.SoCotCon;
      //     return obj;
      //   }
      // });
      this.colschidren = resHeader;
      this.ApiService.GetChiSoGiaVung(data).subscribe(res => {
        this.files = res;
      });
    });
  }

  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
    };
    this.ApiService.ExportGia(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
