import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { CapnhattytrongthietbiComponent } from './NgbModal/capnhattytrongthietbi/capnhattytrongthietbi.component';

@Component({
  selector: 'app-tytrongthietbi',
  templateUrl: './tytrongthietbi.component.html',
  styleUrls: ['./tytrongthietbi.component.css']
})
export class TytrongthietbiComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};

  files: TreeNode[];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;
  uploader: FileUploader;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.getnam();
    this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
    this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
    // this.GetListdmTinh();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportTytrongThietBi(res[0], this.dataitem.IDdmKhuVuc, this.dataitem.Nam).subscribe(res => {
      this.GetListTyTrongPhanThietBi();
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }


  getnam() {
    this.dataitem.Nam = moment().year();
    for (let i = moment().year() - 10; i <= moment().year() + 1; i++) {
      let data: any = { nam: i }
      this.listnam.push(data);
    }
  }

  // GetListdmTinh() {
  //   let data: any = {};
  //   this.DanhmucService.GetListdmTinh(data).subscribe(res => {
  //     this.listtinh = res;
  //     this.dataitem.IDdmTinh = res[0].ID;
  //     this.GetListdmKhuVuc(this.dataitem.IDdmTinh)
  //   });
  // }

  GetListdmKhuVuc(IDdmTinh, IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetListTyTrongPhanThietBi();
    });
  }

  GetListTyTrongPhanThietBi() {
    this.SoCotCon = 0;
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
    };
    this.ApiService.GetHeaderTyTrongPhanThietBi(data).subscribe(res => {
      this.colparen = res.filter(obj => {
        if (obj.SoCotCon > 0) {
          this.SoCotCon += obj.SoCotCon;
          return obj;
        }
      });
      this.colschidren = res.filter(obj => {
        if (obj.SoCotCon == 0) {
          return obj;
        }
      });
      this.ApiService.GetListTyTrongPhanThietBi(data).subscribe(res => {
        this.files = res;
      });
    });
  }

  nhaptytrong(item, tenRow, check) {
    if (!check) {
      const modalRef = this.modalService.open(CapnhattytrongthietbiComponent, { backdrop: 'static', scrollable: true });
      modalRef.componentInstance.item = Object.assign({}, item);
      modalRef.componentInstance.tenRow = tenRow;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListTyTrongPhanThietBi();
      }, (reason) => {
        // không
      });
    }
  }

  // chonloai() {
  //   let data: any = {
  //     IDdmTinh: this.dataitem.IDdmTinh,
  //     Nam: moment().year()
  //   };
  //   this.ApiService.GetdsLoaiCongTrinh(data).subscribe(res => {
  //     this.DanhmucService.GetListdmLoaiCongTrinh().subscribe(restytrongcongtrinh => {
  //       const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
  //       modalRef.componentInstance.checkListtree = Object.assign([], res.items);
  //       modalRef.componentInstance.Listtree = Object.assign([], restytrongcongtrinh);
  //       modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
  //       modalRef.result.then((data) => {
  //         this.GetListTyTrongPhanThietBi();
  //       }, (reason) => {
  //         // không
  //       });
  //     });
  //   });
  // }

  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
    };
    this.ApiService.ExportTyTrongPhanThietBi(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
