import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { DanhmucService } from 'src/app/services/danhmuc.service';
@Component({
  selector: 'app-apgiathongbao',
  templateUrl: './apgiathongbao.component.html',
  styleUrls: ['./apgiathongbao.component.css']
})
export class ApGiaThongBaoComponent implements OnInit {

  ListIPOS: any = [];
  Listdata: any = [];
  listItemCheck: any = [];
  ListGia: any = {};
  ListIPOSChiTiet: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdsBangGia: any = 0;
  dmTinh: any = {};
  IDdmTinh: any = 0;
  Nam: any = 0;
  tenVatLieu : any = "";
  tkBangGia: any = "";
  objloaddata: any = {};
  itemdsBangGia: any = {};
  dataitem: any = {};
  dataitemChiTiet: any = {};
  Ipos: any = {};
  LoaiDanhMuc : any = 0;
  PageIndexChiTiet: any = 1;
  IDBangGia : any = 0;
  IDParent : any = 0;
  isParent : any = false;
  listnam: any = [];
  listtinh: any = []
  soVanBan: any = "";
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private DanhMucService: DanhmucService,
  ) { }

  ngOnInit() {
    this.dataitem.items = [];
    this.dataitem.Nam = this.Nam;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    let namHienTai = new Date;
    let nam = namHienTai.getFullYear();

    for(let i = nam - 9; i < nam + 1; i ++){
      if(i == this.dataitem.Nam)
      {
        this.dataitem.Nam = i;
      }
      this.listnam.push(i);
    }
    this.GetListdmTinh();
    this.DanhMucService.GetdmTinh(this.IDdmTinh?this.IDdmTinh : '').subscribe((res: any) =>{ 
      this.dmTinh = res;
    })
  }

  GetListdmTinh() {
    this.DanhMucService.GetListdmTinh(this.IDdmTinh?this.IDdmTinh : '').subscribe((res: any) =>{ 
      this.listtinh = res;
    })
  }
  
  GetChiTietVLNCM(item, isNew){
    if(item != '' ){
      this.itemdsBangGia = item;
      if(isNew == 1)
      {
        item.checkbox = !item.checkbox;
        this.ListIPOS.forEach(element => {
            if(element.IDBangGia != item.IDBangGia){
              element.checkbox = false;}
          });
          this.IDBangGia = item.IDBangGia
      }
    }
    if(this.LoaiDanhMuc === 3){
      this.ApiService.GetChiTietMay(this.PageIndexChiTiet,this.IDBangGia, this.tenVatLieu, this.dmTinh.IDCongThongTin,this.dataitem.Nam).subscribe(res => {
        this.ListIPOSChiTiet = Object.assign([], res.Items);
        this.ListIPOSChiTiet.filter(obj => {
          obj.check = false;
        });
        for(let i = 0; i< this.ListIPOSChiTiet.length; i ++){
          for(let j = 0; j < this.listItemCheck.length ; j ++){
            if(this.listItemCheck[j].ViTri == (i + 1 + (this.PageIndexChiTiet-1)*this.dataitemChiTiet.pageSize)){
              this.ListIPOSChiTiet[i].check = true;
            }
          }
        }
        this.dataitemChiTiet.TotalRow = res.TotalRow;
        this.PageIndexChiTiet = res.PageIndex;
        this.dataitemChiTiet.pageSize = res.pageSize;
      });
    }
    else if(this.LoaiDanhMuc === 2){
      this.ApiService.GetChiTietNhanCong(this.PageIndexChiTiet,this.IDBangGia, this.tenVatLieu, this.dmTinh.IDCongThongTin,this.dataitem.Nam).subscribe(res => {
        this.ListIPOSChiTiet = Object.assign([], res.Items);
        this.ListIPOSChiTiet.filter(obj => {
          obj.check = false;
        });
        for(let i = 0; i< this.ListIPOSChiTiet.length; i ++){
          for(let j = 0; j < this.listItemCheck.length ; j ++){
            if(this.listItemCheck[j].ViTri == (i + 1 + (this.PageIndexChiTiet-1)*this.dataitemChiTiet.pageSize)){
              this.ListIPOSChiTiet[i].check = true;
            }
          }
        }
        this.dataitemChiTiet.TotalRow = res.TotalRow;
        this.PageIndexChiTiet = res.PageIndex;
        this.dataitemChiTiet.pageSize = res.pageSize;
      });
    }
    else{
      if(this.LoaiDanhMuc === 1){
        this.ApiService.GetChiTietVLNCM(this.PageIndexChiTiet,this.IDBangGia, this.tenVatLieu, this.dmTinh.IDCongThongTin,this.dataitem.Nam).subscribe(res => {
          this.ListIPOSChiTiet = Object.assign([], res.Items);
          this.ListIPOSChiTiet.filter(obj => {
            obj.check = false;
          });
          for(let i = 0; i< this.ListIPOSChiTiet.length; i ++){
            for(let j = 0; j < this.listItemCheck.length ; j ++){
              if(this.listItemCheck[j].ViTri == (i + 1 + (this.PageIndexChiTiet-1)*this.dataitemChiTiet.pageSize)){
                this.ListIPOSChiTiet[i].check = true;
              }
            }
          }
          this.dataitemChiTiet.TotalRow = res.TotalRow;
          this.PageIndexChiTiet = res.PageIndex;
          this.dataitemChiTiet.pageSize = res.pageSize;
        });
      }
    }
  }

  GetBangGia(){
    // this.ApiService.GetBangGia(this.dataitem.PageIndex,this.tkBangGia, this.dmTinh.IDCongThongTin, this.LoaiDanhMuc, this.dataitem.Nam).subscribe(res => {
    //   this.ListIPOS = res.Items;
    //   this.dataitem.TotalRow = res.TotalRow;
    //   this.dataitem.PageIndex = res.PageIndex;
    //   this.dataitem.pageSize = res.pageSize;
    // });
    this.ApiService.GetBangGia(this.dataitem.PageIndex,this.tkBangGia, this.dataitem.idTinh?this.dataitem.idTinh:'', this.LoaiDanhMuc, this.dataitem.Nam, this.soVanBan).subscribe(res => {
      this.ListIPOS = res.Items;
      this.dataitem.TotalRow = res.TotalRow;
      this.dataitem.PageIndex = res.PageIndex;
      this.dataitem.pageSize = res.pageSize;
    });
  }
  CheckTREE(item, index) {
    item.check = !item.check;
    if(this.isParent == false){
      this.ListIPOSChiTiet.forEach(element => {
          element.check = false;
      });
      item.check = true;
      if (item.check) {
        let data: any = {
          "Gia": item.Gia,
        }
        this.ListGia = data;
      }
    }
    else{
      for (let i = 0; i < this.listItemCheck.length; i++) {
        if (this.listItemCheck[i].ViTri === index) {
          this.listItemCheck.splice(i, 1);
          break;
        }
      }
      if(item.check){
        item.ViTri = index;
        this.listItemCheck.push(item)
      }
    }
  }
  XacNhan() {
      this.activeModal.close(
          {data:this.ListGia}
      );
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  SetGiaApGiaThongBao() {
    this.listItemCheck.forEach(element => {
      if(element.check == true){
        let data: any = {
          TenVatLieu: element.Ten,
          DonViTinh: element.DonViTinh,
          Gia: element.Gia,
          LoaiThoiGian: ((this.itemdsBangGia.KieuBangGia || 1) - 1),
          ThoiGian: this.itemdsBangGia.ThangQuy,
          // Nam: this.itemdsBangGia.Nam,
          Nam: this.Nam,
          IDThongTinUser: this.dataitem.IDThongTinUser,
          IDdmTinh: this.dmTinh.ID,
          IDdmKhuVuc: this.IDdmKhuVuc,
          IDParent: this.IDParent,
          LoaiDanhMuc: (this.LoaiDanhMuc - 1),
          Ma: element.Ma,
        }
        this.Listdata.push(data);
      }
    });
    this.ApiService.KiemTraTrungDonViTinh(this.Listdata).subscribe(resGhiDe => {
      if (resGhiDe.State === 11) {
        const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
        modalRef.componentInstance.thongbao = "Đơn vị tính không đồng nhất";
        modalRef.result.then((any) => {
          this.ApiService.SetGiaApGiaThongBao(this.Listdata).subscribe(res => {
            if (res.State === 1) {
              this.toastr.success(res.message);
              this.Onclose();
            }
            else {
              this.toastr.error(res.message);      
            }
          });      
        }, (reason) => {
        });
      }
      else {this.ApiService.SetGiaApGiaThongBao(this.Listdata).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  });
  }
}
