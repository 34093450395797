import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { HienThiVLNCMComponent } from './NgbModal/hienthi-vlncm/hienthi-vlncm.component';
import { CapnhatDanhMucDungChungComponent } from 'src/app/thongtinchung/giavatlieunhancongmay/NgbModal/capnhatdanhmucchung/capnhatdanhmucchung.component';
import { ThemMoiDuAnDanhMucDungChungComponent } from 'src/app/login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { CongboComponent } from 'src/app/chisogia/khuvuc/NgbModal/congbo/congbo.component';
import { ApiService } from './../../services/api.service';
import { MyService } from './../../services/my.service';

@Component({
  selector: 'app-danhmucdungchung',
  templateUrl: './danhmucdungchung.component.html',
  styleUrls: ['./danhmucdungchung.component.css']
})
export class DanhMucDungChungComponent implements OnInit {

  Listtree: any = [];

  listtinh: any = [];
  listloaidanhmuc: any = [];
  listkhuvuc: any = [];  
  List: any = [];
  listIPOS: any = [];
  itemSELeCT: any = {};
  quyenxoa: boolean = false;
  CheckUserAD: boolean = false;
  dataitem: any = {};
  checkListtree: any = [];
  Listpost: any = [];
  TimChaTree: any = [];
  data: any = {};
  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,

  ) { }

  ngOnInit() {
    this.data = {
      CurrentPage: 1,
      PageSize: 25,
      sFilter: "",
    };
    this.dataitem.paging={};
    this.dataitem.TotalItem = 0;
    this.data.IDUser = JSON.parse(localStorage.getItem('currentUser')).UserName;
    this.GetListdmLoaidanhMuc();
    this.GetListdmDungChung();
    
  }

  GetListdmLoaidanhMuc() {
    this.listloaidanhmuc = [];
    let data1: any = { Ma: 'LoaiCongTrinh', Ten: 'Loại công trình' }
    let data2: any = { Ma: 'LoaiVatLieuNhanCongMay', Ten: 'Loại vật liệu nhân công máy mua sắm' }
    let data3: any = { Ma: 'CongTrinhDienHinh', Ten: 'Công trình đại diện' }
    let data4: any = { Ma: 'CongBo', Ten: 'Công bố' }
    this.listloaidanhmuc.push(data1)
    this.listloaidanhmuc.push(data2)
    this.listloaidanhmuc.push(data3)
    this.listloaidanhmuc.push(data4)
    this.dataitem.LoaiDanhMuc = this.listloaidanhmuc[0].Ma;
  }
  GetListdmDungChung() {
    this.listIPOS = [];
    if(this.dataitem.LoaiDanhMuc === 'CongTrinhDienHinh')
    {
      this.DanhmucService.GetListdmDungChungCongTrinhDienHinh(this.dataitem.LoaiDanhMuc, this.data.CurrentPage, this.data.sFilter, this.data.IDUser).subscribe(res => {
        this.listIPOS = res.items;
        this.dataitem.paging = res.paging;
        this.dataitem.TotalItem = res.paging.TotalItem;
      });
    }
    else if(this.dataitem.LoaiDanhMuc === 'CongBo'){
      this.DanhmucService.GetListdmDungChungCongBo(this.data.CurrentPage).subscribe(res => {
        this.listIPOS = res.items;
        this.dataitem.paging = res.paging;
        this.dataitem.TotalItem = res.paging.TotalItem;
      });
    }
    else 
    {
      this.DanhmucService.GetListdmDungChung(this.dataitem.LoaiDanhMuc, this.data.CurrentPage, this.data.sFilter, this.data.IDUser).subscribe(res => {
        this.listIPOS = res.items;
        this.dataitem.paging = res.paging;
        this.dataitem.TotalItem = res.paging.TotalItem;
      });
    }
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmDungChung(item.IDdmDungChung).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmDungChung();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
    });
  }
  dsLoaiDanhMuc(item){
    this.DanhmucService.GetListdsDungChung(item.ID).subscribe(res => {
        const modalRef = this.modalService.open(HienThiVLNCMComponent, {backdrop: 'static', scrollable: true , size : 'xl'});
        modalRef.componentInstance.header = "";
        modalRef.componentInstance.isNotTimKiem = true;
        modalRef.componentInstance.LoaiDanhMucDungChung = item.LoaiDanhMuc;
        modalRef.componentInstance.Listtree = Object.assign([], res);
        modalRef.result.then((data) => {
        }, (reason) => {
          // không
        });
    });
  }
  editCongTrinhdienHinh(item){
    this.DanhmucService.GetdsDungChungCongTrinhDienHinhView(item.IDdmDungChung).subscribe(res => {
        const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, { size: 'fullscreen',backdrop: 'static', scrollable: true });
        modalRef.componentInstance.isEdit = true;
        modalRef.componentInstance.item = res;
        modalRef.componentInstance.IddmDungChung = item.ID;
        modalRef.componentInstance.isHienThi = true;
        modalRef.result.then((data) => {
          this.GetListdmDungChung();
        }, (reason) => {
          // không
        });
    });
  }
  capnhat(item){
    const modalRef = this.modalService.open(CapnhatDanhMucDungChungComponent, { backdrop: 'static' });
    modalRef.componentInstance.IDParent = item;
    modalRef.componentInstance.header = "Cập nhật";
    modalRef.result.then((data) => {
      this.GetListdmDungChung();
    }, (reason) => {
    });
  }
  CongTrinhDienHinh(){
    const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen',backdrop: 'static', scrollable: true});
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.result.then(() => {
    // this.activeModal.close();
    }, (reason) => {
    });
  }
  editCongBo(item) {
    this.ApiService.GetdsCongBoChiSoGia(item.ID).subscribe(res => {
      const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
      modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(res));
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListdmDungChung();
      }, (reason) => {
        // không
      });
    });
  }
  xoaCongBo(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.ApiService.DeleteCongBo(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmDungChung();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }
  CongBoChiSoGia(){
    let item: any ={
      ID: 0,
      TenChiSoGia: this.dataitem.TenChiSoGia,
      NamGoc: this.dataitem.NamGoc,
      NamCongBo: this.dataitem.Nam,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.result.then((data) => {
    this.GetListdmDungChung()
    }, (reason) => {
      // không
    });
  }
  
  taixuong(tenfile) {
    this.MyService.taixuong(tenfile);
  }
}
