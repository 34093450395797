import { Component, OnInit } from '@angular/core';
import { NgbActiveModal , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';

@Component({
  selector: 'app-luachoncongtrinh',
  templateUrl: './luachoncongtrinh.component.html',
  styleUrls: ['./luachoncongtrinh.component.css']
})
export class LuachoncongtrinhComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  TimChaTree: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  Listcha: any = [];
  Nam: any = 0;
  dataitem: any = {};
  ListpostNew: any = [];
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    this.TimChaTree = this.Listtree;
    for (let i = 0; i < this.Listtree.length; i++) {
      for (let j = 0; j < this.checkListtree.length; j++) {
        if (this.Listtree[i].ID == this.checkListtree[j].IDdmLoaiCongTrinh) {
          this.Listtree[i].check = true;
          this.Listtree[i].IDdatontai = this.checkListtree[j].ID;
          break;
        }
        else {
          this.Listtree[i].IDdatontai = 0;
        }
      }
    }
    this.Listtree[0].select = true;
    this.Listtree = this.MyService.unflatten(this.Listtree);
    this.itemSELeCT = this.Listtree[0];
  }

  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }
  
  CheckTREE(item) {
    item.check = !item.check;
    if(item.children.length > 0){
      this.DeQuyCheckTreeChaCon(item.children, item.check);
    }
    if(item.check){
        this.DeQuyCheckTreeConCha(item, item.check);
    }
    if (item.check == false ) {
      for (let i = 0; i < this.Listpost.length; i++) {
        if (item.ID == this.Listpost[i].IDdmLoaiCongTrinh) {
          this.Listpost.splice(i, 1);
          break;
        }
      }
      this.DeQuyCheckTreeFalse(item);
      // if (item.IDdatontai > 0) {
        // let data: any = {
        //   "ID": item.IDdatontai != undefined ? item.IDdatontai : 0,
        //   "IDdmKhuVuc": this.IDdmKhuVuc,
        //   "IDdmTinh": this.IDdmTinh,
        //   "IDdmLoaiCongTrinh": item.ID,
        //   "isXoa": true,
        //   "Nam": this.Nam,
        //   "IDThongTinUser": this.dataitem.IDThongTinUser,
        // }
        // this.Listpost.push(data);
      // }
      // if (item.children.length > 0) {
      //   item.children.forEach(element => {
      //     if (element.IDdatontai > 0) {
      //     let data: any = {
      //       "ID": element.IDdatontai != undefined ? element.IDdatontai : 0,
      //       "IDdmKhuVuc": this.IDdmKhuVuc,
      //       "IDdmTinh": this.IDdmTinh,
      //       "IDdmLoaiCongTrinh": element.ID,
      //       "isXoa": true,
      //       "Nam": this.Nam,
      //       "IDThongTinUser": this.dataitem.IDThongTinUser,
      //     }
      //     this.Listpost.push(data);
      //   }
      //   });
      // }
    }
    else{
      for (let i = 0; i < this.Listpost.length; i++) {
        if (item.ID == this.Listpost[i].IDdmLoaiCongTrinh) {
          this.Listpost.splice(i, 1);
          break;
        }
      }
      this.DeQuyCheckTree(item);
    }
    
  }
  DeQuyCheckTreeConChaFalse(listitem,item, giatri){
    listitem.forEach(element => {
      if(element.ID == item.IDParent){
        var checknew = false;
        element.children.forEach(i => {
            if(i.check == true)
            checknew = true;
        });
        if(checknew == false){
          element.check = false
          return;
        }
      }
      this.DeQuyCheckTreeConChaFalse(element.children, item, giatri)

    });
  }
  DeQuyCheckTree(item){
    for (let i = 0; i < this.Listpost.length; i++) {
      if (item.ID == this.Listpost[i].IDdmLoaiCongTrinh) {
        this.Listpost.splice(i, 1);
        break;
      }
    }
      let data: any = {
        "ID": item.IDdatontai != undefined ? item.IDdatontai : 0,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "IDdmTinh": this.IDdmTinh,
        "IDdmLoaiCongTrinh": item.ID,
        "isXoa": false,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
      }
      this.Listpost.push(data);
      item.children.forEach(i => {
        if(i.check == true){
          this.DeQuyCheckTree(i);
      }
    });
  }
  DeQuyCheckTreeFalse(item){
    for (let i = 0; i < this.Listpost.length; i++) {
      if (item.ID == this.Listpost[i].IDdmLoaiCongTrinh) {
        this.Listpost.splice(i, 1);
        // break;
      }
    }
      let data: any = {
        "ID": item.IDdatontai != undefined ? item.IDdatontai : 0,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "IDdmTinh": this.IDdmTinh,
        "IDdmLoaiCongTrinh": item.ID,
        "isXoa": true,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
      }
      this.Listpost.push(data);
      item.children.forEach(i => {
        if(i.check == false){
          this.DeQuyCheckTreeFalse(i);
      }
    });
  }
  DeQuyCheckTreeConCha(item, giatri){
    this.TimChaTree.forEach(element => {
      if(element.ID == item.IDParent){
        element.check = giatri;
        let data: any = {
          "ID": element.IDdatontai,
          "IDdmKhuVuc": this.IDdmKhuVuc,
          "IDdmTinh": this.IDdmTinh,
          "IDdmLoaiCongTrinh": element.ID,
          "isXoa": false,
          "Nam": this.Nam,
          "IDThongTinUser": this.dataitem.IDThongTinUser,
        }
        this.Listpost.push(data);
        this.DeQuyCheckTreeConCha( element, giatri)
      }
    });
  }
  DeQuyCheckTreeChaCon(Listchildren, giatri){
    Listchildren.forEach(element => {
        element.check = giatri;
      this.DeQuyCheckTreeChaCon(element.children, giatri)

    });
  }
  CHECKchildrenTREE(Listchildren, value) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].check = value;
      if (value) {
        let data: any = {
          "ID": Listchildren[i].IDdatontai,
          "IDdmTinh": this.IDdmTinh,
          "IDdmLoaiCongTrinh": Listchildren[i].IDdmLoaiCongTrinh,
          "isXoa": false,
          "Nam": this.Nam,
          "IDThongTinUser": this.dataitem.IDThongTinUser,
        }
        this.Listpost.push(data);
        // this.timcha();
      }
      else {
        // for (let j = 0; j < this.Listpost.length; j++) {
        //   if (Listchildren[i].ID == this.Listpost[j].ID) {
        //     this.Listpost.splice(j, 1);
        //   }
        // }
        if (Listchildren[i].IDdatontai > 0) {
          let data: any = {
            "ID": Listchildren[i].IDdatontai,
            "IDdmTinh": this.IDdmTinh,
            "IDdmLoaiCongTrinh": Listchildren[i].IDdmLoaiCongTrinh,
            "isXoa": true,
            "Nam": this.Nam,
            "IDThongTinUser": this.dataitem.IDThongTinUser,
          }
          this.Listpost.push(data);
        }
        else {
          for (let j = 0; j < this.Listpost.length; j++) {
            if (Listchildren[i].IDdmLoaiCongTrinh == this.Listpost[j].IDdmLoaiCongTrinh) {
              this.Listpost.splice(j, 1);
              break;
            }
          }
        }
      }
      if (Listchildren[i].children.length > 0) {
        this.CHECKchildrenTREE(Listchildren[i].children, value);
      }
    }
  }

  checkcha(item) {
    for (let i = 0; i < this.Listtree.length; i++) {
      if (item.IDParent == this.Listtree[i].ID) {
        if (!this.Listtree[i].check) {
          this.Listtree[i].check = true;
          let data: any = {
            "ID": this.Listtree[i].IDdatontai,
            "IDdmTinh": this.IDdmTinh,
            "IDdmLoaiCongTrinh": this.Listtree[i].IDdmLoaiCongTrinh,
            "isXoa": false,
            "Nam": this.Nam,
            "IDThongTinUser": this.dataitem.IDThongTinUser,
          }
          this.Listpost.push(data);
          break;
        }
      }
    }
  }

  // choncon(item) {
  //   item.check = !item.check;
  //   if (item.check) {
  //     let data: any = {
  //       "ID": item.IDdatontai,
  //       "IDdmTinh": this.IDdmTinh,
  //       "IDdmLoaiCongTrinh": item.IDdmLoaiCongTrinh,
  //       "isXoa": false,
  //       "Nam": moment().year()
  //     }
  //     this.Listpost.push(data);
  //     this.checkcha(item);
  //   }
  //   else {
  //     if (item.IDdatontai > 0) {
  //       debugger;
  //       let data: any = {
  //         "ID": item.IDdatontai,
  //         "IDdmTinh": this.IDdmTinh,
  //         "IDdmLoaiCongTrinh": item.IDdmLoaiCongTrinh,
  //         "isXoa": true,
  //         "Nam": moment().year()
  //       }
  //       this.Listpost.push(data);
  //       this.checkcha(item);
  //     }
  //     else {
  //       for (let j = 0; j < this.Listpost.length; j++) {
  //         if (item.IDdmLoaiCongTrinh == this.Listpost[j].IDdmLoaiCongTrinh) {
  //           this.Listpost.splice(j, 1);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  XacNhan() {
    let Listpost_new: any = [];
    for (let i = this.Listpost.length - 1; i > -1 ; i--) {
      let kiemtra : any = 0;
      for (let j = 0; j < Listpost_new.length; j++) {
        if(this.Listpost[i].IDdmLoaiCongTrinh == Listpost_new[j].IDdmLoaiCongTrinh){
          kiemtra = 1;
          break;
        }
      }
      if(kiemtra == 0)
        Listpost_new.push(this.Listpost[i]);
    }
    this.Listpost = Listpost_new;
    this.ApiService.SetdsLoaiCongTrinh(this.Listpost).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  ThemMoi() {
    let datanew : any = {};
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;  
    modalRef.result.then((data) => {
      datanew = data.data;
      datanew.collspan = false;
      datanew.check = false;
      datanew.select = false;
      datanew.IDdmLoaiCongTrinh = datanew.ID;
      datanew.children = [];
      if(datanew.IDParent == 0){
        this.Listtree.push(datanew);
      }
      else{
        this.Listtree.forEach(element => {
          if(element.ID == datanew.IDParent)
            element.children.push(datanew)
          else
            this.DeQuyTimCon(element.children, datanew);
        });
      }
      this.CheckTREE(datanew);
    }, (reason) => {
      // không
    });
  }
  DeQuyTimCon(listitem, item){
    listitem.forEach(element => {
      if(element.ID == item.IDParent){
        element.children.push(item);
        return;
      }
      else{
        this.DeQuyTimCon(element.children, item);
      }
    });
  }
}
