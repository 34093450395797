import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChiPhiVatLieuNhanCongMayComponent } from 'src/app/thongtinchung/tytrong-vlncm/NgbModal/chiphivatlieunhancongmay/chiphivatlieunhancongmay.component';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { MyService } from './../../../../services/my.service';

@Component({
  selector: 'app-themmoiduancongtrinhdienhinh',
  templateUrl: './themmoiduancongtrinhdienhinh.component.html',
  styleUrls: ['./themmoiduancongtrinhdienhinh.component.css']
})
export class ThemMoiDuAnDanhMucDungChungComponent implements OnInit {
  public IDParent: any;
  public Nam: any;
  public itemADD: any = {};
  public ThongTinChung: any = {};
  public ChiPhiChung: any = {};
  public ChiPhiVatLieu: any = [];
  public ChiPhiNhanCong: any = [];
  public ChiPhiMay: any = [];

  header: any = '';
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  objloaicongtrinh: any = {};
  IDdsLoaiCongTrinh: any = 0;
  IDdmTinh: any = 0;
  IDdmKhuVuc: any = 0;
  dataitem: any = {};
  listDanhMucDungChung: any = [];
  ListtreeVL: any = [];
  ListtreeNC: any = [];
  ListtreeMTC: any = [];
  ListtreeMS: any = [];
  IddmDungChung: any = 0;
  isEdit: any = false;
  isHienThi: any = false;
  listdmgiaidoanduan: any = [];
  listNam: any = [];
  listThang: any = [];
  listLoaiTinhCSG: any = [{
    Ma: 11,
    Ten: 'Chỉ số giá xây dựng',
  },
  {
    Ma: 12,
    Ten: 'Chỉ số giá thiết bị',
  },
  {
    Ma: 200,
    Ten: 'Cả hai',
  },];
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }
  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID; 
    this.dataitem.IDUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser'))).UserName;
    if(this.isEdit == true){
      this.ItemPOST = Object.assign({}, this.item);
      this.ItemPOST.ID = this.IddmDungChung;
    }
    else{
      this.header = "Thêm mới";
      this.ItemPOST = {
        "ThongTinChung": {
        },
        "ChiPhiChung": {
        },
        "ThongTinKinhTe": {
        },
        "listChiPhiVatLieu": [],
        "listChiPhiNhanCong": [],
        "listChiPhiMay": [],
        "listChiPhiMuaSam": [],
        "listChiPhiKhacTrenCuc":[],
        "listChiPhiTuVanDauTuTrenCuc":[],
        "listChiPhiQuanLyDuAnTrenCuc":[],
      };
      this.ItemPOST.cap = 1;
      this.ItemPOST.Loai = 1;
      this.ItemPOST.collspan = true;
      this.ItemPOST.check = false;
      this.ItemPOST.select = false;
    }
      this.GetdsVatLieu_New();
      this.GetListdmCapCongTrinh();
      this.GetListdmNhomCongTrinh();
      this.GetListdmGiaiDoanDuAn();
      this.GetListNam();
      this.GetListThang();
  }
  GetListdmGiaiDoanDuAn() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmGiaiDoanDuAn(data).subscribe(res => {
      this.listdmgiaidoanduan = res
    });
  }
  SetData() {
    let data: any =
    {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      "IDParent": this.IDParent,
      "ThongTinChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDdmLoaiCongTrinh": this.objloaicongtrinh.IDdmLoaiCongTrinh,
        "IDdmCapCongTrinh": this.ItemPOST.ThongTinChung.IDdmCapCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmCapCongTrinh : 0,
        "IDdmNhomCongTrinh": this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh : 0,
        "DiaDiem": this.ItemPOST.ThongTinChung.DiaDiem != undefined && this.ItemPOST.ThongTinChung.DiaDiem != null ? this.ItemPOST.ThongTinChung.DiaDiem : "",
        "CongSuat": this.ItemPOST.ThongTinChung.CongSuat != undefined && this.ItemPOST.ThongTinChung.CongSuat != null ? this.ItemPOST.ThongTinChung.CongSuat : "",
        "NangLuc": this.ItemPOST.ThongTinChung.NangLuc != undefined && this.ItemPOST.ThongTinChung.NangLuc != null ? this.ItemPOST.ThongTinChung.NangLuc : "",
        "QuyChuan": this.ItemPOST.ThongTinChung.QuyChuan != undefined && this.ItemPOST.ThongTinChung.QuyChuan != null ? this.ItemPOST.ThongTinChung.QuyChuan : "",
        "TieuChuan": this.ItemPOST.ThongTinChung.TieuChuan != undefined && this.ItemPOST.ThongTinChung.TieuChuan != null ? this.ItemPOST.ThongTinChung.TieuChuan : "",
        "ThoiGianSuDung": this.ItemPOST.ThongTinChung.ThoiGianSuDung != undefined && this.ItemPOST.ThongTinChung.ThoiGianSuDung != null ? this.ItemPOST.ThongTinChung.ThoiGianSuDung : "",
        "CoChe": this.ItemPOST.ThongTinChung.CoChe != undefined && this.ItemPOST.ThongTinChung.CoChe != null ? this.ItemPOST.ThongTinChung.CoChe : "",
        "DienTich": this.ItemPOST.ThongTinChung.DienTich != undefined && this.ItemPOST.ThongTinChung.DienTich != null ? this.ItemPOST.ThongTinChung.DienTich : 0,
        "ThongTinKhac": this.ItemPOST.ThongTinChung.ThongTinKhac != undefined && this.ItemPOST.ThongTinChung.ThongTinKhac != null ? this.ItemPOST.ThongTinChung.ThongTinKhac : "",
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "IDdmGiaiDoanDuAn":this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn,
        "NamXayDung":this.ItemPOST.ThongTinChung.NamXayDung,
        "NamSuDung":this.ItemPOST.ThongTinChung.NamSuDung,
        "ThangSuDung":this.ItemPOST.ThongTinChung.ThangSuDung,
        "ChuDauTu":this.ItemPOST.ThongTinChung.ChuDauTu,
        "ThoiGianLapDuLieuUnix": this.MyService.ghepdateUnix(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu),
      },
      "ChiPhiChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "ChiPhiXayDung": this.ItemPOST.ChiPhiChung.ChiPhiXayDung != undefined && this.ItemPOST.ChiPhiChung.ChiPhiXayDung != null ? this.ItemPOST.ChiPhiChung.ChiPhiXayDung : 0,
        "ChiPhiVatLieu": this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != null ? this.ItemPOST.ChiPhiChung.ChiPhiVatLieu : 0,
        "ChiPhiMay": this.ItemPOST.ChiPhiChung.ChiPhiMay != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMay != null ? this.ItemPOST.ChiPhiChung.ChiPhiMay : 0,
        "ChiPhiNhanCong": this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != null ? this.ItemPOST.ChiPhiChung.ChiPhiNhanCong : 0,
        "ChiPhiThietBi": this.ItemPOST.ChiPhiChung.ChiPhiThietBi != undefined && this.ItemPOST.ChiPhiChung.ChiPhiThietBi != null ? this.ItemPOST.ChiPhiChung.ChiPhiThietBi : 0,
        "ChiPhiMuaSam": this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != null ? this.ItemPOST.ChiPhiChung.ChiPhiMuaSam : 0,
        "ChiPhiLapDat": this.ItemPOST.ChiPhiChung.ChiPhiLapDat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiLapDat != null ? this.ItemPOST.ChiPhiChung.ChiPhiLapDat : 0,
        "ChiPhiKhac": this.ItemPOST.ChiPhiChung.ChiPhiKhac != undefined && this.ItemPOST.ChiPhiChung.ChiPhiKhac != null ? this.ItemPOST.ChiPhiChung.ChiPhiKhac : 0,
        "ChiPhiQuanLiDuAn": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn : 0,
        "ChiPhiQuanLiKhaoSat": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat : 0,
        "ChiPhiQuanLiThietKe": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe : 0,
        "ChiPhiGiamSatXD": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD : 0,
        "Khac": this.ItemPOST.ChiPhiChung.Khac != undefined && this.ItemPOST.ChiPhiChung.Khac != null ? this.ItemPOST.ChiPhiChung.Khac : 0,
        "ChiPhiGiamSatTB": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB : 0,
        "VonDauTu": this.ItemPOST.ChiPhiChung.VonDauTu != undefined && this.ItemPOST.ChiPhiChung.VonDauTu != null ? this.ItemPOST.ChiPhiChung.VonDauTu : 0,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
      },
      "Loai": 1,
      "IDdmTinh": this.IDdmTinh,
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "listChiPhiVatLieu": this.ItemPOST.listChiPhiVatLieu,
      "listChiPhiNhanCong": this.ItemPOST.listChiPhiNhanCong,
      "listChiPhiMay": this.ItemPOST.listChiPhiMay,
      "listChiPhiMuaSam": this.ItemPOST.listChiPhiMuaSam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "listChiPhiKhacTrenCuc": this.ItemPOST.listChiPhiKhacTrenCuc,
      "listChiPhiTuVanDauTuTrenCuc": this.ItemPOST.listChiPhiTuVanDauTuTrenCuc,
      "listChiPhiQuanLyDuAnTrenCuc": this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc,
    };
    data.ChiPhiChung.ChiPhiVatLieu = 0;
    data.ChiPhiChung.ChiPhiNhanCong = 0;
    data.ChiPhiChung.ChiPhiMay = 0;
    data.ChiPhiChung.ChiPhiMuaSamThietBi = 0;

    if (this.ItemPOST.listChiPhiVatLieu.length > 0) {
      this.ItemPOST.listChiPhiVatLieu.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiVatLieu += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiNhanCong.length > 0) {
      this.ItemPOST.listChiPhiNhanCong.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiNhanCong += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiMuaSam.length > 0) {
      this.ItemPOST.listChiPhiMuaSam.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiMay += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiMay.length > 0) {
      this.ItemPOST.listChiPhiMay.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiMuaSamThietBi += parseFloat(obj.GiaTri);
        }
      });
    }
    return data;
  }
  XacNhan() {
    if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else{
      this.DanhmucService.SetdmLoaiCongTrinh(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }
  GetdsVatLieu_New() {
    let dataNew: any = []
    let dataNew1: any = []
    let dataNew2: any = []
    let dataNew3: any = []
    let data: any = {
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "Nam": this.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    };
    this.DanhmucService.GetListdmVatLieuNhanCongMayDungChung(data).subscribe(resListdmVatLieuNhanCongMay => {
      resListdmVatLieuNhanCongMay.filter(obj => {
        if (obj.LoaiDanhMuc == 0) {
          let datainsert: any = {
            "ID":0,
            "IDdsVatLieuNhanCongMay":obj.ID,
            "Nam":obj.Nam,
            "IDThongTinUser":obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "TendmVatLieuNhanCongMay": obj.Ten,
            "DonViTinh": obj.DonViTinh,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc,
          }
          dataNew.push(datainsert);
        }
        else if (obj.LoaiDanhMuc == 1) {
          let datainsert: any = {
            "ID":0,
            "IDdsVatLieuNhanCongMay":obj.ID,
            "Nam":obj.Nam,
            "IDThongTinUser":obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "TendmVatLieuNhanCongMay": obj.Ten,
            "DonViTinh": obj.DonViTinh,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc
          }
          dataNew1.push(datainsert);
        }
        
        else if (obj.LoaiDanhMuc == 2) {
          let datainsert: any = {
            "ID":0,
            "IDdsVatLieuNhanCongMay":obj.ID,
            "Nam":obj.Nam,
            "IDThongTinUser":obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "DonViTinh": obj.DonViTinh,
            "TendmVatLieuNhanCongMay": obj.Ten,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc
          }
          dataNew2.push(datainsert);
          }
          else if (obj.LoaiDanhMuc == 3) {
            let datainsert: any = {
              "ID":0,
              "IDdsVatLieuNhanCongMay":obj.ID,
              "Nam":obj.Nam,
              "IDThongTinUser":obj.IDThongTinUser,
              "IDdmKhuVuc": this.IDdmKhuVuc,
              "DonViTinh": obj.DonViTinh,
              "TendmVatLieuNhanCongMay": obj.Ten,
              "isXoa": false,
              "LoaiDanhMuc": obj.LoaiDanhMuc
            }
            dataNew3.push(datainsert);
          }
      });
      this.ListtreeVL = dataNew;
      this.ListtreeNC = dataNew1;
      this.ListtreeMTC = dataNew2;
      this.ListtreeMS = dataNew3;
    });
  }
  GetdsVatLieu() {
      const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.type = "VatLieu";
      modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiVatLieu);
      // modalRef.componentInstance.checkListtree = [];
      modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeVL);
      modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
      modalRef.componentInstance.IDdmTinh = this.IDdmTinh;
      modalRef.componentInstance.Nam = this.Nam;
      modalRef.componentInstance.isDungChung = true;
      modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
      modalRef.componentInstance.listvatlieu = Object.assign([], this.ItemPOST.listChiPhiVatLieu);
      modalRef.result.then((data) => {
        this.ItemPOST.listChiPhiVatLieu = data.vatlieu;
        this.ItemPOST.listChiPhiVatLieu.filter(obj => {
          if (obj.GiaTri == undefined && obj.ID == 0) {
            obj.GiaTri = 0;
          }
        });
      }, (reason) => {
        // không
    });
  }
  GetdsNhanCong() {
      const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.type = "NhanCong";
      modalRef.componentInstance.Selected = this.ChiPhiNhanCong;
      modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiNhanCong);
      modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeNC);
      modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
      modalRef.componentInstance.Nam = this.Nam;
      modalRef.componentInstance.isDungChung = true;
      modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
      modalRef.componentInstance.listnhancong = Object.assign([], this.ItemPOST.listChiPhiNhanCong);
      modalRef.result.then((data) => {
        this.ItemPOST.listChiPhiNhanCong = data.nhancong;
        this.ItemPOST.listChiPhiNhanCong.filter(obj => {
          if (obj.GiaTri == undefined && obj.ID == 0) {
            obj.GiaTri = 0;
          }
        });
      }, (reason) => {
        // không
      });
  }
  GetdsMayThiCong() {
      const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.type = "ThiCong";
      modalRef.componentInstance.checkListtree =  Object.assign([], this.ItemPOST.listChiPhiMay);
      modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeMTC);
      modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
      modalRef.componentInstance.Nam = this.Nam;
      modalRef.componentInstance.isDungChung = true;
      modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
      modalRef.componentInstance.listmaythicong = Object.assign([], this.ItemPOST.listChiPhiMay);
      modalRef.result.then((data) => {
        this.ItemPOST.listChiPhiMay = data.maythicong;
        this.ItemPOST.listChiPhiMay.filter(obj => {
          if (obj.GiaTri == undefined && obj.ID == 0) {
            obj.GiaTri = 0;
          }
        });
      }, (reason) => {
        // không
    });
  }
  GetdsChiPhiMuaSam() {
      const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.type = "MuaSam";
      modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiMuaSam);
      modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeMS);
      modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
      modalRef.componentInstance.Nam = this.Nam;
      modalRef.componentInstance.isDungChung = true;
      modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
      modalRef.componentInstance.listchiphimuasam = Object.assign([], this.ItemPOST.listChiPhiMuaSam);
      modalRef.result.then((data) => {
        this.ItemPOST.listChiPhiMuaSam = data.chiphimuasam;
        this.ItemPOST.listChiPhiMuaSam.filter(obj => {
          if (obj.GiaTri == undefined && obj.ID == 0) {
            obj.GiaTri = 0;
          }
        });
      }, (reason) => {
        // không
    });
  }
  Onclose() {
    this.activeModal.close();
  }
  Ondismiss() {
    this.activeModal.dismiss();
  }
  saveDanhMucdungChungCongTrinhDienHinh(){
    if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else{
      let data:any={
        "Ten":this.ItemPOST.ThongTinChung.Ten,
        "LoaiDanhMuc": 'CongTrinhDienHinh',
        "IDUser": this.dataitem.IDUser,
        "dmCongTrinhDienHinh": this.SetData()
      }
      this.DanhmucService.SetdmDungChungCongTrinhDienHinh(data).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          // this.activeModal.close();
          this.activeModal.dismiss();

          // this.Onclose();
        }
        else {
          this.toastr.error(res.message);      
        }
      }); 
    }
  }
  GetListdmCapCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmCapCongTrinh(data).subscribe(res => {
      this.listcapcongtrinh = res;
    });
  }
  GetListdmNhomCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmNhomCongTrinh(data).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }
  // SetdmDungChungCongTrinhDienHinh(){
  //   if(this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == ""){
  //     this.toastr.error("Bạn chưa nhập tên công trình");
  //   }
  //   else{
  //     this.ItemPOST.ID = this.IddmDungChung;
  //     this.ItemPOST.Ten = this.ItemPOST.ThongTinChung.Ten;
  //     this.DanhmucService.SetdmDungChungCongTrinhDienHinh(this.ItemPOST).subscribe(res => {
  //       if (res.State === 1) {
  //         this.toastr.success(res.message);
  //         this.Onclose();
  //       }
  //       else {
  //         this.toastr.error(res.message);
  //       }
  //     });
  //   }
  // }
  GetListNam() {
    var listNam = [];
    this.listNam = [];
    for(let i = 1970; i <= 2050; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listNam.push(data)
    }
    this.listNam = listNam;
  }
  GetListThang() {
    var listThang = [];
    this.listThang = [];
    for(let i = 1; i <= 12; i++){
      let data: any={
        "ma": i,
        "ten":i
      }
      listThang.push(data)
    }
    this.listThang = listThang;
  }
  TinhGiaTri(item) {
    item.GiaTriSauThue = parseInt(item.ThueGiaTriGiaTang || 0) + parseInt(item.GiaTri || 0);
  }
  XoaListDauTu(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac); 
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  XoaListKhac(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.Khac = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiKhacTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.Khac += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac);  
    }
    else{
      this.ItemPOST.ChiPhiChung.Khac -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  XoaListQuanLiDuAn(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac);  
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  ThemMoiQLDA(){
    if(this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc === undefined)
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.unshift(data);
  }
  ThemMoiTuVanDauTu(){
    if(this.ItemPOST.listChiPhiTuVanDauTuTrenCuc === undefined)
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.unshift(data);
  }
  ThemMoiKhac(){
    if(this.ItemPOST.listChiPhiKhacTrenCuc === undefined)
      this.ItemPOST.listChiPhiKhacTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiKhacTrenCuc.unshift(data);
  }
  TinhChiPhiThietBi() {
    this.ItemPOST.ChiPhiChung.ChiPhiThietBi = 0;
    let ChiPhiMuaSam: any = this.ItemPOST.ChiPhiChung.ChiPhiMuaSam == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiMuaSam);
    let ChiPhiLapDat: any = this.ItemPOST.ChiPhiChung.ChiPhiLapDat == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiLapDat);
    this.ItemPOST.ChiPhiChung.ChiPhiThietBi = ChiPhiMuaSam + ChiPhiLapDat;
    // this.TinhVonDauTu();
  }
}