import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { Router } from '@angular/router';
import { MyService } from './../../services/my.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DoimatkhauComponent } from 'src/app/danhmuc/danhmucuser/NgbModal/doimatkhau/doimatkhau.component';
import { LuaChonPhuongThucCSGComponent } from 'src/app/login/login/NgbModal/luachoncsg/luachoncsg.component';
import { ThemMoiDuAnDanhMucDungChungComponent } from 'src/app/login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { CongboComponent } from 'src/app/chisogia/khuvuc/NgbModal/congbo/congbo.component';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public checkLocalStorage: boolean = false;
  currentUser: any = {};

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthenticationService,
    private MyService: MyService,
    private DanhmucService: DanhmucService
  ) { 
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    console.log(this.currentUser)
  }

  ngOnInit(): void {
    if (localStorage.getItem('currentUser') !== null) {
      this.MyService.checklogin = true;
    }
    else {
      this.MyService.checklogin = false;
      this.router.navigate(['/Login']);
    }
  }

  OutputLogin(e) {
    this.checkLocalStorage = e;
    this.currentUser = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
  }

  logout() {
    this.MyService.checklogin = false;
    this.authService.logout();
  }
  ThongTin() {
    const modalRef = this.modalService.open(DoimatkhauComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.item = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    modalRef.result.then((data) => {
      this.logout();
    }, (reason) => {
      // không
    });
  }
  
  themmoi() {
    const modalRef = this.modalService.open(LuaChonPhuongThucCSGComponent, {size:'sm',backdrop: 'static', centered: true});
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
  CongTrinhDienHinh(){
    this.closemenu();

    const modalRef = this.modalService.open(ThemMoiDuAnDanhMucDungChungComponent, {size:'fullscreen',backdrop: 'static', scrollable: true});
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
  downloadHDSD() {
    this.DanhmucService.GetHDSD()
      .subscribe((res: any) => {
        this.MyService.taixuong(res.DuongDan);
      });
  }
  CongBoChiSoGia(){
    this.closemenu();
    let item: any ={
      ID: 0,
      fileUploadFileDinhKem: [],
      fileUpload: [],
      isThayThe: false,
      IDThongTinUser: 0
    }
    const modalRef = this.modalService.open(CongboComponent, { backdrop: 'static', size:'xl' });
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.result.then((data) => {
      // this.GetListdsCongBoChiSoGia();
    }, (reason) => {
      // không
    });
  }
  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  closemenu() {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
}
