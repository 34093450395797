import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { LuachoncongtrinhComponent } from '../tytrongcongtrinh/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { NhaptytrongKhuVucComponent } from './nhaptytrong/nhaptytrong.component';

@Component({
  selector: 'app-tytrongtheokhuvuc',
  templateUrl: './tytrongtheokhuvuc.component.html',
  styleUrls: ['./tytrongtheokhuvuc.component.css']
})
export class TytrongtheokhuvucComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};

  files: TreeNode[];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;
  uploader: FileUploader;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    this.GetListdmTinh();
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length === 0){
        this.GetListTyTrongDiaPhuong();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;
        this.GetListTyTrongDiaPhuong();
      }
    });
  }
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportTytrongDiaPhuong(res[0], this.dataitem.IDThongTinUser).subscribe(res => {
      this.GetListTyTrongDiaPhuong();
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  GetListTyTrongDiaPhuong() {
    this.SoCotCon = 0;
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetHeaderTyTrongDiaPhuong(data).subscribe(res => {
      this.colschidren = res;
      this.ApiService.GetListTyTrongDiaPhuong(data).subscribe(res => {
        this.files = res;
      });
    });
  }

  nhaptytrong(item, tenRow) {
    if(item.TyTrong != null)
      item.TyTrong = item.TyTrong.toString().replace('.',',');
    const modalRef = this.modalService.open(NhaptytrongKhuVucComponent, { backdrop: 'static', scrollable: true });
    modalRef.componentInstance.item = Object.assign({}, item);
    modalRef.componentInstance.tenRow = tenRow;
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.componentInstance.title = "Cập nhật";
    modalRef.result.then((data) => {
      this.GetListTyTrongDiaPhuong();
    }, (reason) => {
      // không
    });
  }

  chonloai() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: moment().year()
    };
    this.ApiService.GetdsLoaiCongTrinh(data).subscribe(res => {
      let data: any = {
        IDdmTinh: this.dataitem.IDdmTinh, 
        IDdmKhuVuc: 0,   
        IDThongTinUser: this.dataitem.IDThongTinUser,        
      };
      this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(restytrongcongtrinh => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res.items);
        modalRef.componentInstance.Listtree = Object.assign([], restytrongcongtrinh);
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.result.then((data) => {
          this.GetListTyTrongDiaPhuong();
        }, (reason) => {
          // không
        });
      });
    });
  }

  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ExportTyTrongDiaPhuong(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
