import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-updatekhuvuc',
  templateUrl: './updatekhuvuc.component.html',
  styleUrls: ['./updatekhuvuc.component.css']
})
export class UpdatekhuvucLuaChonComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  dataitem : any = {};
  ItemSet : any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({},this.item);
    }
  }

  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "Ma": this.ItemPOST.Ma !== undefined && this.ItemPOST.Ma !== "" ? this.ItemPOST.Ma : "",
      "Ten": this.ItemPOST.Ten !== undefined && this.ItemPOST.Ten !== "" ? this.ItemPOST.Ten : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "IDdmTinh": this.IDParent,
      "IDThongTinUser": this.type == 'themmoi' ? this.dataitem.IDThongTinUser : this.dataitem.IDThongTinUser,
    }
    return data;
  }

  checkdata() {  
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  XacNhan() {       
    if (this.checkdata()) {
      this.activeModal.close(
        {data:this.SetData()}
      );
    }    
    else{
      this.toastr.error("Yêu cầu nhập đầy đủ trường bắt buộc");
    }   
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
