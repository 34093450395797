import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService, httpOptions } from './../../services/host.service';
import { CapnhatgiavatlieunhancongmayComponent } from '../giavatlieunhancongmay/NgbModal/capnhatgiavatlieunhancongmay/capnhatgiavatlieunhancongmay.component';
import { LuachonVLNCMComponent } from '../giavatlieunhancongmay/NgbModal/luachon-vlncm/luachon-vlncm.component';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-giathietbi',
  templateUrl: './giathietbi.component.html',
  styleUrls: ['./giathietbi.component.css']
})
export class GiathietbiComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listkhuvuc: any = [];
  listTinh: any = [];
  listnam: any = [];
  listkhoadulieu: any = [];
  listdonViTien: any = [];
  dataitem: any = {};

  uploader: FileUploader;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
      this.dataitem.Ten = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    }
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
    // this.GetListdmKhuVuc(this.dataitem.IDdmTinh);
    this.GetListdmTinh();
    this.listnam.push(this.dataitem.Nam);
    this.listnam.push(this.dataitem.NamGoc);
    this.GetListDonViTien();
  }
  GetListDonViTien() {
    this.listdonViTien = [];
    let data2: any={
      "ma": 0,
      "ten":"Đồng"
    }
    let data: any={
      "ma": 1,
      "ten":"Nghìn đồng"
    }
    let data1: any={
      "ma": 2,
      "ten":"Triệu đồng"
    }
    this.listdonViTien.push(data2)
    this.listdonViTien.push(data)
    this.listdonViTien.push(data1)
    this.dataitem.DonViTien = this.listdonViTien[0].ma
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    this.spinner.show();
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportGiaThietBi(res[0].Name, this.dataitem.IDdmKhuVuc || 0, this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.GetGiaThietBi()
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  ListKhoaDuLieu() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ListKhoaDuLieu(data).subscribe(res => {
      this.listkhoadulieu = res;
    });
  }
  GetListdmTinh() {
    this.listTinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listTinh = res;
      if(res.length === 0){
        this.GetGiaThietBi();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;      
        this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
      }
    });
  }
  GetListdmKhuVuc(IDdmTinh,IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetGiaThietBi();
    });
  }

  GetGiaThietBi() {
    this.ListKhoaDuLieu();
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetGiaThietBi(data).subscribe(res => {
      if(this.dataitem.DonViTien == 1)
      res.forEach(element => {
        this.dequyGia(element, 1000)
      });
      else if(this.dataitem.DonViTien == 2)
      res.forEach(element => {
        this.dequyGia(element, 1000000)
      });
      this.TreeVLNCM = res
    });

  }

  capnhatgia(e) {
    if(this.dataitem.DonViTien == 1){
      e.Thang1 = e.Thang1 * 1000;
      e.Thang2 = e.Thang2 * 1000;
      e.Thang3 = e.Thang3 * 1000;
      e.Thang4 = e.Thang4 * 1000;
      e.Thang5 = e.Thang5 * 1000;
      e.Thang6 = e.Thang6 * 1000;
      e.Thang7 = e.Thang7 * 1000;
      e.Thang8 = e.Thang8 * 1000;
      e.Thang9 = e.Thang9 * 1000;
      e.Thang10 = e.Thang10 * 1000;
      e.Thang11 = e.Thang11 * 1000;
      e.Thang12 = e.Thang12 * 1000;
    }
    else if(this.dataitem.DonViTien == 2){
      e.Thang1 = e.Thang1 * 1000000;
      e.Thang2 = e.Thang2 * 1000000;
      e.Thang3 = e.Thang3 * 1000000;
      e.Thang4 = e.Thang4 * 1000000;
      e.Thang5 = e.Thang5 * 1000000;
      e.Thang6 = e.Thang6 * 1000000;
      e.Thang7 = e.Thang7 * 1000000;
      e.Thang8 = e.Thang8 * 1000000;
      e.Thang9 = e.Thang9 * 1000000;
      e.Thang10 = e.Thang10 * 1000000;
      e.Thang11 = e.Thang11 * 1000000;
      e.Thang12 = e.Thang12 * 1000000;
    }
    const modalRef = this.modalService.open(CapnhatgiavatlieunhancongmayComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.IDParent = e;
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
    modalRef.componentInstance.LoaiDanhMucUpdate = "MuaSam";
    modalRef.result.then((data) => {
      this.GetGiaThietBi();
    }, (reason) => {
      // không
    });
  }

  GetdsVatLieuNhanCongMay() {    
    let data: any = {
      IDdmTinh : this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetdsVatLieuNhanCongMay(data).subscribe(res => {
      let data: any = {
        IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      };
      this.DanhmucService.GetListdmThietBiDM(data).subscribe(resListdmVatLieuNhanCongMay => {
        const modalRef = this.modalService.open(LuachonVLNCMComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.header = "Lựa chọn thiết bị";
        modalRef.componentInstance.checkListtree = Object.assign([], res.items);
        modalRef.componentInstance.Listtree = Object.assign([], resListdmVatLieuNhanCongMay);
        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.componentInstance.Nam = this.dataitem.Nam;
        modalRef.result.then((data) => {
          this.GetGiaThietBi();
        }, (reason) => {
          // không
        });
      });
    });
  }

  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      IDdmTinh: this.dataitem.IDdmTinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ExportGiaThietBi(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }
  dequyGia(element, giatri){
    element.data.Thang1 = element.data.Thang1/ giatri;
    element.data.Thang2 = element.data.Thang2/ giatri;
    element.data.Thang3 = element.data.Thang3/ giatri;
    element.data.Thang4 = element.data.Thang4/ giatri;
    element.data.Thang5 = element.data.Thang5/ giatri;
    element.data.Thang6 = element.data.Thang6/ giatri;
    element.data.Thang7 = element.data.Thang7/ giatri;
    element.data.Thang8 = element.data.Thang8/ giatri;
    element.data.Thang9 = element.data.Thang9/ giatri;
    element.data.Thang10 = element.data.Thang10/ giatri;
    element.data.Thang11 = element.data.Thang11/ giatri;
    element.data.Thang12 = element.data.Thang12/ giatri;
    element.data.TBinhQuy1 = element.data.TBinhQuy1/ giatri;
    element.data.TBinhQuy2 = element.data.TBinhQuy2/ giatri;
    element.data.TBinhQuy3 = element.data.TBinhQuy3/ giatri;
    element.data.TBinhQuy4 = element.data.TBinhQuy4/ giatri;
    element.data.TBinhNam = element.data.TBinhNam/ giatri;
    if(element.children.length > 0){
      element.children.forEach(i => {
        this.dequyGia(i, giatri)
      });
    }
  }
}
