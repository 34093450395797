import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import * as moment from 'moment';

@Component({
  selector: 'app-luachon-vlncm',
  templateUrl: './luachon-vlncm.component.html',
  styleUrls: ['./luachon-vlncm.component.css']
})
export class LuaChonVLCNMCongTrinhComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdsLoaiCongTrinh: any = 0;
  IDdmTinh: any = 0;
  Nam: any = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    for (let i = 0; i < this.Listtree.length; i++) {

      for (let j = 0; j < this.checkListtree.length; j++) {
        if (this.Listtree[i].IDdmVatLieuNhanCongMay == this.checkListtree[j].IDdmVatLieuNhanCongMay) {
          this.Listtree[i].check = true;
          this.Listtree[i].IDdatontai = this.checkListtree[j].ID;
          break;
        }
        else
          this.Listtree[i].IDdatontai = 0;
      }
    }
  }

  // SelectTREE(item) {

  //   console.log(item)
  //   console.log(1)
  //   this.itemSELeCT = item;
  //   item.select = true;
  //   for (let i = 0; i < this.Listtree.length; i++) {
  //     this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
  //     // if (this.Listtree[i].children.length > 0) {
  //     //   this.SelectchildrenTREE(this.Listtree[i].children, item);
  //     // }
  //   }
  // }

  // SelectchildrenTREE(Listchildren, item) {
  //   for (let i = 0; i < Listchildren.length; i++) {
  //     Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
  //     if (Listchildren[i].children.length > 0) {
  //       this.SelectchildrenTREE(Listchildren[i].children, item);
  //     }
  //   }
  // }

  CheckTREE(item) {   
    item.check = !item.check;
    if (item.check) {
      let data: any = {
        "ID": item.IDdatontai,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "IDdsVatLieuNhanCongMay": item.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        // "IDdmTinh": this.IDdmTinh,
        "isXoa": false,
        "Nam": this.Nam
      }
      this.Listpost.push(data);
    }
    else {      
      if (item.IDdatontai > 0) {
        let data: any = {
          "ID": item.IDdatontai,
          "IDdmKhuVuc": this.IDdmKhuVuc,
          "IDdsVatLieuNhanCongMay": item.ID,
          "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
          // "IDdmTinh": this.IDdmTinh,
          "isXoa": true,
          "Nam": this.Nam
        }
        this.Listpost.push(data);
      }
      else {
        for (let i = 0; i < this.Listpost.length; i++) {
          if (item.IDdmVatLieuNhanCongMay == this.Listpost[i].IDdmVatLieuNhanCongMay) {
            this.Listpost.splice(i, 1);
            break;
          }
        }
      }
    }
    // if (item.children.length > 0) {
    //   this.CHECKchildrenTREE(item.children, item.check);
    // }
  }

  // CHECKchildrenTREE(Listchildren, value) {
  //   for (let i = 0; i < Listchildren.length; i++) {
  //     Listchildren[i].check = value;
  //     if (value) {
  //       let data: any = {
  //         "ID": Listchildren[i].IDdatontai,
  //         "IDdmKhuVuc": this.IDdmKhuVuc,
  //         "IDdmVatLieuNhanCongMay": Listchildren[i].IDdmVatLieuNhanCongMay,
  //         "IDdsVatLieuNhanCongMay": Listchildren[i].IDdsVatLieuNhanCongMay,
  //         "isXoa": false,
  //         "Nam": moment().year()
  //       }
  //       this.Listpost.push(data);
  //     }
  //     else {
  //       if (Listchildren[i].IDdatontai > 0) {
  //         let data: any = {
  //           "ID": Listchildren[i].IDdatontai,
  //           "IDdmKhuVuc": this.IDdmKhuVuc,
  //           "IDdmVatLieuNhanCongMay": Listchildren[i].IDdmVatLieuNhanCongMay,
  //           "IDdsVatLieuNhanCongMay": Listchildren[i].IDdsVatLieuNhanCongMay,
  //           "isXoa": true,
  //           "Nam": moment().year()
  //         }
  //         this.Listpost.push(data);
  //       }
  //       else {
  //         for (let j = 0; j < this.Listpost.length; j++) {
  //           if (Listchildren[i].IDdmVatLieuNhanCongMay == this.Listpost[j].IDdmVatLieuNhanCongMay) {
  //             this.Listpost.splice(j, 1);
  //             break;
  //           }
  //         }
  //       }
  //     }
  //     if (Listchildren[i].children.length > 0) {
  //       this.CHECKchildrenTREE(Listchildren[i].children, value);
  //     }
  //   }
  // }

  XacNhan() {
    this.ApiService.SetdsCongTrinhVatLieu(this.Listpost).subscribe(res => {

      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
