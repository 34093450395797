import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService, httpOptions } from './../../services/host.service';

@Component({
  selector: 'app-diaphuongtheokhuvuc',
  templateUrl: './diaphuongtheokhuvuc.component.html',
  styleUrls: ['./diaphuongtheokhuvuc.component.css']
})
export class DiaphuongtheokhuvucComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};

  uploader: FileUploader;

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    // this.GetListdmKhuVuc(this.dataitem.IDdmTinh);
    this.GetListdmTinh()
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }

  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    let res = JSON.parse(response);
    res[0].Id = 0;
    // this.lstFileKyThuatUpload.push(res[0]);    
    this.ApiService.ImportGia(res[0], this.dataitem.IDdmKhuVuc, this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
      // this.GetChiSoGiaDiaPhuongKhuVuc()
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  GetChiSoGiaDiaPhuongKhuVuc() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetChiSoGiaDiaPhuongKhuVuc(data).subscribe(res => {
      this.TreeVLNCM = res;
    });
  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length === 0){
        this.GetChiSoGiaDiaPhuongKhuVuc();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;
        this.GetChiSoGiaDiaPhuongKhuVuc();
      }
    });
  }
  xuatfile() {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.ExportGia(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
