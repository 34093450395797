import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class BaocaoService {

  constructor(
    private http: HttpClient
  ) { }

  public Url = `${HostService.serverUrl}BaoCao`;
  HeaderBaoCaoCSGVung(data) {
    let api = `${this.Url}/HeaderBaoCaoCSGVung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  BaoCaoCSGVung(data) {
    let api = `${this.Url}/BaoCaoCSGVung`;
    return this.http.post<any>(api, data, httpOptions);
  }

  HeaderBaoCaoCSGXayDungCongTrinh(data) {
    let api = `${this.Url}/HeaderBaoCaoCSGXayDungCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  BaoCaoCSGXayDungCongTrinh(data) {
    let api = `${this.Url}/BaoCaoCSGXayDungCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportbcCSGXayDungCongTrinh(data) {
    let api = `${this.Url}/ExportbcCSGXayDungCongTrinh`;
    return this.http.post<any>(api, data, httpOptions);
  }
  ExportBaoCaoCSGVung(data) {
    let api = `${this.Url}/ExportBaoCaoCSGVung`;
    return this.http.post<any>(api, data, httpOptions);
  }
  HeaderBaoCaoCSGCoCauChiPhi(data) {
    let api = `${this.Url}/HeaderBaoCaoCSGCoCauChiPhi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  BaoCaoCSGCoCauChiPhi(data) {
    let api = `${this.Url}/BaoCaoCSGCoCauChiPhi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportBaoCaoCSGCoCauChiPhi(data) {
    let api = `${this.Url}/ExportBaoCaoCSGCoCauChiPhi`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetHeaderbcCSGVatLieuNhanCongMayChuYeu(data) {
    let api = `${this.Url}/GetHeaderbcCSGVatLieuNhanCongMayChuYeu`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetbcCSGVatLieuNhanCongMayChuYeu(data) {
    let api = `${this.Url}/GetbcCSGVatLieuNhanCongMayChuYeu`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportbcCSGVatLieuNhanCongMayChuYeu(data) {
    let api = `${this.Url}/ExportbcCSGVatLieuNhanCongMayChuYeu`;
    return this.http.post<any>(api, data, httpOptions);
  }

  HeaderBaoCaoCSGVatLieuNhanCongMay(data) {
    let api = `${this.Url}/HeaderBaoCaoCSGVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  GetBaoCaoCSGVatLieuNhanCongMay(data) {
    let api = `${this.Url}/GetBaoCaoCSGVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }

  ExportbcCSGVatLieuNhanCongMay(data) {
    let api = `${this.Url}/ExportbcCSGVatLieuNhanCongMay`;
    return this.http.post<any>(api, data, httpOptions);
  }
  
}
