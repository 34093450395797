import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from 'src/app/services/danhmuc.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChiPhiVatLieuNhanCongMayComponent } from '../chiphivatlieunhancongmay/chiphivatlieunhancongmay.component';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { MyService } from './../../../../services/my.service';
import { HienthicongtrinhdaidienmodalComponent } from 'src/app/danhmuc/danhmucdungchung/NgbModal/hienthicongtrinhdaidienmodal/hienthicongtrinhdaidienmodal.component';

@Component({
  selector: 'app-themmoiduan',
  templateUrl: './themmoiduan.component.html',
  styleUrls: ['./themmoiduan.component.css']
})
export class ThemMoiDuAnComponent implements OnInit {

  public IDParent: any;
  public Nam: any;
  public itemADD: any = {};
  public ThongTinChung: any = {};
  public ChiPhiChung: any = {};
  public ChiPhiVatLieu: any = [];
  public ChiPhiNhanCong: any = [];
  public ChiPhiMay: any = [];

  header: any = '';
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  objloaicongtrinh: any = {};
  IDdsLoaiCongTrinh: any = 0;
  IDdmTinh: any = 0;
  IDdmKhuVuc: any = 0;
  dataitem: any = {};
  listDanhMucDungChung: any = [];
  ListtreeVL: any = [];
  ListtreeNC: any = [];
  ListtreeMTC: any = [];
  ListtreeMS: any = [];
  isNotHienThi: any = false;
  IddmDungChung: any = 0;
  listdmgiaidoanduan: any = [];
  listNam: any = [];
  listThang: any = [
  ];
  listLoaiTinhCSG: any = [{
    Ma: 11,
    Ten: 'Chỉ số giá xây dựng',
  },
  {
    Ma: 12,
    Ten: 'Chỉ số giá thiết bị',
  },
  {
    Ma: 200,
    Ten: 'Cả hai',
  },];
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private MyService: MyService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.IDUser = JSON.parse(localStorage.getItem('currentUser')).UserName;
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
      this.ItemPOST = {
        "ThongTinChung": {
        },
        "ChiPhiChung": {
        },
        "listChiPhiVatLieu": [],
        "listChiPhiNhanCong": [],
        "listChiPhiMay": [],
        "listChiPhiMuaSam": [],
      };


      // 0 : Thêm mới cùng cấp
      // 1 : Thêm mới cấp con
      this.ItemPOST.cap = 1;
      // this.ItemPOST.ID = 0;
      // this.ItemPOST.IDParent = this.IDParent;
      this.ItemPOST.Loai = 1;
      this.ItemPOST.collspan = true;
      this.ItemPOST.check = false;
      this.ItemPOST.select = false;
      this.GetdsVatLieu_New();
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
      this.GetListdsVatLieuCongNhanMayTheoKhuVuc();
      if(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== 0 && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== undefined
        && this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix !== null){
        this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu =  this.MyService.unixToDate(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieuUnix);
      }
    }
    this.GetListdmDungChung();
    this.GetListdmGiaiDoanDuAn();
    this.GetListNam();
    this.GetListThang();
  }

  SetData() {
    let data: any =
    {
      "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ID,
      "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
      "IDParent": this.IDParent,
      "ThongTinChung": {
        "ThoiGianLapDuLieuUnix": this.MyService.DateToUnix(this.ItemPOST.ThongTinChung.ThoiGianLapDuLieu),
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ThongTinChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "Ten": this.ItemPOST.ThongTinChung.Ten != undefined && this.ItemPOST.ThongTinChung.Ten != null ? this.ItemPOST.ThongTinChung.Ten : "",
        "IDdmLoaiCongTrinh": this.objloaicongtrinh.IDdmLoaiCongTrinh,
        "IDdmCapCongTrinh": this.ItemPOST.ThongTinChung.IDdmCapCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmCapCongTrinh : 0,
        "IDdmNhomCongTrinh": this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh != undefined ? this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh : 0,
        "DiaDiem": this.ItemPOST.ThongTinChung.DiaDiem != undefined && this.ItemPOST.ThongTinChung.DiaDiem != null ? this.ItemPOST.ThongTinChung.DiaDiem : "",
        "CongSuat": this.ItemPOST.ThongTinChung.CongSuat != undefined && this.ItemPOST.ThongTinChung.CongSuat != null ? this.ItemPOST.ThongTinChung.CongSuat : "",
        "NangLuc": this.ItemPOST.ThongTinChung.NangLuc != undefined && this.ItemPOST.ThongTinChung.NangLuc != null ? this.ItemPOST.ThongTinChung.NangLuc : "",
        "QuyChuan": this.ItemPOST.ThongTinChung.QuyChuan != undefined && this.ItemPOST.ThongTinChung.QuyChuan != null ? this.ItemPOST.ThongTinChung.QuyChuan : "",
        "TieuChuan": this.ItemPOST.ThongTinChung.TieuChuan != undefined && this.ItemPOST.ThongTinChung.TieuChuan != null ? this.ItemPOST.ThongTinChung.TieuChuan : "",
        "ThoiGianSuDung": this.ItemPOST.ThongTinChung.ThoiGianSuDung != undefined && this.ItemPOST.ThongTinChung.ThoiGianSuDung != null ? this.ItemPOST.ThongTinChung.ThoiGianSuDung : "",
        "CoChe": this.ItemPOST.ThongTinChung.CoChe != undefined && this.ItemPOST.ThongTinChung.CoChe != null ? this.ItemPOST.ThongTinChung.CoChe : "",
        "DienTich": this.ItemPOST.ThongTinChung.DienTich != undefined && this.ItemPOST.ThongTinChung.DienTich != null ? this.ItemPOST.ThongTinChung.DienTich : "",
        "ThongTinKhac": this.ItemPOST.ThongTinChung.ThongTinKhac != undefined && this.ItemPOST.ThongTinChung.ThongTinKhac != null ? this.ItemPOST.ThongTinChung.ThongTinKhac : "",
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "IDdmGiaiDoanDuAn": this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn,
        "NamXayDung": this.ItemPOST.ThongTinChung.NamXayDung,
        "NamSuDung": this.ItemPOST.ThongTinChung.NamSuDung,
        "ThangSuDung": this.ItemPOST.ThongTinChung.ThangSuDung,
        "ChuDauTu": this.ItemPOST.ThongTinChung.ChuDauTu,
      },
      "ChiPhiChung": {
        "ID": this.type == 'themmoi' ? 0 : this.ItemPOST.ChiPhiChung.ID,
        "IDdsLoaiCongTrinh": this.IDdsLoaiCongTrinh,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "ChiPhiXayDung": this.ItemPOST.ChiPhiChung.ChiPhiXayDung != undefined && this.ItemPOST.ChiPhiChung.ChiPhiXayDung != null ? this.ItemPOST.ChiPhiChung.ChiPhiXayDung : 0,
        "ChiPhiVatLieu": this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiVatLieu != null ? this.ItemPOST.ChiPhiChung.ChiPhiVatLieu : 0,
        "ChiPhiMay": this.ItemPOST.ChiPhiChung.ChiPhiMay != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMay != null ? this.ItemPOST.ChiPhiChung.ChiPhiMay : 0,
        "ChiPhiNhanCong": this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != undefined && this.ItemPOST.ChiPhiChung.ChiPhiNhanCong != null ? this.ItemPOST.ChiPhiChung.ChiPhiNhanCong : 0,
        "ChiPhiThietBi": this.ItemPOST.ChiPhiChung.ChiPhiThietBi != undefined && this.ItemPOST.ChiPhiChung.ChiPhiThietBi != null ? this.ItemPOST.ChiPhiChung.ChiPhiThietBi : 0,
        "ChiPhiMuaSam": this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != undefined && this.ItemPOST.ChiPhiChung.ChiPhiMuaSam != null ? this.ItemPOST.ChiPhiChung.ChiPhiMuaSam : 0,
        "ChiPhiLapDat": this.ItemPOST.ChiPhiChung.ChiPhiLapDat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiLapDat != null ? this.ItemPOST.ChiPhiChung.ChiPhiLapDat : 0,
        "ChiPhiKhac": this.ItemPOST.ChiPhiChung.ChiPhiKhac != undefined && this.ItemPOST.ChiPhiChung.ChiPhiKhac != null ? this.ItemPOST.ChiPhiChung.ChiPhiKhac : 0,
        "ChiPhiQuanLiDuAn": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn : 0,
        "ChiPhiQuanLiKhaoSat": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat : 0,
        "ChiPhiQuanLiThietKe": this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != undefined && this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe != null ? this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe : 0,
        "ChiPhiGiamSatXD": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD : 0,
        "Khac": this.ItemPOST.ChiPhiChung.Khac != undefined && this.ItemPOST.ChiPhiChung.Khac != null ? this.ItemPOST.ChiPhiChung.Khac : 0,
        "ChiPhiGiamSatTB": this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != undefined && this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB != null ? this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB : 0,
        "VonDauTu": this.ItemPOST.ChiPhiChung.VonDauTu != undefined && this.ItemPOST.ChiPhiChung.VonDauTu != null ? this.ItemPOST.ChiPhiChung.VonDauTu : 0,
        "Nam": this.Nam,
        "IDThongTinUser": this.dataitem.IDThongTinUser,
        "ChiPhiTuVanDauTu": this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != undefined && this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu != null ? this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu : 0,
      },
      "Loai": 1,
      "IDdmTinh": this.IDdmTinh,
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "listChiPhiVatLieu": this.ItemPOST.listChiPhiVatLieu,
      "listChiPhiNhanCong": this.ItemPOST.listChiPhiNhanCong,
      "listChiPhiMay": this.ItemPOST.listChiPhiMay,
      "listChiPhiMuaSam": this.ItemPOST.listChiPhiMuaSam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "listChiPhiKhacTrenCuc": this.ItemPOST.listChiPhiKhacTrenCuc,
      "listChiPhiTuVanDauTuTrenCuc": this.ItemPOST.listChiPhiTuVanDauTuTrenCuc,
      "listChiPhiQuanLyDuAnTrenCuc": this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc,
    };
    data.ChiPhiChung.ChiPhiVatLieu = 0;
    data.ChiPhiChung.ChiPhiNhanCong = 0;
    data.ChiPhiChung.ChiPhiMay = 0;
    data.ChiPhiChung.ChiPhiMuaSamThietBi = 0;

    if (this.ItemPOST.listChiPhiVatLieu.length > 0) {
      this.ItemPOST.listChiPhiVatLieu.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiVatLieu += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiNhanCong.length > 0) {
      this.ItemPOST.listChiPhiNhanCong.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiNhanCong += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiMuaSam.length > 0) {
      this.ItemPOST.listChiPhiMuaSam.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiMay += parseFloat(obj.GiaTri);
        }
      });
    }

    if (this.ItemPOST.listChiPhiMay.length > 0) {
      this.ItemPOST.listChiPhiMay.filter(obj => {
        if (!obj.isXoa) {
          data.ChiPhiChung.ChiPhiMuaSamThietBi += parseFloat(obj.GiaTri);
        }
      });
    }
    return data;
  }
  XacNhan() {
    if (this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == "") {
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else if(this.ItemPOST.listChiPhiKhacTrenCuc ===undefined || this.ItemPOST.listChiPhiKhacTrenCuc.length === 0)
      this.toastr.error("Bạn chưa nhập chi phí khác");
    else if(this.ItemPOST.listChiPhiTuVanDauTuTrenCuc === undefined || this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.length === 0)
      this.toastr.error("Bạn chưa nhập chi phi tư vấn đầu tư");
    else if(this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc === undefined || this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.length ===0)
      this.toastr.error("Bạn chưa nhập chi phí quản lí dự án");
    // else if(this.ItemPOST.listChiPhiVatLieu === undefined|| this.ItemPOST.listChiPhiVatLieu.length ===0 )
    //   this.toastr.error("Bạn chưa nhập chi phí vật liệu");
    // else if(this.ItemPOST.listChiPhiNhanCong  === undefined|| this.ItemPOST.listChiPhiNhanCong.length ===0)
    //   this.toastr.error("Bạn chưa nhập chi phí nhân công");
    // else if(this.ItemPOST.listChiPhiMay === undefined || this.ItemPOST.listChiPhiMay.length ===0)
    //   this.toastr.error("Bạn chưa nhập chi phí máy");
    // else if(this.ItemPOST.listChiPhiMuaSam === undefined|| this.ItemPOST.listChiPhiMuaSam.length ===0 )
    //   this.toastr.error("Bạn chưa nhập chi phí mua sắm");
    else {
      this.DanhmucService.SetdmLoaiCongTrinh(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  TinhVonDauTu() {
    // this.ItemPOST.ChiPhiChung.VonDauTu = 0;
    // let ChiPhiXayDung: any = parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiXayDung);
    // let ChiPhiThietBi: any = parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiThietBi);
    // let ChiPhiKhac: any = parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiKhac);
    // this.ItemPOST.ChiPhiChung.VonDauTu = ChiPhiXayDung + ChiPhiThietBi + ChiPhiKhac;
  }

  TinhChiPhiXayDung() {
    // this.ItemPOST.ChiPhiChung.ChiPhiXayDung = 0;

    // if (this.ItemPOST.listChiPhiVatLieu.length > 0) {
    //   this.ItemPOST.listChiPhiVatLieu.filter(obj => {
    //     if (!obj.isXoa) {
    //       this.ItemPOST.ChiPhiChung.ChiPhiXayDung += parseFloat(obj.GiaTri);
    //     }
    //   });
    // }

    // if (this.ItemPOST.listChiPhiNhanCong.length > 0) {
    //   this.ItemPOST.listChiPhiNhanCong.filter(obj => {
    //     if (!obj.isXoa) {
    //       this.ItemPOST.ChiPhiChung.ChiPhiXayDung += parseFloat(obj.GiaTri);
    //     }
    //   });
    // }

    // if (this.ItemPOST.listChiPhiMay.length > 0) {
    //   this.ItemPOST.listChiPhiMay.filter(obj => {
    //     if (!obj.isXoa) {
    //       this.ItemPOST.ChiPhiChung.ChiPhiXayDung += parseFloat(obj.GiaTri);
    //     }
    //   });
    // }
    // this.TinhVonDauTu();
  }

  TinhChiPhiThietBi() {
    this.ItemPOST.ChiPhiChung.ChiPhiThietBi = 0;
    let ChiPhiMuaSam: any = this.ItemPOST.ChiPhiChung.ChiPhiMuaSam == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiMuaSam);
    let ChiPhiLapDat: any = this.ItemPOST.ChiPhiChung.ChiPhiLapDat == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiLapDat);
    this.ItemPOST.ChiPhiChung.ChiPhiThietBi = ChiPhiMuaSam + ChiPhiLapDat;
    // this.TinhVonDauTu();
  }

  TinhChiPhiKhac() {
    // this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0;
    // let ChiPhiQuanLiDuAn: any = this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn);
    // let ChiPhiGiamSatXD: any = this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD);
    // let ChiPhiQuanLiKhaoSat: any = this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat);
    // let ChiPhiGiamSatTB: any = this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB);
    // let ChiPhiQuanLiThietKe: any = this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe);
    // let Khac: any = this.ItemPOST.ChiPhiChung.Khac == undefined ? 0 : parseFloat(this.ItemPOST.ChiPhiChung.Khac);
    // this.ItemPOST.ChiPhiChung.ChiPhiKhac = ChiPhiQuanLiDuAn +
    //   ChiPhiGiamSatXD +
    //   ChiPhiQuanLiKhaoSat +
    //   ChiPhiGiamSatTB + ChiPhiQuanLiThietKe + Khac;
    // this.TinhVonDauTu();
  }
  GetdsVatLieu_New() {
    let dataNew: any = []
    let dataNew1: any = []
    let dataNew2: any = []
    let dataNew3: any = []
    let data: any = {
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "Nam": this.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
      resListdmVatLieuNhanCongMay.filter(obj => {
        if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 0) {
          let datainsert: any = {
            "ID": 0,
            "IDdsVatLieuNhanCongMay": obj.ID,
            "Nam": obj.Nam,
            "IDThongTinUser": obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "TendmVatLieuNhanCongMay": obj.TendmVatLieuNhanCongMay,
            "DonViTinh": obj.DonViTinh,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc,
          }
          dataNew.push(datainsert);
        }
        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 1) {
          let datainsert: any = {
            "ID": 0,
            "IDdsVatLieuNhanCongMay": obj.ID,
            "Nam": obj.Nam,
            "IDThongTinUser": obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "TendmVatLieuNhanCongMay": obj.TendmVatLieuNhanCongMay,
            "DonViTinh": obj.DonViTinh,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc
          }
          dataNew1.push(datainsert);
        }

        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 2) {
          let datainsert: any = {
            "ID": 0,
            "IDdsVatLieuNhanCongMay": obj.ID,
            "Nam": obj.Nam,
            "IDThongTinUser": obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "DonViTinh": obj.DonViTinh,
            "TendmVatLieuNhanCongMay": obj.TendmVatLieuNhanCongMay,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc
          }
          dataNew2.push(datainsert);
        }
        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 3) {
          let datainsert: any = {
            "ID": 0,
            "IDdsVatLieuNhanCongMay": obj.ID,
            "Nam": obj.Nam,
            "IDThongTinUser": obj.IDThongTinUser,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "DonViTinh": obj.DonViTinh,
            "TendmVatLieuNhanCongMay": obj.TendmVatLieuNhanCongMay,
            "isXoa": false,
            "LoaiDanhMuc": obj.LoaiDanhMuc
          }
          dataNew3.push(datainsert);
        }
      });
      this.ItemPOST.listChiPhiNhanCong = dataNew1;
      this.ItemPOST.listChiPhiVatLieu = dataNew;
      this.ItemPOST.listChiPhiMay = dataNew2;
      this.ItemPOST.listChiPhiMuaSam = dataNew3;
      this.ListtreeVL = dataNew;
      this.ListtreeNC = dataNew1;
      this.ListtreeMTC = dataNew2;
      this.ListtreeMS = dataNew3;
    });
  }
  // GetdsNhanCong_New() {
  //   let dataNew: any = []
  //   let data: any = {
  //     "IDdmKhuVuc": this.IDdmKhuVuc,
  //     "Nam": this.Nam,
  //     "IDThongTinUser": this.dataitem.IDThongTinUser,
  //   };
  //   this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
  //     resListdmVatLieuNhanCongMay.filter(obj => {
  //       if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 1) {
  //         dataNew.push(obj);
  //         this.ItemPOST.listChiPhiNhanCong = dataNew;
  //       }
  //     });
  //   });
  // }
  // GetdsMayThiCong_New() {
  //   let dataNew: any = []
  //   let data: any = {
  //     "IDdmKhuVuc": this.IDdmKhuVuc,
  //     "Nam": this.Nam,
  //     "IDThongTinUser": this.dataitem.IDThongTinUser,
  //   };
  //   this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
  //     resListdmVatLieuNhanCongMay.filter(obj => {
  //       if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 2) {
  //         dataNew.push(obj);
  //         this.ItemPOST.listChiPhiMay = dataNew;
  //       }
  //     });
  //   });
  // }
  // GetdsMuaSam_New() {
  //   let dataNew: any = []
  //   let data: any = {
  //     "IDdmKhuVuc": this.IDdmKhuVuc,
  //     "Nam": this.Nam,
  //     "IDThongTinUser": this.dataitem.IDThongTinUser,
  //   };
  //   this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
  //     resListdmVatLieuNhanCongMay.filter(obj => {
  //       if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 3) {
  //         dataNew.push(obj);
  //         this.ItemPOST.listChiPhiMuaSam = dataNew;
  //       }
  //     });
  //   });
  // }
  GetListdsVatLieuCongNhanMayTheoKhuVuc() {
    let data: any = {
      "IDdmKhuVuc": this.IDdmKhuVuc,
      "Nam": this.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
      resListdmVatLieuNhanCongMay.filter(obj => {
        if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 0) {
          obj.IDdsVatLieuNhanCongMay = obj.ID;
          this.ListtreeVL.push(obj);
        }
        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 1) {
          obj.IDdsVatLieuNhanCongMay = obj.ID;
          this.ListtreeNC.push(obj);
        }
        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 2) {
          obj.IDdsVatLieuNhanCongMay = obj.ID;
          this.ListtreeMTC.push(obj);
        }
        else if (obj.IDParentdmVatLieuNhanCongMay == 0 && obj.LoaiDanhMuc == 3) {
          obj.IDdsVatLieuNhanCongMay = obj.ID;
          this.ListtreeMS.push(obj);
        }
      });
    });
  }
  GetdsVatLieu() {
    console.log(this.ItemPOST.listChiPhiVatLieu);
    const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.type = "VatLieu";
    modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiVatLieu);
    modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeVL);
    modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
    modalRef.componentInstance.IDdmTinh = this.IDdmTinh;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
    modalRef.componentInstance.listvatlieu = Object.assign([], this.ItemPOST.listChiPhiVatLieu);
    modalRef.result.then((data) => {
      this.ItemPOST.listChiPhiVatLieu = data.vatlieu;
      this.ItemPOST.listChiPhiVatLieu.filter(obj => {
        if (obj.GiaTri == undefined && obj.ID == 0) {
          obj.GiaTri = 0;
        }
      });
    }, (reason) => {
      // không

    });
  }

  GetdsNhanCong() {
    const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.type = "NhanCong";
    modalRef.componentInstance.Selected = this.ChiPhiNhanCong;
    modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiNhanCong);
    modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeNC);
    modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
    modalRef.componentInstance.listnhancong = Object.assign([], this.ItemPOST.listChiPhiNhanCong);
    modalRef.result.then((data) => {
      this.ItemPOST.listChiPhiNhanCong = data.nhancong;
      this.ItemPOST.listChiPhiNhanCong.filter(obj => {
        if (obj.GiaTri == undefined && obj.ID == 0) {
          obj.GiaTri = 0;
        }
      });
    }, (reason) => {
      // không
    });
  }

  GetdsMayThiCong() {
    const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.type = "ThiCong";
    modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiMay);
    modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeMTC);
    modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
    modalRef.componentInstance.listmaythicong = Object.assign([], this.ItemPOST.listChiPhiMay);
    modalRef.result.then((data) => {
      this.ItemPOST.listChiPhiMay = data.maythicong;
      this.ItemPOST.listChiPhiMay.filter(obj => {
        if (obj.GiaTri == undefined && obj.ID == 0) {
          obj.GiaTri = 0;
        }
      });
    }, (reason) => {
      // không
    });
  }

  GetdsChiPhiMuaSam() {
    const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.type = "MuaSam";
    modalRef.componentInstance.checkListtree = Object.assign([], this.ItemPOST.listChiPhiMuaSam);
    modalRef.componentInstance.Listtree = Object.assign([], this.ListtreeMS);
    modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.IDdsLoaiCongTrinh = this.IDdsLoaiCongTrinh;
    modalRef.componentInstance.listchiphimuasam = Object.assign([], this.ItemPOST.listChiPhiMuaSam);
    modalRef.result.then((data) => {
      this.ItemPOST.listChiPhiMuaSam = data.chiphimuasam;
      this.ItemPOST.listChiPhiMuaSam.filter(obj => {
        if (obj.GiaTri == undefined && obj.ID == 0) {
          obj.GiaTri = 0;
        }
      });
    }, (reason) => {
      // không
    });
  }

  changedsvatlieu() {

  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  saveDanhMucdungChung() {
    if (this.ItemPOST.ThongTinChung.Ten == undefined || this.ItemPOST.ThongTinChung.Ten == null || this.ItemPOST.ThongTinChung.Ten == "") {
      this.toastr.error("Bạn chưa nhập tên công trình");
    }
    else {
      let data: any = {
        "Ten": this.ItemPOST.ThongTinChung.Ten,
        "LoaiDanhMuc": 'CongTrinhDienHinh',
        "IDUser": this.dataitem.IDUser,
        "dmCongTrinhDienHinh": this.SetData()
      }
      this.DanhmucService.KiemTraTrungdmDungChung(data).subscribe(resGhiDe => {
        if (resGhiDe.State === 11) {
          const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
          modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn ghi đè?";
          modalRef.result.then((any) => {
            data.ID = resGhiDe.objectReturn.ID;
            this.DanhmucService.SetdmDungChung(data).subscribe(res => {
              if (res.State === 1) {
                this.toastr.success(res.message);
                this.Onclose();
              }
              else {
                this.toastr.error(res.message);
              }
            });
          }, (reason) => {
          });
        }
        else {
          this.DanhmucService.SetdmDungChung(data).subscribe(res => {
            if (res.State === 1) {
              this.toastr.success(res.message);
              this.Onclose();
            }
            else {
              this.toastr.error(res.message);
            }
          });
        }
      });
    }
  }

  GetListdmDungChung() {
    this.listDanhMucDungChung = [];
    this.DanhmucService.GetListdmDungChungCongTrinhDienHinh('CongTrinhDienHinh', 0, "", this.dataitem.IDUser).subscribe(res => {
      this.listDanhMucDungChung = res;
    });
  }
  LuaChonDanhMucdungChung() {
    const modalRef = this.modalService.open(HienthicongtrinhdaidienmodalComponent, {
      backdrop: 'static'
      , size: 'xl'
    });
    modalRef.componentInstance.LoaiDanhMucDungChung = 'CongTrinhDienHinh';
    modalRef.componentInstance.listDanhMucDungChungCopy = this.listDanhMucDungChung;
    modalRef.componentInstance.Nam = this.Nam;
    modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
    modalRef.componentInstance.IDThongTinUser = this.dataitem.IDThongTinUser;
    modalRef.result.then((data) => {
      this.ItemPOST.ThongTinChung.Ten = data.data.ThongTinChung.Ten;
      this.ItemPOST.ThongTinChung.IDdmCapCongTrinh = data.data.ThongTinChung.IDdmCapCongTrinh;
      this.ItemPOST.ThongTinChung.IDdmNhomCongTrinh = data.data.ThongTinChung.IDdmNhomCongTrinh;
      this.ItemPOST.ThongTinChung.DiaDiem = data.data.ThongTinChung.DiaDiem;
      this.ItemPOST.ThongTinChung.CongSuat = data.data.ThongTinChung.CongSuat;
      this.ItemPOST.ThongTinChung.NangLuc = data.data.ThongTinChung.NangLuc;
      this.ItemPOST.ThongTinChung.QuyChuan = data.data.ThongTinChung.QuyChuan;
      this.ItemPOST.ThongTinChung.TieuChuan = data.data.ThongTinChung.TieuChuan;
      this.ItemPOST.ThongTinChung.ThoiGianSuDung = data.data.ThongTinChung.ThoiGianSuDung;
      this.ItemPOST.ThongTinChung.CoChe = data.data.ThongTinChung.CoChe;
      this.ItemPOST.ThongTinChung.DienTich = data.data.ThongTinChung.DienTich;
      this.ItemPOST.ThongTinChung.ThongTinKhac = data.data.ThongTinChung.ThongTinKhac;
      this.ItemPOST.ThongTinChung.ChuDauTu = data.data.ThongTinChung.ChuDauTu;
      this.ItemPOST.ThongTinChung.NamSuDung = data.data.ThongTinChung.NamSuDung;
      this.ItemPOST.ThongTinChung.NamXayDung = data.data.ThongTinChung.NamXayDung;
      this.ItemPOST.ThongTinChung.ThangSuDung = data.data.ThongTinChung.ThangSuDung;
      this.ItemPOST.ThongTinChung.IDdmGiaiDoanDuAn = data.data.ThongTinChung.IDdmGiaiDoanDuAn;
      this.ItemPOST.ChiPhiChung.ChiPhiXayDung = data.data.ChiPhiChung.ChiPhiXayDung;
      this.ItemPOST.ChiPhiChung.ChiPhiThietBi = data.data.ChiPhiChung.ChiPhiThietBi;
      this.ItemPOST.ChiPhiChung.ChiPhiMuaSam = data.data.ChiPhiChung.ChiPhiMuaSam;
      this.ItemPOST.ChiPhiChung.ChiPhiLapDat = data.data.ChiPhiChung.ChiPhiLapDat;
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn = data.data.ChiPhiChung.ChiPhiQuanLiDuAn;
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiKhaoSat = data.data.ChiPhiChung.ChiPhiQuanLiKhaoSat;
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiThietKe = data.data.ChiPhiChung.ChiPhiQuanLiThietKe;
      this.ItemPOST.ChiPhiChung.ChiPhiGiamSatXD = data.data.ChiPhiChung.ChiPhiGiamSatXD;
      this.ItemPOST.ChiPhiChung.Khac = data.data.ChiPhiChung.Khac;
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu = data.data.ChiPhiChung.ChiPhiTuVanDauTu;
      this.ItemPOST.ChiPhiChung.ChiPhiGiamSatTB = data.data.ChiPhiChung.ChiPhiGiamSatTB;
      this.ItemPOST.ChiPhiChung.VonDauTu = data.data.ChiPhiChung.VonDauTu;

      this.ItemPOST.ChiPhiChung.ChiPhiKhac = (data.data.ChiPhiChung.ChiPhiQuanLiDuAn || 0) + (data.data.ChiPhiChung.ChiPhiTuVanDauTu || 0) + (data.data.ChiPhiChung.Khac || 0);

      //
      this.ItemPOST.listChiPhiVatLieu = data.data.listChiPhiVatLieu;
      this.ItemPOST.listChiPhiNhanCong = data.data.listChiPhiNhanCong;
      this.ItemPOST.listChiPhiMay = data.data.listChiPhiMay;
      this.ItemPOST.listChiPhiMuaSam = data.data.listChiPhiMuaSam;
      //
      this.ItemPOST.listChiPhiVatLieu.forEach(element => {
        element.GiaTriSauThue = element.GiaTri;
      });
      this.ItemPOST.listChiPhiNhanCong.forEach(element => {
        element.GiaTriSauThue = element.GiaTri;
      });
      this.ItemPOST.listChiPhiMay.forEach(element => {
        element.GiaTriSauThue = element.GiaTri;
      });
      this.ItemPOST.listChiPhiMuaSam.forEach(element => {
        element.GiaTriSauThue = element.GiaTri;
      });
      //
      this.ItemPOST.listChiPhiKhacTrenCuc = data.data.listChiPhiKhacTrenCuc;
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc = data.data.listChiPhiTuVanDauTuTrenCuc;
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc = data.data.listChiPhiQuanLyDuAnTrenCuc;
    }, (reason) => {
    });
  }
  GetListNam() {
    var listNam = [];
    this.listNam = [];
    for (let i = 1970; i <= 2050; i++) {
      let data: any = {
        "ma": i,
        "ten": i
      }
      listNam.push(data)
    }
    this.listNam = listNam;
  }
  GetListThang() {
    var listThang = [];
    this.listThang = [];
    for (let i = 1; i <= 12; i++) {
      let data: any = {
        "ma": i,
        "ten": i
      }
      listThang.push(data)
    }
    this.listThang = listThang;
  }
  GetListdmGiaiDoanDuAn() {
    let data: any = {
      CurrentPage: 0,
    };
    this.DanhmucService.GetListdmGiaiDoanDuAn(data).subscribe(res => {
      this.listdmgiaidoanduan = res
    });
  }
  TinhGiaTri(item) {
    item.GiaTriSauThue = Math.round((100 + parseInt(item.ThueGiaTriGiaTang || 0))/100 * parseInt(item.GiaTri || 0));
  }
  XoaListDauTu(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac); 
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  XoaListKhac(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.Khac = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiKhacTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.Khac += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac);  
    }
    else{
      this.ItemPOST.ChiPhiChung.Khac -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  XoaListQuanLiDuAn(item, isSua = false) {
    if(isSua = true){
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn = 0;
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = 0; 
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.forEach(element => {
        this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn += parseInt(element.GiaTri || 0)
      });
      this.ItemPOST.ChiPhiChung.ChiPhiKhac = parseInt(this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn) + parseInt(this.ItemPOST.ChiPhiChung.ChiPhiTuVanDauTu) +
                                            parseInt(this.ItemPOST.ChiPhiChung.Khac);  
    }
    else{
      this.ItemPOST.ChiPhiChung.ChiPhiQuanLiDuAn -= (item.GiaTri || 0);
      this.ItemPOST.ChiPhiChung.ChiPhiKhac -= (item.GiaTri || 0); 
      item.isXoa = true;
    }
  }
  ThemMoiQLDA(){
    if(this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc === undefined)
      this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiQuanLyDuAnTrenCuc.unshift(data);
  }
  ThemMoiTuVanDauTu(){
    if(this.ItemPOST.listChiPhiTuVanDauTuTrenCuc === undefined)
      this.ItemPOST.listChiPhiTuVanDauTuTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiTuVanDauTuTrenCuc.unshift(data);
  }
  ThemMoiKhac(){
    if(this.ItemPOST.listChiPhiKhacTrenCuc === undefined)
      this.ItemPOST.listChiPhiKhacTrenCuc = []
    let data: any = {
      Ten: "",
      GiaTri: 0,
      LoaiTinhCSG: 11,
      DonviTinh: "",
      ThueGiaTriGiaTang: 0,
      GiaTriSauThue: 0,
    }
    this.ItemPOST.listChiPhiKhacTrenCuc.unshift(data);
  }
}