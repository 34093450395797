import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { NgbPaginationModule, NgbAlertModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { AuthGuard } from './_guard/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { FormatdatetimeService } from './services/formatdatetime.service';
import localeVi from '@angular/common/locales/vi';
registerLocaleData(localeVi);
// import { MenuItem } from 'primeng/api';              

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { GiavatlieunhancongmayComponent } from './thongtinchung/giavatlieunhancongmay/giavatlieunhancongmay.component';
import { CapnhatgiavatlieunhancongmayComponent } from './thongtinchung/giavatlieunhancongmay/NgbModal/capnhatgiavatlieunhancongmay/capnhatgiavatlieunhancongmay.component';
import { TytrongcongtrinhComponent } from './thongtinchung/tytrongcongtrinh/tytrongcongtrinh.component';
import { NhaptytrongComponent } from './thongtinchung/tytrongcongtrinh/NgbModal/nhaptytrong/nhaptytrong.component';
import { TytrongVLNCMComponent } from './thongtinchung/tytrong-vlncm/tytrong-vlncm.component';
import { LuachonVLNCMComponent } from './thongtinchung/giavatlieunhancongmay/NgbModal/luachon-vlncm/luachon-vlncm.component';
import { LuachoncongtrinhComponent } from './thongtinchung/tytrongcongtrinh/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { TytrongComponent } from './thongtinchung/tytrong-vlncm/NgbModal/tytrong/tytrong.component';
import { TytrongtheokhuvucComponent } from './thongtinchung/tytrongtheokhuvuc/tytrongtheokhuvuc.component';
import { ThongbaoComponent } from './NgbModalAll/thongbao/thongbao.component';
import { ThemmoitreeComponent } from './NgbModalAll/themmoitree/themmoitree.component';
import { GiathietbiComponent } from './thongtinchung/giathietbi/giathietbi.component';
import { TytrongthietbiComponent } from './thongtinchung/tytrongthietbi/tytrongthietbi.component';
import { LoginComponent } from './login/login/login.component';
import { KhuvucComponent } from './chisogia/khuvuc/khuvuc.component';
import { DiaphuongtheokhuvucComponent } from './chisogia/diaphuongtheokhuvuc/diaphuongtheokhuvuc.component';
import { CongtrinhtheokhuvucComponent } from './chisogia/congtrinhtheokhuvuc/congtrinhtheokhuvuc.component';
import { XaydungcongtrinhComponent } from './baocao/xaydungcongtrinh/xaydungcongtrinh.component';
import { ChisogiatheococauchiphiComponent } from './baocao/chisogiatheococauchiphi/chisogiatheococauchiphi.component';
import { VatlieunhancongmayComponent } from './baocao/vatlieunhancongmay/vatlieunhancongmay.component';
import { VatlieunhancongmaykhongchuyeuComponent } from './baocao/vatlieunhancongmaykhongchuyeu/vatlieunhancongmaykhongchuyeu.component';
import { ChondanhmucComponent } from './danhmuc/chondanhmuc/chondanhmuc/chondanhmuc.component';
import { CapnhatdanhmucComponent } from './danhmuc/chondanhmuc/capnhatdanhmuc/capnhatdanhmuc.component';
import { DanhmuctinhComponent } from './danhmuc/danhmuctinh/danhmuctinh.component';
import { DanhmucvungComponent } from './danhmuc/danhmucvung/danhmucvung.component';
import { LoaicongtrinhComponent } from './danhmuc/loaicongtrinh/loaicongtrinh.component';
import { VatlieuCongnhanMayComponent } from './danhmuc/vatlieu-congnhan-may/vatlieu-congnhan-may.component';
import { UpdatekhuvucComponent } from './danhmuc/danhmuctinh/NgbModal/updatekhuvuc/updatekhuvuc.component';
import { ThietbiComponent } from './thongtinchung/giathietbi/NgbModal/thietbi/thietbi.component';
import { LuachonthietbiComponent } from './thongtinchung/giathietbi/NgbModal/luachonthietbi/luachonthietbi.component';
import { CapnhattytrongthietbiComponent } from './thongtinchung/tytrongthietbi/NgbModal/capnhattytrongthietbi/capnhattytrongthietbi.component';
import { DanhmucuserComponent } from './danhmuc/danhmucuser/danhmucuser.component';
import { UpdateuserComponent } from './danhmuc/danhmucuser/NgbModal/updateuser/updateuser.component';
import { LuaChonVLCNMCongTrinhComponent } from './thongtinchung/tytrong-vlncm/NgbModal/luachon-vlncm/luachon-vlncm.component';
import { ChiPhiVatLieuNhanCongMayComponent } from './thongtinchung/tytrong-vlncm/NgbModal/chiphivatlieunhancongmay/chiphivatlieunhancongmay.component';
import { ThemMoiDuAnComponent } from './thongtinchung/tytrong-vlncm/NgbModal/themmoiduan/themmoiduan.component';
import { DanhsachvatlieuComponent } from './thongtinchung/danhsachvatlieu/danhsachvatlieu.component';
import { NhaptytrongKhuVucComponent } from './thongtinchung/tytrongtheokhuvuc/nhaptytrong/nhaptytrong.component';
import { DanhmuccapcongtrinhComponent } from './danhmuc/danhmuccapcongtrinh/danhmuccapcongtrinh.component';
import { DanhmucnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/danhmucnhomcongtrinh.component';
import { CapnhatcapcongtrinhComponent } from './danhmuc/danhmuccapcongtrinh/NgbModal/capnhatcapcongtrinh/capnhatcapcongtrinh.component';
import { CapnhatnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/NgbModal/capnhatnhomcongtrinh/capnhatnhomcongtrinh.component';
import { CapnhatdanhmucvungComponent } from './danhmuc/danhmucvung/NgbModal/capnhatdanhmucvung/capnhatdanhmucvung.component';
import { FilterTrueFalsePipe } from './pipe/filter-true-false.pipe';
import { TytrongvungComponent } from './thongtinchung/tytrongvung/tytrongvung.component';
import { ChisogiatheovungComponent } from './chisogia/chisogiatheovung/chisogiatheovung.component';
import { CongtrinhtheovungComponent } from './chisogia/congtrinhtheovung/congtrinhtheovung.component';
import { CongboComponent } from './chisogia/khuvuc/NgbModal/congbo/congbo.component';
import { DscongboComponent } from './chisogia/dscongbo/dscongbo.component';
import { ApGiaThongBaoComponent } from './thongtinchung/giavatlieunhancongmay/NgbModal/apgiathongbao/apgiathongbao.component';
import { ThongtinuserComponent } from './thongtinuser/thongtinuser/thongtinuser.component';
import { CapnhapthongtinuserComponent } from './thongtinuser/thongtinuser/NgbModal/capnhapthongtinuser/capnhapthongtinuser.component';
import { ChiphimuasamComponent } from './danhmuc/chiphimuasam/chiphimuasam.component';
import { LuaChonCSGComponent } from './login/login/NgbModal/luachon/luachon.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { LuaChonTinhCSGComponent } from './login/login/NgbModal/luachontinh/luachontinh.component';
import { UpdatekhuvucLuaChonComponent } from './login/login/NgbModal/updatekhuvuc/updatekhuvuc.component';
import { ThemmoitreeLuaChonComponent } from './login/login/NgbModal/themmoitree/themmoitree.component';
import { LuaChonPhuongThucCSGComponent } from './login/login/NgbModal/luachoncsg/luachoncsg.component';
import { DanhMucDungChungComponent } from './danhmuc/danhmucdungchung/danhmucdungchung.component';
import { CapnhatDanhMucDungChungComponent } from './thongtinchung/giavatlieunhancongmay/NgbModal/capnhatdanhmucchung/capnhatdanhmucchung.component';
import { HienThiVLNCMComponent } from './danhmuc/danhmucdungchung/NgbModal/hienthi-vlncm/hienthi-vlncm.component';
import { LuaChonDanhMucDungChungCSGComponent } from './login/login/NgbModal/luachondanhmucchung/luachondanhmucchung.component';
import { ThemMoiDuAnDanhMucDungChungComponent } from './login/login/NgbModal/themmoiduancongtrinhdienhinh/themmoiduancongtrinhdienhinh.component';
import { BaoCaoVungComponent } from './baocao/baocaovung/baocaovung.component';
import { DoimatkhauComponent } from './danhmuc/danhmucuser/NgbModal/doimatkhau/doimatkhau.component';
import { DmgiaidoanduanComponent } from './danhmuc/dmgiaidoanduan/dmgiaidoanduan.component';
import { DmgiaidoanduanmodalComponent } from './danhmuc/dmgiaidoanduanmodal/dmgiaidoanduanmodal.component';
import { FilemauComponent } from './danhmuc/filemau/filemau.component';
import { HienthicongtrinhdaidienmodalComponent } from './danhmuc/danhmucdungchung/NgbModal/hienthicongtrinhdaidienmodal/hienthicongtrinhdaidienmodal.component';
import { DmloaicongtrinhmodalComponent } from './thongtinchung/danhsachvatlieu/dmloaicongtrinhmodal/dmloaicongtrinhmodal.component';
import { HuongdandangnhapComponent } from './HuongDanSuDung/huongdandangnhap/huongdandangnhap.component';
import { HuongdandanhsachcsgComponent } from './HuongDanSuDung/huongdandanhsachcsg/huongdandanhsachcsg.component';
import { HuongdantinhcsgComponent } from './HuongDanSuDung/huongdantinhcsg/huongdantinhcsg.component';
import { HuongdanthuthapdulieuComponent } from './HuongDanSuDung/huongdanthuthapdulieu/huongdanthuthapdulieu.component';
import { HuongdantytrongComponent } from './HuongDanSuDung/huongdantytrong/huongdantytrong.component';
import { HuongdanchisogiaComponent } from './HuongDanSuDung/huongdanchisogia/huongdanchisogia.component';
import { HuongdanbaocaoComponent } from './HuongDanSuDung/huongdanbaocao/huongdanbaocao.component';
import { HuongdandanhmucComponent } from './HuongDanSuDung/huongdandanhmuc/huongdandanhmuc.component';
import { ThuviencanhanComponent } from './HuongDanSuDung/thuviencanhan/thuviencanhan.component';
import { ThemmoiComponent } from './HuongDanSuDung/themmoi/themmoi.component';
import {CalendarModule} from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    FilterTrueFalsePipe,
    CapnhapthongtinuserComponent,
    LuaChonCSGComponent,
    LuaChonPhuongThucCSGComponent,
    LuaChonTinhCSGComponent,
    LuaChonDanhMucDungChungCSGComponent,
    UpdatekhuvucLuaChonComponent,
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    ThemmoitreeLuaChonComponent,
    GiavatlieunhancongmayComponent,
    CapnhatgiavatlieunhancongmayComponent,
    CapnhatDanhMucDungChungComponent,
    ApGiaThongBaoComponent,
    TytrongcongtrinhComponent,
    NhaptytrongComponent,
    NhaptytrongKhuVucComponent,
    TytrongVLNCMComponent,
    LuachonVLNCMComponent,
    LuachoncongtrinhComponent,
    TytrongComponent,
    TytrongtheokhuvucComponent,
    GiathietbiComponent,
    TytrongthietbiComponent,
    ThongbaoComponent,
    ThemmoitreeComponent,
    LoginComponent,
    ThongtinuserComponent,
    KhuvucComponent,
    DiaphuongtheokhuvucComponent,
    CongtrinhtheokhuvucComponent,
    BaoCaoVungComponent,
    XaydungcongtrinhComponent,
    ChisogiatheococauchiphiComponent,
    VatlieunhancongmayComponent,
    VatlieunhancongmaykhongchuyeuComponent,
    ChondanhmucComponent,
    CapnhatdanhmucComponent,
    DanhmuctinhComponent,
    DanhmucvungComponent,
    LoaicongtrinhComponent,
    DanhMucDungChungComponent,
    VatlieuCongnhanMayComponent,
    ChiphimuasamComponent,
    UpdatekhuvucComponent,
    ThietbiComponent,
    LuachonthietbiComponent,
    CapnhattytrongthietbiComponent,
    DanhmucuserComponent,
    UpdateuserComponent,
    LuaChonVLCNMCongTrinhComponent,
    HienThiVLNCMComponent,
    ChiPhiVatLieuNhanCongMayComponent,
    ThemMoiDuAnComponent,
    ThemMoiDuAnDanhMucDungChungComponent,
    DanhsachvatlieuComponent,
    DanhmuccapcongtrinhComponent,
    DanhmucnhomcongtrinhComponent,
    CapnhatcapcongtrinhComponent,
    CapnhatnhomcongtrinhComponent,
    CapnhatdanhmucvungComponent,
    TytrongvungComponent,
    ChisogiatheovungComponent,
    CongtrinhtheovungComponent,
    CongboComponent,
    DscongboComponent,
    DoimatkhauComponent,
    DmgiaidoanduanComponent,
    DmgiaidoanduanmodalComponent,
    FilemauComponent,
    HienthicongtrinhdaidienmodalComponent,
    DmloaicongtrinhmodalComponent,
    HuongdandangnhapComponent,
    HuongdandanhsachcsgComponent,
    HuongdantinhcsgComponent,
    HuongdanthuthapdulieuComponent,
    HuongdantytrongComponent,
    HuongdanchisogiaComponent,
    HuongdanbaocaoComponent,
    HuongdandanhmucComponent,
    ThuviencanhanComponent,
    ThemmoiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    HttpClientModule,
    FileUploadModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    AccordionModule,
    TreeTableModule,
    TableModule,   
    CalendarModule,
    CheckboxModule,
    ButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    AuthenticationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    { provide: NgbDateParserFormatter, useClass: FormatdatetimeService },
  ],

  // providers: [
  //   { provide: LocationStrategy, useClass: HashLocationStrategy },  

  //   { provide: NgbDateParserFormatter, useClass: FormatdatetimeService },  
  // ],
  bootstrap: [AppComponent],
  entryComponents: [
    CapnhapthongtinuserComponent,
    ThongbaoComponent,
    ThemmoitreeComponent,
    UpdatekhuvucComponent,
    LuaChonTinhCSGComponent,
    LuaChonDanhMucDungChungCSGComponent,
    CapnhatgiavatlieunhancongmayComponent,
    CapnhatDanhMucDungChungComponent,
    ApGiaThongBaoComponent,
    LuaChonCSGComponent,
    LuaChonPhuongThucCSGComponent,
    UpdatekhuvucLuaChonComponent,
    ThemmoitreeLuaChonComponent,
    NhaptytrongComponent,
    NhaptytrongKhuVucComponent,
    LuachonVLNCMComponent,
    LuachoncongtrinhComponent,
    TytrongComponent,
    ThietbiComponent,
    LuachonthietbiComponent,
    CapnhattytrongthietbiComponent,
    UpdateuserComponent,
    LuaChonVLCNMCongTrinhComponent,
    HienThiVLNCMComponent,
    ChiPhiVatLieuNhanCongMayComponent,
    ThemMoiDuAnComponent,
    ThemMoiDuAnDanhMucDungChungComponent,
    CapnhatcapcongtrinhComponent,
    CapnhatnhomcongtrinhComponent,
    CapnhatdanhmucvungComponent,
    CongboComponent,
    DoimatkhauComponent,
    DmgiaidoanduanmodalComponent,
    HienthicongtrinhdaidienmodalComponent,
    DmloaicongtrinhmodalComponent,
  ]
})
export class AppModule { }
