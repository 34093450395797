import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-doimatkhau',
  templateUrl: './doimatkhau.component.html',
  styleUrls: ['./doimatkhau.component.css']
})
export class DoimatkhauComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  IDdmTinh: any = {};
  IDdmTinh_User: any;
  ListTINH: any = [];
  itemSELeCT: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.ItemPOST = Object.assign({}, this.item);
    this.ItemPOST.PasswordHash = "";
  }

  capnhat() {
    let data: any = {
      "Id": this.type == 'themmoi' ? "" : this.item.Id,
      "HoTen": this.ItemPOST.HoTen !== undefined && this.ItemPOST.HoTen !== "" ? this.ItemPOST.HoTen : "",
      "UserName": this.ItemPOST.UserName !== undefined && this.ItemPOST.UserName !== "" ? this.ItemPOST.UserName : "",
      "PasswordHash": this.ItemPOST.PasswordHash !== undefined && this.ItemPOST.PasswordHash !== "" ? this.ItemPOST.PasswordHash : "",
      "NamGoc": this.ItemPOST.NamGoc !== undefined && this.ItemPOST.NamGoc !== 0 ? this.ItemPOST.NamGoc : 0,
      "listUserTinh": this.ItemPOST.listUserTinh !== undefined && this.ItemPOST.listUserTinh !== 0 ? this.ItemPOST.listUserTinh : 0,
    }
    return data;
  }

  XacNhan(item) {
    this.DanhmucService.KiemTraPass(this.ItemPOST.Password).subscribe(res => {
      if(res == false)
        this.toastr.error("Bạn nhập sai mật khẩu cũ");
      else{
        if(item.PasswordHash == undefined){
          this.toastr.error("Bạn chưa nhập pass");
        }
        else if (item.PasswordHash != item.confirmPasswordHash) {
          this.toastr.error("Xác nhận mật khẩu sai");
        }
        else {
          this.DanhmucService.SetUser(this.capnhat()).subscribe(res => {
            if (res.State === 1) {
              this.toastr.success(res.message);
              this.Onclose();
            }
            else {
              this.toastr.error(res.message);
            }
          });
        }
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
}
