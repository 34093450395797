import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-huongdandanhsachcsg',
  templateUrl: './huongdandanhsachcsg.component.html',
  styleUrls: ['./huongdandanhsachcsg.component.css']
})
export class HuongdandanhsachcsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
