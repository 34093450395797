import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ApGiaThongBaoComponent } from '../apgiathongbao/apgiathongbao.component';
import { DanhmucService } from 'src/app/services/danhmuc.service';

@Component({
  selector: 'app-capnhatgiavatlieunhancongmay',
  templateUrl: './capnhatgiavatlieunhancongmay.component.html',
  styleUrls: ['./capnhatgiavatlieunhancongmay.component.css']
})
export class CapnhatgiavatlieunhancongmayComponent implements OnInit {

  IDParent: any = {};
  ItemPOST: any = {};
  Nam: any = 0;
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  listkhoadulieu: any = [];
  IDThongTinUser : any = 0;
  LoaiDanhMucUpdate : any = "";
  dataitem: any = {};
  dmTinh: any = {};
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private DanhMucService: DanhmucService,

  ) { }

  ngOnInit() {
    this.ItemPOST = Object.assign({}, this.IDParent);
    this.Nam = Object.assign(this.Nam);
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    this.dataitem.Ten = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.DanhMucService.GetdmTinh(this.IDdmTinh).subscribe((res: any) =>{ 
      this.dmTinh = res;
    })
  }

  XacNhan() {
    if(this.ItemPOST.Thang1 == "")
      this.ItemPOST.Thang1 = 0;
    if(this.ItemPOST.Thang2 == "")
      this.ItemPOST.Thang2 = 0;
    if(this.ItemPOST.Thang3 == "")
      this.ItemPOST.Thang3 = 0;
    if(this.ItemPOST.Thang4 == "")
      this.ItemPOST.Thang4 = 0;
    if(this.ItemPOST.Thang5 == "")
      this.ItemPOST.Thang5 = 0;
    if(this.ItemPOST.Thang6 == "")
      this.ItemPOST.Thang6 = 0;
    if(this.ItemPOST.Thang7 == "")
      this.ItemPOST.Thang7 = 0;
    if(this.ItemPOST.Thang8 == "")
      this.ItemPOST.Thang8 = 0;
    if(this.ItemPOST.Thang9 == "")
      this.ItemPOST.Thang9 = 0;
    if(this.ItemPOST.Thang10 == "")
      this.ItemPOST.Thang10 = 0;
    if(this.ItemPOST.Thang11 == "")
      this.ItemPOST.Thang11 = 0;
    if(this.ItemPOST.Thang12 == "")
      this.ItemPOST.Thang12 = 0;
    this.ItemPOST.Nam = this.Nam;
    this.ItemPOST.IDdmKhuVuc = this.IDdmKhuVuc;
    this.ItemPOST.IDdmTinh = this.IDdmTinh;
    this.ItemPOST.IDThongTinUser = this.dataitem.IDThongTinUser;
    this.ApiService.SetGia(this.ItemPOST).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  GetdsApGia(thang) {
    let LoaiDanhMuc : any = this.ItemPOST.LoaiDanhMuc + 1;
    if(this.ItemPOST.LoaiDanhMuc == 3)
      LoaiDanhMuc = 1;
    this.ApiService.GetBangGia(1,'Tất cả',this.dmTinh.IDCongThongTin, LoaiDanhMuc, this.Nam).subscribe(res => {
        const modalRef = this.modalService.open(ApGiaThongBaoComponent, { size: 'fullscreen', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.ListIPOS = Object.assign([], res.Items);
        modalRef.componentInstance.IDdmTinh = this.IDdmTinh;
        modalRef.componentInstance.tkBangGia = '';
        modalRef.componentInstance.dataitem.TotalRow = res.TotalRow;
        modalRef.componentInstance.dataitem.PageIndex =res.PageIndex;// Object.assign([], res.PageIndex);
        modalRef.componentInstance.dataitem.pageSize =res.pageSize;// Object.assign([], res.pageSize);
        modalRef.componentInstance.tenVatLieu = this.ItemPOST.TendmVatLieuNhanCongMay;
        modalRef.componentInstance.LoaiDanhMuc = LoaiDanhMuc;
        modalRef.componentInstance.Nam = this.Nam;
        modalRef.result.then((data) => {
          switch(thang){
            case 1:
              this.ItemPOST.Thang1 = data.data.Gia;
              break;
            case 2:
              this.ItemPOST.Thang2 = data.data.Gia;
              break;
            case 3:
              this.ItemPOST.Thang3 = data.data.Gia;
              break;
            case 4:
              this.ItemPOST.Thang4 = data.data.Gia;
              break;
            case 5:
              this.ItemPOST.Thang5 = data.data.Gia;
              break;
            case 6:
              this.ItemPOST.Thang6 = data.data.Gia;
              break;
            case 7:
              this.ItemPOST.Thang7 = data.data.Gia;
              break;
            case 8:
              this.ItemPOST.Thang8 = data.data.Gia;
              break;
            case 9:
              this.ItemPOST.Thang9 = data.data.Gia;
              break;
            case 10:
              this.ItemPOST.Thang10 = data.data.Gia;
              break;
              case 11:
              this.ItemPOST.Thang11 = data.data.Gia;
              break;
            case 12:
              this.ItemPOST.Thang12 = data.data.Gia;
              break;
          }
        }, (reason) => {
          // không
        });
      });
    // });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  
  ApGiaThongBaoParent() {
    // this.Onclose();
    let LoaiDanhMuc : any = this.ItemPOST.LoaiDanhMuc + 1;
    if(this.ItemPOST.LoaiDanhMuc == 3)
      LoaiDanhMuc = 1;
    this.ApiService.GetBangGia(1,'Tất cả',this.dmTinh.IDCongThongTin, LoaiDanhMuc, this.Nam).subscribe(res => {
        const modalRef = this.modalService.open(ApGiaThongBaoComponent, { size: 'fullscreen', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.ListIPOS = Object.assign([], res.Items);
        modalRef.componentInstance.IDdmTinh = this.IDdmTinh;
        modalRef.componentInstance.Nam = this.Nam;
        modalRef.componentInstance.LoaiDanhMuc = LoaiDanhMuc;
        modalRef.componentInstance.IDdmKhuVuc = this.IDdmKhuVuc;
        modalRef.componentInstance.IDParent = this.ItemPOST.IDdmVatLieuNhanCongMay;
        modalRef.componentInstance.isParent = true;
        // modalRef.componentInstance.tkBangGia = 'Tất cả';
        modalRef.componentInstance.tkBangGia = '';
        modalRef.componentInstance.dataitem.TotalRow = res.TotalRow;
        modalRef.componentInstance.dataitem.PageIndex =res.PageIndex;// Object.assign([], res.PageIndex);
        modalRef.componentInstance.dataitem.pageSize =res.pageSize;// Object.assign([], res.pageSize);
        modalRef.componentInstance.tenVatLieu = this.ItemPOST.TendmVatLieuNhanCongMay;
        modalRef.result.then((data) => {
          this.Onclose();
        }, (reason) => {
          // không
        });
      });
    // });
  }
  reFresh(){
    this.ItemPOST.Thang1 = 0;
    this.ItemPOST.Thang2 = 0;
    this.ItemPOST.Thang3 = 0;
    this.ItemPOST.Thang4 = 0;
    this.ItemPOST.Thang5 = 0;
    this.ItemPOST.Thang6 = 0;
    this.ItemPOST.Thang7 = 0;
    this.ItemPOST.Thang8 = 0;
    this.ItemPOST.Thang9 = 0;
    this.ItemPOST.Thang10 = 0;
    this.ItemPOST.Thang11 = 0;
    this.ItemPOST.Thang12 = 0;
    this.ItemPOST.TyTrong = 0;
  }
}
