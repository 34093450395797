import { Injectable } from '@angular/core';
import { HostService, httpOptions } from './host.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MyService {

  currentUser: any = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));

  checklogin: any = false;

  constructor() {
  }

  public unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.ID] = arrElem;
      mappedArr[arrElem.ID]['children'] = [];
    }
    for (var ID in mappedArr) {
      if (mappedArr.hasOwnProperty(ID)) {
        mappedElem = mappedArr[ID];
        if (mappedElem.IDParent) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);

        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  ghepdateUnix(date) {
    if(date === undefined || date === null)
      return null;
    let newDate = new Date(date.year, date.month - 1, date.day);
    let newDateUnix = moment(newDate).unix();
    return newDateUnix;
  }
  unixToDate(unix:number):{}|null{
    // let time = new Date(unix * 1000);
    // let date = {year: time.getFullYear(),
    //   month: time.getMonth() + 1,
    //   day: time.getDate()
    // }
    // return unix>
    // 0?(date):null;
    if (unix !== undefined && unix !== null && unix !== 0) {
      return new Date(unix * 1000);
  }
}

DateToUnix(date: any): any {
  if (date == null) {
      return 0
  } else {
      return (new Date(date)).getTime() / 1000
  }
}

  taixuong(item) {
    window.open(HostService.taianh + item);
  }

}
