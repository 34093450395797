import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { TytrongComponent } from '././../tytrong-vlncm/NgbModal/tytrong/tytrong.component';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HostService } from 'src/app/services/host.service';
import { LuaChonVLCNMCongTrinhComponent } from './../tytrong-vlncm/NgbModal/luachon-vlncm/luachon-vlncm.component';
import { LuachoncongtrinhComponent } from '../tytrongcongtrinh/NgbModal/luachoncongtrinh/luachoncongtrinh.component';
import { ChiPhiVatLieuNhanCongMayComponent } from './../tytrong-vlncm/NgbModal/chiphivatlieunhancongmay/chiphivatlieunhancongmay.component';
import { ThemMoiDuAnComponent } from '././../tytrong-vlncm/NgbModal/themmoiduan/themmoiduan.component';
import { observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { CapnhatDanhMucDungChungComponent } from '../giavatlieunhancongmay/NgbModal/capnhatdanhmucchung/capnhatdanhmucchung.component';
import { Router } from '@angular/router';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { DmloaicongtrinhmodalComponent } from './dmloaicongtrinhmodal/dmloaicongtrinhmodal.component';
@Component({
  selector: 'app-danhsachvatlieu',
  templateUrl: './danhsachvatlieu.component.html',
  styleUrls: ['./danhsachvatlieu.component.css']
})
export class DanhsachvatlieuComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  ListVUNG: any = [];
  listkhuvuc: any = [];
  listnam: any = [];

  dataitem: any = {};

  files: TreeNode[];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;
  uploader: FileUploader;
  loaihienthi: any = [];
  listcapcongtrinh: any = [];
  listnhomcongtrinh: any = [];
  IDdmLoaiCongTrinh: any = 0;  
  NamTinh: any = 0;  
  listdonViTien: any = [];
  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit() {    
    this.uploader = new FileUploader({
      url: `${HostService.serverUrl}FileUploader/Post`,
      headers: [{ name: 'Accept', value: 'application/json' }],
      autoUpload: true,
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = true;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
      this.dataitem.Ten = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.NamTinh = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    this.GetListdmCapCongTrinh();
    this.GetListdmNhomCongTrinh();
    this.getLoaiHienThi();
    this.GetListdmTinh();
    this.GetListDonViTien();
  }

  GetListDonViTien() {
    this.listdonViTien = [];
    let data2: any={
      "ma": 0,
      "ten":"Đồng"
    }
    let data: any={
      "ma": 1,
      "ten":"Nghìn đồng"
    }
    let data1: any={
      "ma": 2,
      "ten":"Triệu đồng"
    }
    this.listdonViTien.push(data2)
    this.listdonViTien.push(data)
    this.listdonViTien.push(data1)
    this.dataitem.DonViTien = this.listdonViTien[2].ma
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length === 0){
        this.GetListTyTrongPhanXayDung();
      }
      else{
        this.dataitem.IDdmTinh = res[0].ID;      
        this.GetListdmKhuVuc(this.dataitem.IDdmTinh, this.dataitem.IDThongTinUser);
      }
    });
  }
  onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    this.spinner.show();
    let res = JSON.parse(response);
    res[0].Id = 0;
    this.DanhmucService.ImportdmCongTrinhDienHinh(res[0].Name, this.dataitem.IDThongTinUser,this.dataitem.Nam, this.dataitem.IDdmTinh, this.dataitem.IDdmKhuVuc).subscribe(res => {
      if (res.State === 1) {        
        this.toastr.success(res.message);
        this.GetListTyTrongPhanXayDung();
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();
    });
  };

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  TinhTyTrongCongTrinh() {
    this.spinner.show();
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.TinhTyTrongCongTrinh(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.router.navigate(['/ThongTinChung/TyTrongVLNCM']);   
      }
      else {
        this.toastr.error(res.message);
      }
      this.spinner.hide();

    });
  }
  
  GetListdmCapCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmCapCongTrinh(data).subscribe(res => {
      this.listcapcongtrinh = res;
    });
  }

  GetListdmNhomCongTrinh() {
    let data: any = {
      CurrentPage: 0,
      PageSize: 0,
      sFilter: "",
    };
    this.DanhmucService.GetListdmNhomCongTrinh(data).subscribe(res => {
      this.listnhomcongtrinh = res;
    });
  }

  GetListdmKhuVuc(IDdmTinh, IDThongTinUser) {
    this.dataitem.IDdmKhuVuc = undefined;
    this.listkhuvuc = [];
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.listkhuvuc = res
      this.dataitem.IDdmKhuVuc = res[0].ID;
      this.GetListTyTrongPhanXayDung();
    });
  }

  GetListTyTrongPhanXayDung() {
    this.SoCotCon = 0;
    let data: any = {      
      IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
      Nam: this.dataitem.Nam,
      LoaiHienThi: this.dataitem.LoaiHienThi,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetHeaderChiPhiVatLieuNhanCongMay(data).subscribe(res => {
      this.colparen = res.filter(obj => {
        if (obj.SoCotCon > 0) {
          this.SoCotCon += obj.SoCotCon;
          return obj;
        }
      });
      this.colschidren = res.filter(obj => {
        if (obj.SoCotCon == 0) {
          return obj;
        }
      });
      this.ApiService.GetListTyTrongPhanXayDung(data).subscribe(res => {
        if(this.dataitem.DonViTien == 1){
          res.forEach(element => {
         this.dequyGia(element, 1000)
       });
       }
       else if(this.dataitem.DonViTien == 2){
         res.forEach(element => {
         this.dequyGia(element, 1000000)
       });
       }
        this.files = res;
        this.files.filter(obj => {
          if (this.IDdmLoaiCongTrinh == 0) {
            // this.TimIDdmLoaiCongTrinh(obj);
          }
        });
      });
    });
  }

  chonloai() {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetdsLoaiCongTrinh(data).subscribe(res => {
      let data: any = {
        IDdmTinh: this.dataitem.IDdmTinh,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc,  
        IDThongTinUser: this.dataitem.IDThongTinUser,    
      };
      this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(restytrongcongtrinh => {
        const modalRef = this.modalService.open(LuachoncongtrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res.items);
        modalRef.componentInstance.Listtree = Object.assign([], restytrongcongtrinh);
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.componentInstance.Nam = this.dataitem.Nam;
        modalRef.result.then((data) => {
          this.GetListTyTrongPhanXayDung();
        }, (reason) => {
          // không
        });
      });
    });
  }

  getLoaiHienThi() {
    this.loaihienthi = [
      {
        Ma: 0,
        Ten: 'Tỷ trọng',
      },
      {
        Ma: 1,
        Ten: 'Danh sách vật liệu',
      },
    ];
    this.dataitem.LoaiHienThi = this.loaihienthi[1].Ma;
  }

  chonthoigian() {
    this.GetListTyTrongPhanXayDung();
  }

  nhaptytrong(item, tenRow, check) {
    if (!check) {
      const modalRef = this.modalService.open(TytrongComponent, { backdrop: 'static', scrollable: true });
      modalRef.componentInstance.item = Object.assign({}, item);
      modalRef.componentInstance.tenRow = tenRow;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.result.then((data) => {
        this.GetListTyTrongPhanXayDung();
      }, (reason) => {
        // không
      });
    }
  }

  GetdsVatLieuCongTrinh(item, tenRow, check) {
    let data: any = {
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: moment().year(),
      IDdmCongTrinh: item.IDdmLoaiCongTrinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetListVatLieuTheoCongTrinh(data).subscribe(res => {
      this.ApiService.GetListdsVatLieuCongNhanMayTheoKhuVuc(data).subscribe(resListdmVatLieuNhanCongMay => {
        const modalRef = this.modalService.open(LuaChonVLCNMCongTrinhComponent, { size: 'lg', backdrop: 'static', scrollable: true });
        modalRef.componentInstance.checkListtree = Object.assign([], res);
        modalRef.componentInstance.Listtree = Object.assign([], resListdmVatLieuNhanCongMay);
        modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
        modalRef.componentInstance.IDdsLoaiCongTrinh = item.ID; this.dataitem.IDdmTinh
        modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
        modalRef.result.then((data) => {
          this.GetListTyTrongPhanXayDung();
        }, (reason) => {
          // không
        });
      });
    });
  }

  GetChiPhiVatLieuNhanCongMay(item, itemRow, check) {
    // if(item.Level >3){
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      Nam: moment().year(),
      IDdmCongTrinh: item.IDdmLoaiCongTrinh,
      IDdmVatLieuNhanCongMay: itemRow.IDdmVatLieuNhanCongMay,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.ApiService.GetChiPhiVatLieuNhanCongMay(data).subscribe(res => {
      const modalRef = this.modalService.open(ChiPhiVatLieuNhanCongMayComponent, { size: 'lg', backdrop: 'static', scrollable: true });
      modalRef.componentInstance.item = Object.assign([], res);
      modalRef.componentInstance.itemRow = Object.assign([], itemRow);
      modalRef.result.then((data) => {
        this.GetListTyTrongPhanXayDung();
      }, (reason) => {
        // không
      });
    });
    // }
  }

  ThemMoiDuAn(rowData) {
    let objloaicongtrinh: any = {
      IDdmLoaiCongTrinh: rowData.IDdmLoaiCongTrinh,
      TendmLoaiCongTrinh: rowData.TendmLoaiCongTrinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    }
    
    const modalRef = this.modalService.open(ThemMoiDuAnComponent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
    modalRef.componentInstance.IDParent = rowData.IDdmLoaiCongTrinh;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
    modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
    modalRef.componentInstance.Nam = this.dataitem.Nam;
    modalRef.componentInstance.listcapcongtrinh = this.listcapcongtrinh;
    modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;
    modalRef.componentInstance.objloaicongtrinh = objloaicongtrinh;
    modalRef.componentInstance.IDdsLoaiCongTrinh = 0;
    modalRef.result.then((data) => {
      this.GetListTyTrongPhanXayDung();
    }, (reason) => {
      // không
    });
  }
  XoaDuAn(ID) {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedsLoaiCongTrinh(ID).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListTyTrongPhanXayDung();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }

  CapNhatDuAn(rowData) {    
    this.ApiService.GetCongTrinhDienHinh(rowData.ID, this.dataitem.IDdmKhuVuc || 0, this.dataitem.IDThongTinUser, this.dataitem.Nam).subscribe(res => {
      let objloaicongtrinh: any = {
        IDdmLoaiCongTrinh: rowData.IDParentdmLoaiCongTrinh,
        TendmLoaiCongTrinh: res.ThongTinChung.TenLoaiCongTrinh,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      }
    res.listChiPhiVatLieu.forEach(element => {
      element.GiaTri = element.GiaTri.toString().replace('.',',');
    });
    res.listChiPhiNhanCong.forEach(element => {
      element.GiaTri = element.GiaTri.toString().replace('.',',');
    });
    res.listChiPhiMay.forEach(element => {
      element.GiaTri = element.GiaTri.toString().replace('.',',');
    });
    res.listChiPhiMuaSam.forEach(element => {
      element.GiaTri = element.GiaTri.toString().replace('.',',');
    });
      const modalRef = this.modalService.open(ThemMoiDuAnComponent, { size: 'xl', backdrop: 'static' });
      modalRef.componentInstance.type = "capnhat";
      modalRef.componentInstance.LoaidanhMuc = "Loaicongtrinh";
      modalRef.componentInstance.IDParent = rowData.IDParentdmLoaiCongTrinh;
      modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
      modalRef.componentInstance.IDdmKhuVuc = this.dataitem.IDdmKhuVuc;
      modalRef.componentInstance.Nam = this.dataitem.Nam;
      modalRef.componentInstance.listcapcongtrinh = this.listcapcongtrinh;
      modalRef.componentInstance.listnhomcongtrinh = this.listnhomcongtrinh;
      modalRef.componentInstance.objloaicongtrinh = objloaicongtrinh;
      modalRef.componentInstance.IDdsLoaiCongTrinh = rowData.ID;
      modalRef.componentInstance.item = Object.assign({}, res);
      modalRef.result.then((data) => {
        this.GetListTyTrongPhanXayDung();
      }, (reason) => {
        // không
      });
    });
  }

  xuatfile() {
    let data: any =
    {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDdmKhuVuc: this.dataitem.IDdmKhuVuc,
      // IDdmCongTrinh: this.IDdmLoaiCongTrinh,
      Nam: this.dataitem.Nam,
      IDThongTinUser: this.dataitem.IDThongTinUser,
    };
    this.DanhmucService.ExportdmCongTrinhDienHinh(data).subscribe(res => {
      if (res.TenFile !== undefined) {        
        this.MyService.taixuong(res.TenFile);
      }
      else {
        this.toastr.error(res.message);
      } 
    });
  }
  saveDanhMucdungChung(){
    const modalRef = this.modalService.open(CapnhatDanhMucDungChungComponent, { backdrop: 'static' });
    modalRef.componentInstance.LoaiDanhMuc = 'LoaiCongTrinh';
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;
    modalRef.result.then((data) => {
    }, (reason) => {
    });
  }
  dequyGia(element, giatri){
    element.data.listChiPhiVatLieuNhanCongMay.forEach(element => {
      element.GiaTri = element.GiaTri/ giatri
    });
    if(element.children.length > 0){
      element.children.forEach(i => {
        this.dequyGia(i, giatri)
      });
    }
  }
  update(item){
    let modalRef = this.modalService.open(DmloaicongtrinhmodalComponent, {
      // size: 'fullscreen',
      backdrop: 'static'
    })
    modalRef.componentInstance.opt = 'edit';
    modalRef.componentInstance.ItemPOST = JSON.parse(JSON.stringify(item));
    modalRef.result.then((res: any) => {
    })
  }
  CheckTinhTyTrong(){
    let data: any = {
      "IDdmTinh": this.dataitem.IDdmTinh,
      "IDdmKhuVuc": this.dataitem.IDdmKhuVuc,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    };
    this.ApiService.CheckTinhTyTrong(data).subscribe(res => {
      if (res.State !== 1) {
        this.toastr.error(res.message);
      }
    });
  }
}
