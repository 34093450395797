import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.css']
})
export class UpdateuserComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  IDdmTinh: any = {};
  IDdmTinh_User: any;
  ListTINH: any = [];
  itemSELeCT: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.IDdmTinh_User = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
    this.GetListdmTinh();
    if (this.type == 'themmoi') {
      this.ItemPOST.listUserTinh = [];
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
    }
    // this.ItemPOST.IDdmTinh = JSON.parse(localStorage.getItem('currentUser')).IDdmTinh;
  }

  capnhat() {
    let data: any = {
      "Id": this.type == 'themmoi' ? "" : this.item.Id,
      "HoTen": this.ItemPOST.HoTen !== undefined && this.ItemPOST.HoTen !== "" ? this.ItemPOST.HoTen : "",
      "UserName": this.ItemPOST.UserName !== undefined && this.ItemPOST.UserName !== "" ? this.ItemPOST.UserName : "",
      "PasswordHash": this.ItemPOST.PasswordHash !== undefined && this.ItemPOST.PasswordHash !== "" ? this.ItemPOST.PasswordHash : "",
      "NamGoc": this.ItemPOST.NamGoc !== undefined && this.ItemPOST.NamGoc !== 0 ? this.ItemPOST.NamGoc : 0,
      "listUserTinh": this.ItemPOST.listUserTinh !== undefined && this.ItemPOST.listUserTinh !== 0 ? this.ItemPOST.listUserTinh : 0,
    }
    return data;
  }

  XacNhan(item) {
    if(item.UserName == undefined){
      this.toastr.error("Bạn chưa nhập username");
    }
    else if(item.PasswordHash == undefined){
      this.toastr.error("Bạn chưa nhập pass");
    }
    else if (item.PasswordHash != item.confirmPasswordHash && this.type == 'themmoi') {
      this.toastr.error("Xác nhận mật khẩu sai");
    }
    else {
      this.DanhmucService.SetUser(this.capnhat()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  GetListdmTinh() {
    let data: any = [];
    this.DanhmucService.GetListdmTinh("").subscribe(res => {
      this.ListTINH = res;
      // if(this.IDdmTinh_User == 0){
      //   this.ListTINH = res;
      // }
      // else{
      //   data = res.filter(item=>item.ID==this.IDdmTinh_User);
      //   this.ListTINH = data;
      // }
      // this.itemSELeCT = this.ListTINH[0];
    });
  }
}
