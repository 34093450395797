import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../services/api.service';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from './../../scriptAll/TreeNode';
import * as moment from 'moment';
import { LodatabaocaoComponent } from 'src/app/NgbModalAll/lodatabaocao/lodatabaocao.component';
import { BaocaoService } from 'src/app/services/baocao.service';

@Component({
  selector: 'app-chisogiatheococauchiphi',
  templateUrl: './chisogiatheococauchiphi.component.html',
  styleUrls: ['./chisogiatheococauchiphi.component.css']
})
export class ChisogiatheococauchiphiComponent implements OnInit {

  TreeVLNCM: TreeNode[];

  listtinh: any = [];
  listnam: any = [];
  ListVUNG: any = [];
  CheckUserAD: boolean = false;
  CheckUserToanQuoc: boolean = false;

  dataitem: any = {};

  files: any = [];
  colschidren: any[];
  colparen: any[];

  SoCotCon: any = 0;

  listquy: any = [];
  listthang: any = [];
  listthoigian: any = [];
  listTu: any = [];
  listDen: any = [];

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
    private BaocaoService: BaocaoService,
  ) { }

  ngOnInit() {
    // this.dataitem.IDdmTinh = JSON.parse(localStorage.getItem('IDdmTinhUser')).IDdmTinh;
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    this.CheckQuyenToanQuoc();
    this.getthoigian();
    this.getthang();
    this.getquy();
    this.GetListdmVung();
    this.GetListdmTinh();
    this.chonthoigian();
  }


  getthang() {
    this.dataitem.Thang = moment().month();
    for (let i = 1; i <= 12; i++) {
      let data: any = { Ma: i, Ten: `Tháng ${i}` }
      this.listthang.push(data);
    }
  }

  getquy() {
    this.listquy = [
      {
        Ma: 1,
        Ten: 'Quý 1',
      },
      {
        Ma: 2,
        Ten: 'Quý 2',
      },
      {
        Ma: 3,
        Ten: 'Quý 3',
      },
      {
        Ma: 4,
        Ten: 'Quý 4',
      },
    ]
  }

  getthoigian() {
    this.listthoigian = [
      {
        Ma: 0,
        Ten: 'Năm',
      },
      {
        Ma: 1,
        Ten: 'Quý',
      },
      {
        Ma: 2,
        Ten: 'Tháng',
      },
    ];
    this.dataitem.ThoiGian = this.listthoigian[0].Ma;
  }

  chonthoigian() {
    if (this.dataitem.ThoiGian == 0) {
      this.dataitem.ThoiGian_Tu = this.dataitem.Nam;
          this.dataitem.ThoiGian_Den = this.dataitem.Nam;
    }
    else if (this.dataitem.ThoiGian == 1) {
      this.listTu = this.listquy;
      this.listDen = this.listquy;
      this.dataitem.ThoiGian_Tu = this.listquy[0].Ma;
      this.dataitem.ThoiGian_Den = this.listquy[0].Ma;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
    else if (this.dataitem.ThoiGian == 2) {
      this.listTu = this.listthang;
      this.listDen = this.listthang;
      this.dataitem.ThoiGian_Tu = this.listthang[0].Ma;
      this.dataitem.ThoiGian_Den = this.listthang[0].Ma;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    }
  }

  CheckUser() {
    this.DanhmucService.CheckUser().subscribe(res => {
      this.CheckUserAD = res;
    });
  }
  GetListdmTinh() {
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if (res.length > 0) {
        this.dataitem.IDdmTinh = res[0].ID;
      }
      this.BaoCaoCSGCoCauChiPhi();
    });
  }
  CheckQuyenToanQuoc() {
    this.DanhmucService.CheckQuyenToanQuoc().subscribe(res => {
      this.CheckUserToanQuoc = res;
    });
  }

  GetListdmVung() {
    this.dataitem.IDdmVung = undefined;
    this.ListVUNG = [];
    let data: any = {};
    this.DanhmucService.GetListdmVung().subscribe(res => {
      this.ListVUNG = res;
      if (res.length > 0 && this.CheckUserToanQuoc == true) {
        this.dataitem.IDdmVung = this.ListVUNG[0].ID;
      } 
    });
  }


  BaoCaoCSGCoCauChiPhi() {
    this.dataitem.ThoiGian_Den = this.dataitem.ThoiGian_Tu;
    if (this.dataitem.ThoiGian_Tu <= this.dataitem.ThoiGian_Den && this.dataitem.ThoiGian!=0) {
      this.SoCotCon = 0;
      let data: any =
      {
        Tu: this.dataitem.ThoiGian_Tu,
        Den: this.dataitem.ThoiGian_Tu,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
        IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
        IDdmVung: this.dataitem.IDdmVung != undefined ? this.dataitem.IDdmVung : 0,
        NamGoc: this.dataitem.NamGoc,
        LoaiThoiGian: this.dataitem.ThoiGian,
        Nam: this.dataitem.Nam,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      };
      this.BaocaoService.HeaderBaoCaoCSGCoCauChiPhi(data).subscribe(res => {
        this.colparen = res.filter(obj => {
          if (obj.SoCotCon > 0) {
            this.SoCotCon += obj.SoCotCon;
            return obj;
          }
        });
        this.colschidren = res.filter(obj => {
          if (obj.SoCotCon == 0) {
            return obj;
          }
        });
        this.BaocaoService.BaoCaoCSGCoCauChiPhi(data).subscribe(res => {
          this.files = res;
        });
      });
    }
    else if(this.dataitem.ThoiGian==0) {
      this.SoCotCon = 0;
      let data: any =
      {
        Tu: this.dataitem.Nam,
        Den: this.dataitem.Nam,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
        IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
        IDdmVung: this.dataitem.IDdmVung != undefined ? this.dataitem.IDdmVung : 0,
        NamGoc: this.dataitem.NamGoc,
        LoaiThoiGian: this.dataitem.ThoiGian,
        Nam: this.dataitem.Nam,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      };
      this.BaocaoService.HeaderBaoCaoCSGCoCauChiPhi(data).subscribe(res => {
        this.colparen = res.filter(obj => {
          if (obj.SoCotCon > 0) {
            this.SoCotCon += obj.SoCotCon;
            return obj;
          }
        });
        this.colschidren = res.filter(obj => {
          if (obj.SoCotCon == 0) {
            return obj;
          }
        });
        this.BaocaoService.BaoCaoCSGCoCauChiPhi(data).subscribe(res => {
          this.files = res;
        });
      });
    }
    else {
      this.toastr.error("Yêu cầu thời gian bắt đầu phải nhỏ hơn thời gian hết thúc");
    }

    // let data: any = {
    //   "Nam_from": this.dataitem.ThoiGian_Tu,
    //   "Nam_to": this.dataitem.ThoiGian_Den,
    //   "IdDmKhuVuc": this.dataitem.IDdmKhuVuc,
    //   // "NamGoc": NamGoc,
    //   "eTime": this.dataitem.ThoiGian,
    // }
    // this.ApiService.GetListTyTrongPhanXayDung(data).subscribe(res => {
    //   this.files = res;
    // });
  }

  xuatfile() {
    let data: any =
      {
        Tu: this.dataitem.ThoiGian_Tu,
        Den: this.dataitem.ThoiGian_Tu,
        IDdmKhuVuc: this.dataitem.IDdmKhuVuc != undefined ? this.dataitem.IDdmKhuVuc : 0,
        IDdmTinh: this.dataitem.IDdmTinh != undefined ? this.dataitem.IDdmTinh : 0,
        IDdmVung: this.dataitem.IDdmVung != undefined ? this.dataitem.IDdmVung : 0,
        NamGoc: this.dataitem.NamGoc,
        LoaiThoiGian: this.dataitem.ThoiGian,
        Nam: this.dataitem.Nam,
        IDThongTinUser: this.dataitem.IDThongTinUser,
      };
    this.BaocaoService.ExportBaoCaoCSGCoCauChiPhi(data).subscribe(res => {
      this.MyService.taixuong(res.TenFile);
    });
  }

}
