import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { CapnhatnhomcongtrinhComponent } from './NgbModal/capnhatnhomcongtrinh/capnhatnhomcongtrinh.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-danhmucnhomcongtrinh',
  templateUrl: './danhmucnhomcongtrinh.component.html',
  styleUrls: ['./danhmucnhomcongtrinh.component.css']
})
export class DanhmucnhomcongtrinhComponent implements OnInit {

  objloaddata: any = {};

  dataitem: any = {};

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {    
    this.dataitem.items = [];
    this.dataitem.paging = {};
    this.dataitem.paging.TotalItem = 0;
    this.refresh();
  }

  refresh() {
    this.objloaddata = {
      CurrentPage: 1,
      PageSize: 15,
      sFilter: "",
    };
    this.loaddata();
  }

  loaddata() {
    this.DanhmucService.GetListdmNhomCongTrinh(this.objloaddata).subscribe(res => {
      this.dataitem = res;
    });
  }

  themmoi() {
    const modalRef = this.modalService.open(CapnhatnhomcongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';  
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  capnhat(item) {
    const modalRef = this.modalService.open(CapnhatnhomcongtrinhComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.loaddata();
    }, (reason) => {
      // không
    });
  }

  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmNhomCongTrinh(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.loaddata();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }

}
