import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MyService } from './../../../../services/my.service';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';

@Component({
  selector: 'app-hienthi-vlncm',
  templateUrl: './hienthi-vlncm.component.html',
  styleUrls: ['./hienthi-vlncm.component.css']
})
export class HienThiVLNCMComponent implements OnInit {
  ListtreeNew: any = [];
  Listtree: any = [];
  ListBanDau: any = [];
  IDdmDungChung: any = 0;
  dataitem: any = {};
  dataVatLieu: any = [];
  dataNhanCong: any = [];
  dataMayThiCong: any = [];
  dataMuaSam: any = [];
  LoaiDanhMucDungChung: any = "";
  isCombobox: any = false;
  listDanhMucDungChung: any  = [];
  listDanhMucDungChungCopy: any  = [];
  Nam: any  = 0;
  IDdmKhuVuc: any  = 0;
  IDThongTinUser: any = 0;
  isNotTimKiem: any= false;
  isCongTrinhTrenCuc: any = 0;
  filter: any = {}
  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.isCongTrinhTrenCuc = "0";

    this.listDanhMucDungChungCopy = JSON.parse(JSON.stringify(this.listDanhMucDungChung));
    this.dataitem.IDdmDungChung = this.IDdmDungChung;
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    this.ListBanDau = this.Listtree;
    this.Listtree = this.unflatten(this.Listtree);
    if(this.LoaiDanhMucDungChung == 'LoaiVatLieuNhanCongMay'){
      this.Listtree.forEach(element => {
        if(element.Loai == 0)
        {
          this.dataVatLieu.push(element)
        }
        else if(element.Loai == 1)
        {
          this.dataNhanCong.push(element)
        }
        else if(element.Loai == 2)
        {
          this.dataMayThiCong.push(element)
        }
        else if(element.Loai == 3)
        {
          this.dataMuaSam.push(element)
        }
      });
    }
    this.dsLoaiVatLieuNew();
  }
  
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
      for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.IDLoaiDanhMuc] = arrElem;
        mappedArr[arrElem.IDLoaiDanhMuc]['children'] = [];
      }
      for (var IDLoaiDanhMuc in mappedArr) {
        if (mappedArr.hasOwnProperty(IDLoaiDanhMuc)) {
          mappedElem = mappedArr[IDLoaiDanhMuc];
          if (mappedElem.IDParent) {
            mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);
  
          }
          else {
            tree.push(mappedElem);
          }
        }
      }
      return tree;
  }
  
  LuaChonDanhMuc(){
    this.Listtree = [];
    this.ListBanDau = [];
    this.DanhmucService.GetListdsDungChung(this.dataitem.IDdmDungChung).subscribe(res => {
      this.Listtree = res
      if(this.LoaiDanhMucDungChung != 'CongTrinhDienHinh'){
        this.ListBanDau = this.Listtree;
        this.Listtree.filter(obj => {
          obj.collspan = true;
          obj.check = false;
          obj.select = false;
        });
        this.Listtree = this.unflatten(this.Listtree);
      }
      
      if(this.LoaiDanhMucDungChung == 'LoaiVatLieuNhanCongMay'){
        this.dataVatLieu= [];
        this.dataNhanCong= [];
        this.dataMayThiCong= [];
        this.dataMuaSam= [];
        this.Listtree.forEach(element => {
          if(element.Loai == 0)
          {
            this.dataVatLieu.push(element)
          }
          else if(element.Loai == 1)
          {
            this.dataNhanCong.push(element)
          }
          else if(element.Loai == 2)
          {
            this.dataMayThiCong.push(element)
          }
          else if(element.Loai == 3)
          {
            this.dataMuaSam.push(element)
          }
        });
      }
      });
      this.dsLoaiVatLieuNew();
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  dsLoaiVatLieuNew(){
    this.ListtreeNew = [];
    this.DanhmucService.GetListdsDungChungLoaiVatLieu(this.dataitem.IDdmDungChung).subscribe(res => {
      this.ListtreeNew = res
      this.ListtreeNew.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      // this.ListBanDau = this.Listtree;
  })
}
  XacNhan(){
    if(this.LoaiDanhMucDungChung == 'LoaiVatLieuNhanCongMay'){
      this.Listtree = this.ListtreeNew;
      this.ListBanDau = this.ListtreeNew;
    }
    let data: any ={
      IDdmDungChung: this.dataitem.IDdmDungChung,
      listTree: this.Listtree,
      listBanDau: this.ListBanDau
    }
    this.activeModal.close(
      {data:data}
    );
  }
  XacNhanCongTrinhDienHinh(){
    if(parseInt(this.isCongTrinhTrenCuc)=== 0){
      this.DanhmucService.GetdsDungChungCongTrinhDienHinh(this.dataitem.IDdmDungChung, this.IDThongTinUser, this.Nam, this.IDdmKhuVuc || 0).subscribe(res => {
        if(res.MaKhongCo.length > 0 ){
          const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
          modalRef.componentInstance.thongbao = "Không tìm thấy tên " + res.MaKhongCo;
          modalRef.result.then((any) => {
          }, (reason) => {
          });
        }
        this.activeModal.close(
          {data:res}
        );
      });
    }
    else if(parseInt(this.isCongTrinhTrenCuc) === 1){
      this.DanhmucService.GetCongTrinhDienHinhTrenCuc(this.dataitem.IDdmDungChung).subscribe(res => {
        this.activeModal.close(
          {data:res}
        );
      });
    }
  }
  CongTrinhTrenCuc(event){
    console.log(event)
    this.dataitem.IDdmDungChung  = "";
      this.DanhmucService.GetListDuAnCongTrinh(this.filter.IddmTinh, this.filter.IddmLoaiCongTrinh).subscribe(res => {
        let datapush = [];
        res.forEach(element => {
          let data : any = {
            ID: element.IdDuAn,
            Ten: element.TenDuAn
          }
          datapush.push(data)
        });
        this.listDanhMucDungChung = datapush;
    });
    
  }
  CongTrinhCaNhan(event){
    console.log(event)
    this.dataitem.IDdmDungChung  = "";
    this.listDanhMucDungChung = this.listDanhMucDungChungCopy;
  }
}
