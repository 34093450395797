import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { AuthGuard } from './_guard/auth.guard';
// import { ChondanhmucComponent } from './danhmuc/chondanhmuc/chondanhmuc/chondanhmuc.component';
// import { CapnhatdanhmucComponent } from './danhmuc/chondanhmuc/capnhatdanhmuc/capnhatdanhmuc.component';
// import { DanhmuctinhComponent } from './danhmuc/danhmuctinh/danhmuctinh.component';
// import { LoaicongtrinhComponent } from './danhmuc/loaicongtrinh/loaicongtrinh.component';
// import { VatlieuCongnhanMayComponent } from './danhmuc/vatlieu-congnhan-may/vatlieu-congnhan-may.component';
import { GiavatlieunhancongmayComponent } from './thongtinchung/giavatlieunhancongmay/giavatlieunhancongmay.component';
import { TytrongcongtrinhComponent } from './thongtinchung/tytrongcongtrinh/tytrongcongtrinh.component';
import { TytrongVLNCMComponent } from './thongtinchung/tytrong-vlncm/tytrong-vlncm.component';
import { TytrongtheokhuvucComponent } from './thongtinchung/tytrongtheokhuvuc/tytrongtheokhuvuc.component';
import { XaydungcongtrinhComponent } from './baocao/xaydungcongtrinh/xaydungcongtrinh.component';
import { ChisogiatheococauchiphiComponent } from './baocao/chisogiatheococauchiphi/chisogiatheococauchiphi.component';
import { VatlieunhancongmayComponent } from './baocao/vatlieunhancongmay/vatlieunhancongmay.component';
import { VatlieunhancongmaykhongchuyeuComponent } from './baocao/vatlieunhancongmaykhongchuyeu/vatlieunhancongmaykhongchuyeu.component';
import { KhuvucComponent } from './chisogia/khuvuc/khuvuc.component';
import { DiaphuongtheokhuvucComponent } from './chisogia/diaphuongtheokhuvuc/diaphuongtheokhuvuc.component';
import { CongtrinhtheokhuvucComponent } from './chisogia/congtrinhtheokhuvuc/congtrinhtheokhuvuc.component';
import { GiathietbiComponent } from './thongtinchung/giathietbi/giathietbi.component';
import { TytrongthietbiComponent } from './thongtinchung/tytrongthietbi/tytrongthietbi.component';
import { CapnhatdanhmucComponent } from './danhmuc/chondanhmuc/capnhatdanhmuc/capnhatdanhmuc.component';
import { DanhmuctinhComponent } from './danhmuc/danhmuctinh/danhmuctinh.component';
import { LoaicongtrinhComponent } from './danhmuc/loaicongtrinh/loaicongtrinh.component';
import { VatlieuCongnhanMayComponent } from './danhmuc/vatlieu-congnhan-may/vatlieu-congnhan-may.component';
import { DanhmucuserComponent } from './danhmuc/danhmucuser/danhmucuser.component';
import { UpdateuserComponent } from './danhmuc/danhmucuser/NgbModal/updateuser/updateuser.component';
import { DanhsachvatlieuComponent } from './thongtinchung/danhsachvatlieu/danhsachvatlieu.component';
import { DanhmucvungComponent } from './danhmuc/danhmucvung/danhmucvung.component';
import { DanhmuccapcongtrinhComponent } from './danhmuc/danhmuccapcongtrinh/danhmuccapcongtrinh.component';
import { DanhmucnhomcongtrinhComponent } from './danhmuc/danhmucnhomcongtrinh/danhmucnhomcongtrinh.component';
import { TytrongvungComponent } from './thongtinchung/tytrongvung/tytrongvung.component';
import { ChisogiatheovungComponent } from './chisogia/chisogiatheovung/chisogiatheovung.component';
import { CongtrinhtheovungComponent } from './chisogia/congtrinhtheovung/congtrinhtheovung.component';
import { DscongboComponent } from './chisogia/dscongbo/dscongbo.component';
import { ThongtinuserComponent } from './thongtinuser/thongtinuser/thongtinuser.component';
import { ChiphimuasamComponent } from './danhmuc/chiphimuasam/chiphimuasam.component';
import { DanhMucDungChungComponent } from './danhmuc/danhmucdungchung/danhmucdungchung.component';
import { BaoCaoVungComponent } from './baocao/baocaovung/baocaovung.component';
import { DmgiaidoanduanComponent } from './danhmuc/dmgiaidoanduan/dmgiaidoanduan.component';
import { FilemauComponent } from './danhmuc/filemau/filemau.component';
import { HuongdandangnhapComponent } from './HuongDanSuDung/huongdandangnhap/huongdandangnhap.component';
import { HuongdandanhsachcsgComponent } from './HuongDanSuDung/huongdandanhsachcsg/huongdandanhsachcsg.component';
import { HuongdantinhcsgComponent } from './HuongDanSuDung/huongdantinhcsg/huongdantinhcsg.component';
import { HuongdanthuthapdulieuComponent } from './HuongDanSuDung/huongdanthuthapdulieu/huongdanthuthapdulieu.component';
import { HuongdantytrongComponent } from './HuongDanSuDung/huongdantytrong/huongdantytrong.component';
import { HuongdanchisogiaComponent } from './HuongDanSuDung/huongdanchisogia/huongdanchisogia.component';
import { HuongdanbaocaoComponent } from './HuongDanSuDung/huongdanbaocao/huongdanbaocao.component';
import { HuongdandanhmucComponent } from './HuongDanSuDung/huongdandanhmuc/huongdandanhmuc.component';
import { ThuviencanhanComponent } from './HuongDanSuDung/thuviencanhan/thuviencanhan.component';
import { ThemmoiComponent } from './HuongDanSuDung/themmoi/themmoi.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/Login",
    pathMatch: "full",
  },
  {
    path: "Login",
    component: LoginComponent,
    // canActivate: [!AuthGuard],
  }, 
  {
    path: "ThongTinChung",
    children: [
      {
        path: "GiaVatLieuNhanCongMay",
        component: GiavatlieunhancongmayComponent,
        // canActivate: [AuthGuard],
      },
      {
        path: "TyTrongCongTrinh",
        // canActivate: [AuthGuard],
        component: TytrongcongtrinhComponent,
      },
      {
        path: "CongTrinhDienHinh",
        // canActivate: [AuthGuard],
        component: DanhsachvatlieuComponent,
      },      
      {
        path: "TyTrongVLNCM",
        // canActivate: [AuthGuard],
        component: TytrongVLNCMComponent,
      },
      {
        path: "TyTrongTheoKhuVuc",
        // canActivate: [AuthGuard],
        component: TytrongtheokhuvucComponent,
      },
      {
        path: "GiaThietBi",
        // canActivate: [AuthGuard],
        component: GiathietbiComponent,
      },
      {
        path: "TyTrongThietBi",
        // canActivate: [AuthGuard],
        component: TytrongthietbiComponent,
      }, 
      {
        path: "TyTrongTheoVung",
        // canActivate: [AuthGuard],
        component: TytrongvungComponent,
      },             
    ],
  },
  {
    path: "ChiSoGia",
    children: [
      {
        path: "KhuVuc",
        // canActivate: [AuthGuard],
        component: KhuvucComponent,
      },
      {
        path: "CongBo",
        // canActivate: [AuthGuard],
        component: DscongboComponent,
      },      
      {
        path: "DiaPhuongTheoKhuVuc",
        // canActivate: [AuthGuard],
        component: DiaphuongtheokhuvucComponent,
      },
      {
        path: "CongTrinhTheoKhuVuc",
        // canActivate: [AuthGuard],
        component: CongtrinhtheokhuvucComponent,
      },
      {
        path: "CongTrinhTheoVung",
        // canActivate: [AuthGuard],
        component: CongtrinhtheovungComponent,
      },      
      {
        path: "ChiSoGiaTheoVung",
        // canActivate: [AuthGuard],
        component: ChisogiatheovungComponent,
      },      
    ]
  },
  {
    path: "BaoCao",
    children: [
      {
        path: "ChiSoGiaVung",
        // canActivate: [AuthGuard],
        component: BaoCaoVungComponent,
      },
      {
        path: "XayDungCongTrinh",
        // canActivate: [AuthGuard],
        component: XaydungcongtrinhComponent,
      },
      {
        path: "ChiSoGiaTheoCoCauChiPhi",
        // canActivate: [AuthGuard],
        component: ChisogiatheococauchiphiComponent,
      },
      {
        path: "VatLieuNhanCongMayChuYeu",
        // canActivate: [AuthGuard],
        component: VatlieunhancongmayComponent,
      },
      {
        path: "VatLieuNhanCongMay",
        // canActivate: [AuthGuard],
        component: VatlieunhancongmaykhongchuyeuComponent,
      },
    ]
  },
  {
    path: "DanhMuc",
    children: [
      {
        path: "DanhMucVung",
        // canActivate: [AuthGuard],
        component: DanhmucvungComponent,
      },
      {
        path: "DanhMucTinh",
        // canActivate: [AuthGuard],
        component: DanhmuctinhComponent,
      },
      {
        path: "LoaiCongTrinh",
        // canActivate: [AuthGuard],
        component: LoaicongtrinhComponent,
      },
      {
        path: "VatLieuNhanCongMay",
        // canActivate: [AuthGuard],
        component: VatlieuCongnhanMayComponent,
      },
      {
        path: "ChiPhiMuaSamThietBi",
        // canActivate: [AuthGuard],
        component: ChiphimuasamComponent,
      },
      {
        path: "DanhMucCapCongTrinh",
        // canActivate: [AuthGuard],
        component: DanhmuccapcongtrinhComponent,
      },
      {
        path: "DanhMucNhomCongTrinh",
        // canActivate: [AuthGuard],
        component: DanhmucnhomcongtrinhComponent,
      },
      // {
      //   path: "ChonDanhMuc",
      //   component: ChondanhmucComponent,
      // },
      {
        path: "CapNhatDanhMuc",
        // canActivate: [AuthGuard],
        component: CapnhatdanhmucComponent,
      },
      {
        path: "DanhMucUser",
        // canActivate: [AuthGuard],
        component: DanhmucuserComponent,
      },
      {
        path: "SetUser",
        // canActivate: [AuthGuard],
        component: UpdateuserComponent,
      },
      {
        path: "DanhMucDungChung",
        component: DanhMucDungChungComponent,
        // canActivate: [!AuthGuard],
      },
      {
        path: "dmgiaidoanduan",
        component: DmgiaidoanduanComponent,
        // canActivate: [!AuthGuard],
      },
      {
        path: "filemau",
        // canActivate: [AuthGuard],
        component: FilemauComponent,
      }, 
    ]
  },
  {
    path: "ThongTinUser",
    component: ThongtinuserComponent,
    // canActivate: [!AuthGuard],
  },
  {
    path: "HuongDanSuDung",
    children: [
      {
        path: "DangNhap",
        component: HuongdandangnhapComponent,
      },
      {
        path: "DanhSachCSG",
        component: HuongdandanhsachcsgComponent,
      },
      {
        path: "TinhCSG",
        component: HuongdantinhcsgComponent,
      },
      {
        path: "ThuThapDuLieu",
        component: HuongdanthuthapdulieuComponent,
      },
      {
        path: "TyTrong",
        component: HuongdantytrongComponent,
      },
      {
        path: "ChiSoGia",
        component: HuongdanchisogiaComponent,
      },
      {
        path: "BaoCao",
        component: HuongdanbaocaoComponent,
      },
      {
        path: "DanhMuc",
        component: HuongdandanhmucComponent,
      },
      {
        path: "ThuVienDungChung",
        component: ThuviencanhanComponent,
      },
      {
        path: "PhimTat",
        component: ThemmoiComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
