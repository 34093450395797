import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { UpdatekhuvucComponent } from './NgbModal/updatekhuvuc/updatekhuvuc.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-danhmuctinh',
  templateUrl: './danhmuctinh.component.html',
  styleUrls: ['./danhmuctinh.component.css']
})
export class DanhmuctinhComponent implements OnInit {

  ListTINH: any = [];

  itemSELeCT: any = {};

  ListKHUVUC: any = [];
  dataitem: any = {};
  IDdmTinh: any = 0;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
    this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
    this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
    this.GetListdmTinh();

  }

  GetListdmTinh() {
    let data: any = {};
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.ListTINH = res;
      this.ListTINH[0].select = true;
      this.itemSELeCT = this.ListTINH[0];
      this.GetListdmKhuVuc(this.ListTINH[0].ID, this.dataitem.IDThongTinUser);
    });
  }

  GetListdmKhuVuc(IDdmTinh,IDThongTinUser) {
    this.DanhmucService.GetListdmKhuVuc(IDdmTinh, IDThongTinUser).subscribe(res => {
      this.ListKHUVUC = res;
    });
  }
  CheckTREE(item){
    this.ListTINH.forEach(element => {
      if(element.ID == item.ID)
      {
        element.check = true;
        this.IDdmTinh = item.ID;
      }
      else{
        element.check = false;
      }
    });
  }
  chontinh(item) {
    this.itemSELeCT = item;
    this.ListTINH.filter(obj => {
      if (obj.ID == item.ID) {
        item.select = true;
        this.GetListdmKhuVuc(item.ID, this.dataitem.IDThongTinUser);
      }
      else {
        obj.select = false;
      }
    });
  }
  tieptheo(){
    let data = {
      "IDThongTinUser": this.dataitem.IDThongTinUser,
      "IDdmTinh": this.IDdmTinh,
    }
    this.DanhmucService.SetdmTinhTheoThongTinUser(data).subscribe(res => {
      this.ListKHUVUC = res;
    });
    
    this.router.navigate(['/DanhMuc/LoaiCongTrinh']);
  }
  themmoi() {
    const modalRef = this.modalService.open(UpdatekhuvucComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'themmoi';
    modalRef.componentInstance.IDParent = this.itemSELeCT;    
    modalRef.result.then((data) => {
      this.GetListdmKhuVuc(this.itemSELeCT.ID, this.dataitem.IDThongTinUser);
    }, (reason) => {
      // không
    });
  }

  capnhat(item){
    const modalRef = this.modalService.open(UpdatekhuvucComponent, { backdrop: 'static' });
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.IDParent = this.itemSELeCT;    
    modalRef.result.then((data) => {
      this.GetListdmKhuVuc(this.itemSELeCT.ID, this.dataitem.IDThongTinUser);
    }, (reason) => {
      // không
    });
  }

  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmKhuVuc(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmKhuVuc(this.itemSELeCT.ID, this.dataitem.IDThongTinUser);
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }

}
