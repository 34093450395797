import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostService, httpOptions } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class DanhmucService {

  constructor(
    private http: HttpClient
  ) { }

  public danhmucUrl = `${HostService.serverUrl}DanhMuc`;

  // GetListdmTinh() {
  //   let url = `${this.danhmucUrl}/GetListdmTinh`;
  //   return this.http.get<any>(url, httpOptions);
  // }
  GetListdmVatLieuNhanCongMayDungChung(data) {
    let api = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMayDungChung`;
    return this.http.post<any>(api, data, httpOptions);
  }
  GetListdmTinhTheoThongTinUser(IDThongTinUser) {
    let url = `${this.danhmucUrl}/GetListdmTinhTheoThongTinUser?IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmTinh(sFilter) {
    let url = `${this.danhmucUrl}/GetListdmTinh?sFilter=${sFilter}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListThongTinUser(data) {
    let url = `${this.danhmucUrl}/GetListThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListThongTinUserNamTruoc(Nam, NamGoc, IDdmTinh, LoaiTinhCSG) {
    let url = `${this.danhmucUrl}/GetListThongTinUserNamTruoc?Nam=${Nam}&NamGoc=${NamGoc}&IDdmTinh=${IDdmTinh}&LoaiTinhCSG=${LoaiTinhCSG}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmDungChungCongTrinhDienHinh(LoaiDanhMuc, CurrentPage, sFilter, UserName) {
    let url = `${this.danhmucUrl}/GetListdmDungChungCongTrinhDienHinh?LoaiDanhMuc=${LoaiDanhMuc}&CurrentPage=${CurrentPage}&sFilter=${sFilter}&UserName=${UserName}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmDungChung(LoaiDanhMuc, CurrentPage, sFilter, UserName) {
    let url = `${this.danhmucUrl}/GetListdmDungChung?LoaiDanhMuc=${LoaiDanhMuc}&CurrentPage=${CurrentPage}&sFilter=${sFilter}&UserName=${UserName}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmDungChungCongBo(CurrentPage) {
    let url = `${this.danhmucUrl}/GetListdmDungChungCongBo?CurrentPage=${CurrentPage}`;
    return this.http.get<any>(url, httpOptions);
  }
  SetdmDungChungCongTrinhDienHinh(data) {
    let url = `${this.danhmucUrl}/SetdmDungChungCongTrinhDienHinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetdmDungChung(data) {
    let url = `${this.danhmucUrl}/SetdmDungChung`;
    return this.http.post<any>(url, data, httpOptions);
  }
  KiemTraTrungdmDungChung(data) {
    let url = `${this.danhmucUrl}/KiemTraTrungdmDungChung`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeleteThongTinUser(data) {
    let url = `${this.danhmucUrl}/DeleteThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmDungChung(IDdmDungChung) {
    let url = `${this.danhmucUrl}/DeletedmDungChung?IDdmDungChung=${IDdmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdsDungChung(IddmDungChung) {
    let url = `${this.danhmucUrl}/GetListdsDungChung?IddmDungChung=${IddmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetdsDungChungCongTrinhDienHinh(IddmDungChung, IDThongTinUser, Nam, IDdmKhuVuc) {
    let url = `${this.danhmucUrl}/GetdsDungChungCongTrinhDienHinh?IddmDungChung=${IddmDungChung}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}&IDdmKhuVuc=${IDdmKhuVuc}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetdsDungChungCongTrinhDienHinhView(IddmDungChung) {
    let url = `${this.danhmucUrl}/GetdsDungChungCongTrinhDienHinhView?IddmDungChung=${IddmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdsDungChungLoaiVatLieu(IddmDungChung) {
    let url = `${this.danhmucUrl}/GetListdsDungChungLoaiVatLieu?IddmDungChung=${IddmDungChung}`;
    return this.http.get<any>(url, httpOptions);
  }
  SetThongTinUser(data) {
    let url = `${this.danhmucUrl}/SetThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmTinhTheoVung(IDdmVung) {
    let url = `${this.danhmucUrl}/GetListdmTinhTheoVung?IDdmVung=${IDdmVung}`;
    return this.http.get<any>(url, httpOptions);
  }

  SetdmTinh(data) {
    let url = `${this.danhmucUrl}/SetdmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmTinh(data) {
    let url = `${this.danhmucUrl}/DeletedmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetdmTinh(IDdmTinh) {
    let url = `${this.danhmucUrl}/GetdmTinh?IDdmTinh=${IDdmTinh}`;
    return this.http.get<any>(url, httpOptions);
  }

  ImportdmTinh(FileName) {
    let url = `${this.danhmucUrl}/ImportdmTinh?FileName=${FileName}`;
    return this.http.get<any>(url, httpOptions);
  }

  ExportdmTinh(data) {
    let url = `${this.danhmucUrl}/ExportdmTinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  // tỉnh - khu vực

  GetListdmKhuVuc(IDdmTinh, IDThongTinUser) {
    let url = `${this.danhmucUrl}/GetListdmKhuVuc?IDdmTinh=${IDdmTinh}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmTinhTheoUser(data) {
    let url = `${this.danhmucUrl}/GetListdmTinhTheoUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetdmTinhTheoThongTinUser(data) {
    let url = `${this.danhmucUrl}/SetdmTinhTheoThongTinUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetdmKhuVuc(data) {
    let url = `${this.danhmucUrl}/SetdmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetThongTinLuaChon(data) {
    let url = `${this.danhmucUrl}/SetThongTinLuaChon`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmKhuVuc(data) {
    let url = `${this.danhmucUrl}/DeletedmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }

  ImportdmKhuVuc(FileName) {
    let url = `${this.danhmucUrl}/ImportdmKhuVuc?FileName=${FileName}`;
    return this.http.get<any>(url, httpOptions);
  }

  ExportdmKhuVuc(data) {
    let url = `${this.danhmucUrl}/ExportdmKhuVuc`;
    return this.http.post<any>(url, data, httpOptions);
  }

  // loại công trình

  GetListdmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmLoaiCongTrinhLuaChon(data) {
    let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinhLuaChon`;
    return this.http.post<any>(url, data, httpOptions);
  }
  // GetListdmLoaiCongTrinh(IDdmTinh) {
  //   let url = `${this.danhmucUrl}/GetListdmLoaiCongTrinh?IDdmTinh=${IDdmTinh}`;
  //   return this.http.get<any>(url, httpOptions);
  // } 

  SetdmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedsLoaiCongTrinh(IDdsLoaiCongTrinh) {
    let url = `${this.danhmucUrl}/DeletedsLoaiCongTrinh?IDdsLoaiCongTrinh=${IDdsLoaiCongTrinh}`;
    return this.http.get<any>(url, httpOptions);
  }

  ImportdmLoaiCongTrinh(FileName) {
    let url = `${this.danhmucUrl}/ImportdmLoaiCongTrinh?FileName=${FileName}`;
    return this.http.get<any>(url, httpOptions);
  }

  ExportdmLoaiCongTrinh(data) {
    let url = `${this.danhmucUrl}/ExportdmLoaiCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  // loại vật tư

  GetListdmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmThietBiDM(data) {
    let url = `${this.danhmucUrl}/GetListdmThietBiDM`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmVatLieuNhanCongMayDM(data) {
    let url = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMayDM`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmVatLieuNhanCongMayFull(data) {
    let url = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMayFull`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListdmThietBi(IDdmTinh, IDThongTinUser) {
    let url = `${this.danhmucUrl}/GetListdmThietBi?IDdmTinh=${IDdmTinh}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(url, httpOptions);
  }
  // GetListdmVatLieuNhanCongMay(IDdmTinh) {
  //   let url = `${this.danhmucUrl}/GetListdmVatLieuNhanCongMay?IDdmTinh=${IDdmTinh}`;
  //   return this.http.get<any>(url, httpOptions);
  // }

  SetdmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/SetdmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/DeletedmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  ImportdmVatLieuNhanCongMay(FileName, IDThongTinUser) {
    let url = `${this.danhmucUrl}/ImportdmVatLieuNhanCongMay?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}`;
    return this.http.get<any>(url, httpOptions);
  }

  ExportdmVatLieuNhanCongMay(data) {
    let url = `${this.danhmucUrl}/ExportdmVatLieuNhanCongMay`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DangNhap(data) {
    let url = `${this.danhmucUrl}/DangNhap`;
    return this.http.post<any>(url, data, httpOptions);
  }
  //#region user

  GetListUser() {
    let url = `${this.danhmucUrl}/GetListUser`;
    return this.http.get<any>(url, httpOptions);
  }
  DeleteUser(data) {
    let url = `${this.danhmucUrl}/DeleteUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  SetUser(data) {
    let url = `${this.danhmucUrl}/SetUser`;
    return this.http.post<any>(url, data, httpOptions);
  }
  KiemTraPass(Password) {
    let url = `${this.danhmucUrl}/KiemTraPass?Password=${Password}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/GetListdmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmCapCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmCapCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/GetListdmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/SetdmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmNhomCongTrinh(data) {
    let url = `${this.danhmucUrl}/DeletedmNhomCongTrinh`;
    return this.http.post<any>(url, data, httpOptions);
  }

  GetListdmVung() {
    let url = `${this.danhmucUrl}/GetListdmVung`;
    return this.http.get<any>(url, httpOptions);
  }

  GetdmVung(IDdmVung) {
    let url = `${this.danhmucUrl}/GetdmVung?IDdmVung=${IDdmVung}`;
    return this.http.get<any>(url, httpOptions);
  }

  SetdmVung(data) {
    let url = `${this.danhmucUrl}/SetdmVung`;
    return this.http.post<any>(url, data, httpOptions);
  }

  DeletedmVung(data) {
    let url = `${this.danhmucUrl}/DeletedmVung`;
    return this.http.post<any>(url, data, httpOptions);
  }

  ImportdmCongTrinhDienHinh(FileName, IDThongTinUser, Nam, IDdmTinh, IDdmKhuVuc) {
    let url = `${this.danhmucUrl}/ImportdmCongTrinhDienHinh?FileName=${FileName}&IDThongTinUser=${IDThongTinUser}&Nam=${Nam}&IDdmTinh=${IDdmTinh}&IDdmKhuVuc=${IDdmKhuVuc}`;
    return this.http.get<any>(url, httpOptions);
  }

  ExportdmCongTrinhDienHinh(data) {
    let api = `${this.danhmucUrl}/ExportdmCongTrinhDienHinh`;
    return this.http.post<any>(api, data, httpOptions);
  }

  CheckUser() {
    let api = `${this.danhmucUrl}/CheckUser`;
    return this.http.get<any>(api, httpOptions);
  }
  CheckAdmin() {
    let api = `${this.danhmucUrl}/CheckAdmin`;
    return this.http.get<any>(api, httpOptions);
  }
  GetListdmVungTheoUser() {
    let api = `${this.danhmucUrl}/GetListdmVungTheoUser`;
    return this.http.get<any>(api, httpOptions);
  }

  CheckView(TenView) {
    let api = `${this.danhmucUrl}/CheckView?TenView=${TenView}`;
    return this.http.get<any>(api, httpOptions);
  }

  CheckQuyenToanQuoc() {
    let api = `${this.danhmucUrl}/CheckQuyenToanQuoc`;
    return this.http.get<any>(api, httpOptions);
  }

  //#endregion

  GetListdmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/GetListdmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }

  SetdmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/SetdmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }
  DeletedmGiaiDoanDuAn(data) {
    let url = `${this.danhmucUrl}/DeletedmGiaiDoanDuAn`;
    return this.http.post<any>(url, data, httpOptions);
  }
  GetListDuAnCongTrinh(IddmTinh, IddmLoaiCongTrinh) {
    let url = `https://csg.cuckinhtexd.gov.vn/SmartCE/GetListDuAnCongTrinh?IddmTinh=${IddmTinh}&IddmLoaiCongTrinh=${IddmLoaiCongTrinh}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetDuAnCongTrinh(Id) {
    let url = `https://csg.cuckinhtexd.gov.vn/SmartCE/GetDuAnCongTrinh?Id=${Id}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetCongTrinhDienHinhTrenCuc(Id) {
    let url = `${this.danhmucUrl}/GetCongTrinhDienHinhTrenCuc?Id=${Id}`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmTinhCucKinhTe() {
    let url = `https://csg.cuckinhtexd.gov.vn/SmartCE/GetListdmTinh`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListdmLoaiCongTrinhCucKinhTe() {
    let url = `https://csg.cuckinhtexd.gov.vn/SmartCE/GetListdmLoaiCongTrinh`;
    return this.http.get<any>(url, httpOptions);
  }
  fileHelp() {
    let url = `${this.danhmucUrl}/fileHelp`;
    return this.http.get<any>(url, httpOptions);
  }
  GetListDonViBanHanh() {
    let url = `https://csg.cuckinhtexd.gov.vn/SmartCE/GetListDonViBanHanh`;
    return this.http.get<any>(url, httpOptions);
  }
  GetHDSD() {
    let url = `${this.danhmucUrl}/GetHDSD`;
    return this.http.get<any>(url, httpOptions);
  }
}
