import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tytrong',
  templateUrl: './tytrong.component.html',
  styleUrls: ['./tytrong.component.css']
})
export class TytrongComponent implements OnInit {

  item: any = {};
  tenRow: any = "";
  title: any = "";
  Nam: any = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    if (this.item.TendmVatLieuNhanCongMay != undefined) {
      this.title = `${this.item.TendmLoaiCongTrinh} - ${this.item.TendmVatLieuNhanCongMay}`;
    }
    else {
      this.title = `${this.item.TendmLoaiCongTrinh} - ${this.item.TendmKhuVuc}`;
    }
  }

  XacNhan() {
    let data: any = {
      "ID": this.item.ID,
      "TyTrong": this.item.TyTrong,
      "Nam": this.Nam,
      "IDdsLoaiCongTrinh": this.item.IDdsLoaiCongTrinh,
      "IDdsVatLieuNhanCongMay": this.item.IDdsVatLieuNhanCongMay,
      "IDdmKhuVuc": this.item.IDdmKhuVuc,
      "IDThongTinUser": this.item.IDThongTinUser,
      "IDdmTinh": this.item.IDdmTinh,
      "Thang": this.item.Thang,
      "Quy": this.item.Quy,
      // "IDUser": null,
      // "IDUserSua": null
    }
    console.log(data)
    this.ApiService.SetTyTrongPhanXayDung(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.close();
  }

}
