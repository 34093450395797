import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { DanhmucService } from './../../../../services/danhmuc.service';

@Component({
  selector: 'app-luachon-vlncm',
  templateUrl: './luachon-vlncm.component.html',
  styleUrls: ['./luachon-vlncm.component.css']
})
export class LuachonVLNCMComponent implements OnInit {

  Listtree: any = [];
  itemSELeCT: any = {};
  Listpost: any = [];
  ListpostNew: any = [];
  TimChaTree: any = [];
  checkListtree: any = [];
  IDdmKhuVuc: any = 0;
  IDdmTinh: any = 0;
  Nam: any = 0;
  dataitem: any = {};
  header: any = "";
  CheckboxVatLieu: any = false;
  CheckboxNhanCong: any = false;
  CheckboxMay: any = false;
  CheckboxMuaSam: any = false;
  itemVatLieu: any = 0;
  itemNhanCong: any = 0;
  itemMay: any = 0;
  itemMuSam: any = 0;
  TeniconCongTrinh: any = "";
  CheckboxAllCongTrinh: any = false;
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
  ) { }

  ngOnInit() {
    this.Listtree.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });
    for (let i = 0; i < this.Listtree.length; i++) {
      for (let j = 0; j < this.checkListtree.length; j++) {
        if (this.Listtree[i].ID == this.checkListtree[j].IDdmVatLieuNhanCongMay) {
          this.Listtree[i].check = true;
          this.Listtree[i].IDdatontai = this.checkListtree[j].ID;
            for (let m = 0; m < this.Listtree.length; m++) {
              if (this.Listtree[m].ID == this.Listtree[i].IDParent) {
                this.Listtree[m].check = true;
                break;
              }
          }
          break;
        }
        else {
          this.Listtree[i].IDdatontai = 0;
        }
      }
    }
    this.Listtree[0].select = true;
    this.TimChaTree = this.Listtree;
    this.itemSELeCT = this.Listtree[0];
    this.Listtree.forEach(x => {
      if(x.check == false){
        this.TeniconCongTrinh = "Lựa chọn tất cả";
      }
      else
      {
        this.CheckboxAllCongTrinh = true;
        this.TeniconCongTrinh = "Bỏ chọn tất cả";
      }
    });
    this.Listtree = this.unflatten(this.Listtree);
    this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
  }
  GetListdmVatLieuNhanCongMay() {
    let data: any = {
      IDdmTinh: this.IDdmTinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
      Nam: this.dataitem.Nam,
    }
    this.DanhmucService.GetListdmVatLieuNhanCongMayDM(data).subscribe(res => {
      this.Listtree = Object.assign([], res);
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.Listtree = this.unflatten(res);
      this.Listtree[0].select = true;
      this.itemSELeCT = this.Listtree[0]; 
    });
  }
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.ID] = arrElem;
      mappedArr[arrElem.ID]['children'] = [];
    }
    for (var ID in mappedArr) {
      if (mappedArr.hasOwnProperty(ID)) {
        mappedElem = mappedArr[ID];
        if (mappedElem.IDParent != -1) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);
        }
        else {

          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].ID == item.ID ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  CheckTREE(item) {
    item.check = !item.check;
      if(item.children.length > 0){
          this.DeQuyCheckTreeChaCon(item.children, item.check);
      }
      if(item.check){
          this.DeQuyCheckTreeConCha(item, item.check);
      }
      if(item.check == false){
        for (let i = 0; i < this.Listpost.length; i++) {
          if (item.ID == this.Listpost[i].IDdmVatLieuNhanCongMay) {
            this.Listpost.splice(i, 1);
            break;
          }
        }
        if (item.IDdatontai > 0) {
          let data: any = {
            "ID": item.IDdatontai,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "IDdmVatLieuNhanCongMay": item.ID,
            "IDdmTinh": this.IDdmTinh,
            "isXoa": true,
            "Nam": this.Nam,
            "IDThongTinUser" : this.dataitem.IDThongTinUser,
          }
          this.Listpost.push(data);
        }
        if (item.children.length > 0) {
          item.children.forEach(element => {
          console.log(element)
            for (let i = 0; i < this.Listpost.length; i++) {
              if (element.ID == this.Listpost[i].IDdmVatLieuNhanCongMay) {
                this.Listpost.splice(i, 1);
                break;
              }
            }
            if (element.IDdatontai > 0) {
              let data: any = {
                "ID": element.IDdatontai,
                "IDdmKhuVuc": this.IDdmKhuVuc,
                "IDdmVatLieuNhanCongMay": element.ID,
                "IDdmTinh": this.IDdmTinh,
                "isXoa": true,
                "Nam": this.Nam,
                "IDThongTinUser" : this.dataitem.IDThongTinUser,
              }
              this.Listpost.push(data);
            }
          });
        }
      }
      else {
        for (let i = 0; i < this.Listpost.length; i++) {
          if (item.ID == this.Listpost[i].IDdmVatLieuNhanCongMay) {
            this.Listpost.splice(i, 1);
            break;
          }
        }
        this.DeQuyCheckTree(item);
      }
  }
  DeQuyCheckTree(item){
    for (let i = 0; i < this.Listpost.length; i++) {
      if (item.ID == this.Listpost[i].IDdmVatLieuNhanCongMay) {
        this.Listpost.splice(i, 1);
        break;
      }
    }
      let data: any = {
        "ID": item.IDdatontai != undefined ? item.IDdatontai : 0,
        "IDdmKhuVuc": this.IDdmKhuVuc,
        "IDdmVatLieuNhanCongMay": item.ID,
        "IDdmTinh": this.IDdmTinh,
        "isXoa": false,
        "Nam": this.Nam,
        "IDThongTinUser" : this.dataitem.IDThongTinUser,
      }
      this.Listpost.push(data);
    
    item.children.forEach(i => {
      if(i.check == true){
        this.DeQuyCheckTree(i);
    }
  });
}
  CHECKchildrenTREE(Listchildren, value) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].check = value;
      if (value) {
        let data: any = {
          "ID": Listchildren[i].IDdatontai,
          "IDdmKhuVuc": this.IDdmKhuVuc,
          "IDdmVatLieuNhanCongMay": Listchildren[i].IDdmVatLieuNhanCongMay,
          "isXoa": false,
          "Nam": this.Nam,
          "IDThongTinUser" : this.dataitem.IDThongTinUser,
        }
        this.Listpost.push(data);
      }
      else {
        // for (let j = 0; j < this.Listpost.length; j++) {
        //   if (Listchildren[i].ID == this.Listpost[j].ID) {
        //     this.Listpost.splice(j, 1);
        //   }
        // }
        if (Listchildren[i].IDdatontai > 0) {
          let data: any = {
            "ID": Listchildren[i].IDdatontai,
            "IDdmKhuVuc": this.IDdmKhuVuc,
            "IDdmVatLieuNhanCongMay": Listchildren[i].IDdmVatLieuNhanCongMay,
            "isXoa": true,
            "Nam": this.Nam,
            "IDThongTinUser" : this.dataitem.IDThongTinUser,
          }
          this.Listpost.push(data);
        }
        else {
          for (let j = 0; j < this.Listpost.length; j++) {
            if (Listchildren[i].IDdmVatLieuNhanCongMay == this.Listpost[j].IDdmVatLieuNhanCongMay) {
              this.Listpost.splice(j, 1);
              break;
            }
          }
        }
      }
      if (Listchildren[i].children.length > 0) {
        this.CHECKchildrenTREE(Listchildren[i].children, value);
      }
    }
  }

  XacNhan() {
    this.ApiService.SetdsVatLieuNhanCongMay(this.Listpost).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }
  DeQuyCheckTreeChaCon(Listchildren, giatri){
    Listchildren.forEach(element => {
      // if(element.IDParent == item.ID){
        element.check = giatri;
      // }
      this.DeQuyCheckTreeChaCon(element.children, giatri)

    });
  }
  DeQuyCheckTreeConCha(item, giatri){
    this.TimChaTree.forEach(element => {
      if(element.ID == item.IDParent){
            element.check = giatri;
            this.DeQuyCheckTreeConCha(element, giatri)
          }
    });
  }
  DeQuyCheckTreeConChaFalse(listitem,item, giatri){
    listitem.forEach(element => {
      if(element.ID == item.IDParent){
        var checknew = false;
        element.children.forEach(i => {
            if(i.check == true)
            checknew = true;
        });
        if(checknew == false){
          element.check = false
          return;
        }
      }
      this.DeQuyCheckTreeConChaFalse(element.children, item, giatri)

    });
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
  CheckVatLieu(){
    this.CheckboxVatLieu =! this.CheckboxVatLieu;
    if(this.CheckboxVatLieu == true){
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 0){
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
          this.DeQuyCheckTreeVLCNM(element);
        }
      });
    }
    else{
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 0){
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
      }
      });
    }
  }
  CheckNhanCong(){
    this.CheckboxNhanCong =! this.CheckboxNhanCong;
    if(this.CheckboxNhanCong == true){
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 1){
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
          this.DeQuyCheckTreeVLCNM(element);
        }
      });
    }
    else{
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 1){
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
      }
      });
    }
  }
  CheckMay(){
    this.CheckboxMay =! this.CheckboxMay;
    if(this.CheckboxMay == true){
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 2){
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else{
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 2){
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
      }
      });
    }
  }
  CheckMuaSam(){
    this.CheckboxMuaSam =! this.CheckboxMuaSam;
    if(this.CheckboxMay == true){
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 3){
          element.checkbox = true;
          this.dequyCheckBoxVL(element.children, true);
        }
      });
    }
    else{
      this.Listtree.forEach(element => {
        if(element.LoaiDanhMuc == 3){
          element.checkbox = false;
          this.dequyCheckBoxVL(element.children, false)
      }
      });
    }
  }
  dequyCheckBoxVL(listItem , checkbox){
    listItem.forEach(element => {
      element.check = checkbox;
      this.dequyCheckBoxVL(element.children, checkbox)
    });
  }
  DeQuyCheckTreeVLCNM(item){
    let data: any = {
      "ID": item.IDdatontai,
      "IDdmKhuVuc": this.dataitem.IDdmKhuVuc,
      "IDdmTinh": this.dataitem.IDdmTinh, 
      "IDdmVatLieuNhanCongMay": item.ID,
      "isXoa": false,
      "Nam": this.dataitem.Nam,
      "IDThongTinUser": this.dataitem.IDThongTinUser,
    }
    this.Listpost.push(data);
    item.children.forEach(i => {
      if(i.checkbox == true){
        this.DeQuyCheckTreeVLCNM(i);
    }
  });
  }
  ThemMoi() {
    let datanew : any = {};
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaivattu";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;  
    modalRef.result.then((data) => {
      datanew = data.data;
      datanew.collspan = false;
      datanew.check = false;
      datanew.select = false;
      datanew.IDdmVatLieuNhanCongMay = datanew.ID;
      datanew.children = [];
      if(datanew.IDParent == 0){
        this.Listtree.push(datanew);
      }
      else{
        this.Listtree.forEach(element => {
          if(element.ID == datanew.IDParent)
            element.children.push(datanew)
          else
            this.DeQuyTimCon(element.children, datanew);
        });
      }
      
      this.CheckTREE(datanew);
    }, (reason) => {
      // không
    });
  }
  DeQuyTimCon(listitem, item){
    listitem.forEach(element => {
      if(element.ID == item.IDParent){
        element.children.push(item);
        return;
      }
      else{
        this.DeQuyTimCon(element.children, item);
      }
    });
  }
  CheckAllCongTrinh() {
    this.CheckboxAllCongTrinh = !this.CheckboxAllCongTrinh;
    if (this.CheckboxAllCongTrinh == true) {
      this.TeniconCongTrinh = 'Bỏ chọn tất cả';
      this.Listtree.forEach(element => {
        element.check = true;
        this.dequyCheckBox(element.children, true);
        this.DeQuyCheckTree(element);
      });
    }
    else {
      this.TeniconCongTrinh = 'Chọn tất cả';
      this.Listtree.forEach(element => {
        element.check = false;
        this.dequyCheckBox(element.children, false)
      });
    }
    console.log(this.Listpost)
  }
  dequyCheckBox(listItem, checkbox) {
    listItem.forEach(element => {
      element.check = checkbox;
      this.dequyCheckBox(element.children, checkbox)
    });
  }
}
