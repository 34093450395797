import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './../../../../services/api.service';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { Router } from '@angular/router';
import { LuaChonTinhCSGComponent } from 'src/app/login/login/NgbModal/luachontinh/luachontinh.component';
import { MyService } from './../../../../services/my.service';
@Component({
  selector: 'app-capnhapthongtinuser',
  templateUrl: './capnhapthongtinuser.component.html',
  styleUrls: ['./capnhapthongtinuser.component.css']
})
export class CapnhapthongtinuserComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private DanhmucService: DanhmucService,
    private router: Router,
    private MyService: MyService,
  ) { }
  
  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  USER: any = {};
  ListTinhPost: any = [];
  Listtree: any = [];
  ListVLNCM: any = [];
  dataitem: any = {};
  ThongTinUser: any = {};
  ListTinhKhuVuc: any = [];
  listThongTinUser: any = [];
  ngOnInit() {
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
    }
    this.USER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser')));
    this.GetListdmTinhTheoThongTinUser();
    // this.listdmCongTrinh();
  }
  GetListdmTinhTheoThongTinUser() {
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.ItemPOST.ID).subscribe(res => {
      this.ListTinhKhuVuc =  res;
      this.GetListThongTinUser();
    });
  }
  GetListThongTinUser() {
    if(this.ItemPOST.Nam > 0 && this.ItemPOST.NamTinh > 0){
      let IDdmTinhFind : any = 0;
      if(this.ListTinhKhuVuc.length < 1)
        IDdmTinhFind = 0;
      else
        IDdmTinhFind = this.ListTinhKhuVuc[0].ID;
      this.DanhmucService.GetListThongTinUserNamTruoc(this.ItemPOST.NamTinh, this.ItemPOST.Nam, IDdmTinhFind, this.ItemPOST.LoaiTinhCSG).subscribe(res => {
        this.listThongTinUser = res
    });
    }
  }

  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "IDUser": this.USER.Id,
      "Ten": this.ItemPOST.Ten,
      "NgayTao": this.type == 'themmoi' ? null : this.item.property,
      "MoTa": this.ItemPOST.MoTa !== undefined && this.ItemPOST.MoTa !== "" ? this.ItemPOST.MoTa : "",
      "Nam": this.ItemPOST.Nam !== undefined && this.ItemPOST.Nam !== "" ? this.ItemPOST.Nam : "",
      "NamTinh": this.ItemPOST.NamTinh !== undefined && this.ItemPOST.NamTinh !== "" ? this.ItemPOST.NamTinh : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "NoiDung": this.ItemPOST.NoiDung !== undefined && this.ItemPOST.NoiDung !== "" ? this.ItemPOST.NoiDung : "",
      "LoaiTinhCSG": this.ItemPOST.LoaiTinhCSG !== undefined && this.ItemPOST.LoaiTinhCSG !== "" ? this.ItemPOST.LoaiTinhCSG : "",
      "IDThongTinUserNamTruoc": this.ItemPOST.IDThongTinUserNamTruoc !== undefined && this.ItemPOST.IDThongTinUserNamTruoc !== "" ? this.ItemPOST.IDThongTinUserNamTruoc : 0,
    }
    return data;
  }
  listdmTinh() {
    this.DanhmucService.GetListdmTinh("").subscribe(res => {
      this.ListTinhPost = res;
    });
  }
  // listdmCongTrinh() {
  //   let data: any = {
  //     IDdmTinh: 0,
  //   };
  //   this.DanhmucService.GetListdmLoaiCongTrinh(data).subscribe(resdmLoaiCongTrinh => {
  //     this.Listtree =  resdmLoaiCongTrinh;
  //   });
  // }
 
  XacNhan() {
    
    if(this.ItemPOST.Nam == undefined){
      this.toastr.error("Bạn chưa nhập năm gốc");
    }
    else if(this.ItemPOST.NamTinh == undefined){
      this.toastr.error("Bạn chưa nhập năm tính");
    }
    else if(this.ItemPOST.Nam < 0 || this.ItemPOST.Nam.length > 4){
      this.toastr.error("Bạn nhập năm gốc sai định dạng");
    }
    else if(this.ItemPOST.NamTinh < 0 || this.ItemPOST.NamTinh.length > 4){
      this.toastr.error("Bạn nhập năm tính sai định dạng");
    }
    else {
      this.DanhmucService.SetThongTinUser(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          localStorage.setItem('thongtinuser', JSON.stringify(res.objectReturn)); 
          this.ThongTinUser = res.objectReturn;
          this.activeModal.close(
            {data:res.objectReturn}

          );
          // const modalRef = this.modalService.open(LuaChonTinhCSGComponent, {size:'lg', backdrop: 'static' });
          // modalRef.componentInstance.ListTinhPost = this.ListTinhPost;
          // modalRef.componentInstance.Listtree = this.Listtree;
          // modalRef.componentInstance.ListtreeVLNCM = this.ListVLNCM;
          // modalRef.result.then((data) => {

          // }, (reason) => {
          //   // không
          // });
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
    
  }
  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
