import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-thietbi',
  templateUrl: './thietbi.component.html',
  styleUrls: ['./thietbi.component.css']
})
export class ThietbiComponent implements OnInit {

  IDParent: any = {};
  ItemPOST: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.ItemPOST = Object.assign({}, this.IDParent);
  }

  XacNhan() {
    this.ItemPOST.Nam = moment().year();
    this.ApiService.SetGiaThietBi(this.ItemPOST).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }

}
