import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ThemmoitreeComponent } from './NgbModal/themmoitree/themmoitree.component';
import { TreeNode } from './../../../scriptAll/TreeNode';
import { ApiService } from './../../../services/api.service';


@Component({
  selector: 'app-capnhatdanhmuc',
  templateUrl: './capnhatdanhmuc.component.html',
  styleUrls: ['./capnhatdanhmuc.component.css']
})
export class CapnhatdanhmucComponent implements OnInit {

  Listtree: any = [];

  itemSELeCT: any = {};

  files: TreeNode[];

  colschidren: any[];
  colparen: any[];
  col: any[];

  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
  ) {

  }

  ngOnInit() {
    let data: any = {
      IDdmKhuVuc: 5,
      Nam: 2019,
    };
    this.ApiService.GetHeaderTyTrongPhanXayDung(data).subscribe(res => {
      this.colschidren = res.filter(obj => {
        if (obj.SoCotCon == 0) {
          return obj;
        }
      });
      this.colparen = res.filter(obj => {
        if (obj.SoCotCon > 0) {
          return obj;
        }
      });
    });

    this.ApiService.GetListTyTrongPhanXayDung(data).subscribe(res => {
      this.files = res;
    });

    // this.col = [
    //   { field: 'name', header: 'Name' },
    //   { field: 'sizee', header: 'Size' },
    //   { field: 'typee', header: 'Type' }
    // ];
    // let data222: any = [
    //   {
    //     "data": {
    //       "name": "Documents",
    //       "size": "75kb",
    //       "type": "Folder"
    //     },
    //     "children": [
    //       {
    //         "data": {
    //           "name": "Work",
    //           "size": "55kb",
    //           "type": "Folder"
    //         },
    //         "children": [
    //           {
    //             "data": {
    //               "name": "Expenses.doc",
    //               "size": "30kb",
    //               "type": "Document"
    //             }
    //           },
    //           {
    //             "data": {
    //               "name": "Resume.doc",
    //               "size": "25kb",
    //               "type": "Resume"
    //             }
    //           }
    //         ]
    //       },
    //       {
    //         "data": {
    //           "name": "Home",
    //           "size": "20kb",
    //           "type": "Folder"
    //         },
    //         "children": [
    //           {
    //             "data": {
    //               "name": "Invoices",
    //               "size": "20kb",
    //               "type": "Text"
    //             }
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     "data": {
    //       "name": "Pictures",
    //       "size": "150kb",
    //       "type": "Folder"
    //     },
    //     "children": [
    //       {
    //         "data": {
    //           "name": "barcelona.jpg",
    //           "size": "90kb",
    //           "type": "Picture"
    //         }
    //       },
    //       {
    //         "data": {
    //           "name": "primeui.png",
    //           "size": "30kb",
    //           "type": "Picture"
    //         }
    //       },
    //       {
    //         "data": {
    //           "name": "optimus.jpg",
    //           "size": "30kb",
    //           "type": "Picture"
    //         }
    //       }
    //     ]
    //   }
    // ]
    // this.files = data222;
  }

  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parentid) {
          mappedArr[mappedElem['parentid']]['children'].push(mappedElem);
        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  datatree() {
    let arr: any = [
      { 'id': 1, 'parentid': 0 },
      { 'id': 2, 'parentid': 1 },
      { 'id': 3, 'parentid': 1 },
      { 'id': 4, 'parentid': 2 },
      { 'id': 5, 'parentid': 0 },
      { 'id': 6, 'parentid': 0 },
      { 'id': 7, 'parentid': 4 }
    ];

    arr.filter(obj => {
      obj.collspan = true;
      obj.check = false;
      obj.select = false;
    });

    this.Listtree = this.unflatten(arr);
  }

  collpanTREE(item) {
    item.collspan = !item.collspan;
    // if(item.select==true && item.children.length > 0){
    //   item.select = !item.select;      
    // }
  }

  CheckTREE(item) {
    item.check = !item.check;
    if (item.children.length > 0) {
      this.CHECKchildrenTREE(item.children, item.check);
    }
  }

  CHECKchildrenTREE(Listchildren, value) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].check = value;
      if (Listchildren[i].children.length > 0) {
        this.CHECKchildrenTREE(Listchildren[i].children, value);
      }
    }
  }

  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.Listtree.length; i++) {
      this.Listtree[i].select = this.Listtree[i].id == item.id ? item.select : false;
      if (this.Listtree[i].children.length > 0) {
        this.SelectchildrenTREE(this.Listtree[i].children, item);
      }
    }
  }

  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].id == item.id ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  themmoiTREE() {
    const modalRef = this.modalService.open(ThemmoitreeComponent, { backdrop: 'static' });
    modalRef.componentInstance.dataitem = this.itemSELeCT;
    modalRef.result.then((data) => {
      // 0 : Thêm mới cùng cấp
      // 1 : Thêm mới cấp con
      // let tree: any = {};
      // tree = data.itemADD;
      this.itemSELeCT.children.push(data.itemADD);
      this.Listtree = this.Listtree;
      // this.PUShList(this.itemSELeCT,tree);
    }, (reason) => {
      // không
    });
  }

  xoaTREE() {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {

    }, (reason) => {
      // không
    });
  }

}
