import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chondanhmuc',
  templateUrl: './chondanhmuc.component.html',
  styleUrls: ['./chondanhmuc.component.css']
})
export class ChondanhmucComponent implements OnInit {

  Listtree: any = [];

  constructor() {

  }

  ngOnInit() {
    this.datatree();
  }

  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parentid) {
          mappedArr[mappedElem['parentid']]['children'].push(mappedElem);
        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  datatree() {
    let arr: any = [
      { 'id': 1, 'parentid': 0 },
      { 'id': 2, 'parentid': 1 },
      { 'id': 3, 'parentid': 1 },
      { 'id': 4, 'parentid': 2 },
      { 'id': 5, 'parentid': 0 },
      { 'id': 6, 'parentid': 0 },
      { 'id': 7, 'parentid': 4 }
    ];

    arr.filter(obj => {
      obj.select = true;
      obj.check = false;
    });

    this.Listtree = this.unflatten(arr);
  }

  collpanTREE(item) {
    item.select = !item.select;
    // if(item.select==true && item.children.length > 0){
    //   item.select = !item.select;      
    // }
  }

  CheckTREE(item) {
    item.check = !item.check;
    if (item.children.length > 0) {
      this.CHECKchildrenTREE(item.children, item.check);
    }
  }

  CHECKchildrenTREE(Listchildren, value) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].check = value;
      if (Listchildren[i].children.length > 0) {
        this.CHECKchildrenTREE(Listchildren[i].children, value);
      }
    }
  }

}
