import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../../../services/danhmuc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-capnhatdanhmucvung',
  templateUrl: './capnhatdanhmucvung.component.html',
  styleUrls: ['./capnhatdanhmucvung.component.css']
})
export class CapnhatdanhmucvungComponent implements OnInit {

  type: any = '';
  item: any = {};
  ItemPOST: any = {};
  header: any = "";
  IDParent: any = {};
  ListAllTINH: any = [];

  constructor(
    private activeModal: NgbActiveModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.ListAllTINH.filter(obj => {
      obj.check = false;
      obj.isXoa = false;
    });
    if (this.type == 'themmoi') {
      this.header = "Thêm mới";
      this.ItemPOST.listTinh = [];
    }
    else {
      this.header = "Cập nhật";
      this.ItemPOST = Object.assign({}, this.item);
      for (let i = 0; i < this.ItemPOST.listTinh.length; i++) {
        for (let j = 0; j < this.ListAllTINH.length; j++) {
          if (this.ItemPOST.listTinh[i].ID == this.ListAllTINH[j].ID) {
            this.ListAllTINH[j].check = true;
          }
        }
      }
    }
  }

  SetData() {
    let data: any = {
      "ID": this.type == 'themmoi' ? 0 : this.item.ID,
      "Ma": this.ItemPOST.Ma !== undefined && this.ItemPOST.Ma !== "" ? this.ItemPOST.Ma : "",
      "Ten": this.ItemPOST.Ten !== undefined && this.ItemPOST.Ten !== "" ? this.ItemPOST.Ten : "",
      "GhiChu": this.ItemPOST.GhiChu !== undefined && this.ItemPOST.GhiChu !== "" ? this.ItemPOST.GhiChu : "",
      "listTinh": this.ItemPOST.listTinh,
    }
    return data;
  }

  checklistTinh(item) {
    item.check = !item.check;
    if (item.check) {
      this.ItemPOST.listTinh.push(item);
    }
    else {
      for (let i = 0; i < this.ItemPOST.listTinh.length; i++) {
        if (item.ID == this.ItemPOST.listTinh[i].ID) {
          if (this.type == 'themmoi') {
            this.ItemPOST.listTinh.splice(i, 1);
            break;
          }
          else {
            this.ItemPOST.listTinh[i].isXoa = true;
            break;
          }
        }
      }
    }
  }

  checkdata() {
    if (this.ItemPOST.Ma != undefined && this.ItemPOST.Ma != null && this.ItemPOST.Ma != "" &&
      this.ItemPOST.Ten != undefined && this.ItemPOST.Ten != null && this.ItemPOST.Ten != ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  XacNhan() {
    if (this.checkdata()) {
      this.DanhmucService.SetdmVung(this.SetData()).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.Onclose();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }
    else {
      this.toastr.error("Yêu cầu nhập đầy đủ trường bắt buộc");
    }
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.dismiss();
  }
}
