import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CapnhapthongtinuserComponent } from './NgbModal/capnhapthongtinuser/capnhapthongtinuser.component';
import { LuaChonPhuongThucCSGComponent } from 'src/app/login/login/NgbModal/luachoncsg/luachoncsg.component';
import * as moment from 'moment';

@Component({
  selector: 'app-thongtinuser',
  templateUrl: './thongtinuser.component.html',
  styleUrls: ['./thongtinuser.component.css']
})
export class ThongtinuserComponent implements OnInit {

  dataitem: any = {};
  data: any = {};
  currentUser: any = {};
  listIPOS: any = [];
  IDUSER : any= "";
  Nam : any= 0;
  listnam: any = [];
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private toastr: ToastrService,
  ) {
  }
  ngOnInit() {
    this.dataitem.sFilter = "";
    this.data = {
      CurrentPage: 1,
      PageSize: 25,
      sFilter: "",
      IDUser: this.IDUSER,
      Nam: this.Nam,
    };
    this.dataitem.paging={};
    this.dataitem.TotalItem = 0;
    this.IDUSER = Object.assign({}, JSON.parse(localStorage.getItem('currentUser'))).Id;
    this.getnam();
    this.GetListThongTinUser();
  }
  GetListThongTinUser() {
    let data = {
      CurrentPage: this.data.CurrentPage,
      PageSize: 25,
      sFilter: this.dataitem.sFilter,
      IDUser: this.IDUSER,
      Nam: this.Nam,
    };
    this.DanhmucService.GetListThongTinUser(data).subscribe(res => {
      this.listIPOS = res.items;
      this.dataitem.paging = res.paging;
      this.dataitem.TotalItem = res.paging.TotalItem;
    });
  }
  getnam() {
    let data: any = { 
      ma: 0,
      nam: 'Tất cả' }
    this.listnam.push(data);
    for (let i = moment().year() - 10; i <= moment().year() + 1; i++) {
      let data: any = { 
        ma: i,
        nam: i }
      this.listnam.push(data);
    }
    this.Nam = this.listnam[0].ma;
  } 
  // themmoi() {
  //   const modalRef = this.modalService.open(CapnhapthongtinuserComponent, {size:'lg' ,  backdrop: 'static' });
  //   modalRef.componentInstance.type = 'themmoi';  
  //   modalRef.result.then((data) => {
  //     this.GetListThongTinUser();
  //   }, (reason) => {
  //     // không
  //   });
  // }
  themmoi() {
    const modalRef = this.modalService.open(LuaChonPhuongThucCSGComponent, {backdrop: 'static', centered: true });
    modalRef.result.then(() => {
    }, (reason) => {
    });
  }
  capnhat(item) {
    const modalRef = this.modalService.open(CapnhapthongtinuserComponent, {size:'lg' , backdrop: 'static'});
    modalRef.componentInstance.type = 'capnhat';
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      this.GetListThongTinUser();
    }, (reason) => {
      // không
    });
  }
  luachonVLNCM(item) {
    // this.MyService.checklogin = true;                
    this.router.navigate(['/ThongTinChung/GiaVatLieuNhanCongMay']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  luachonThietBi(item) {
    this.router.navigate(['/ThongTinChung/GiaThietBi']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  luachonCongTrinhDienHinh(item) {
    this.router.navigate(['/ThongTinChung/CongTrinhDienHinh']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  tytrong(item) {
    this.router.navigate(['/ThongTinChung/TyTrongVLNCM']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  tinhchisogia(item) {
    this.router.navigate(['/ChiSoGia/KhuVuc']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  baocao(item) {
    this.router.navigate(['/BaoCao/XayDungCongTrinh']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  danhmuckhuvuc(item) {
    this.router.navigate(['/DanhMuc/DanhMucTinh']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  danhmucloaicongtrinh(item) {
    this.router.navigate(['/DanhMuc/LoaiCongTrinh']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  danhmucvatlieunhancongmay(item) {
    this.router.navigate(['/DanhMuc/VatLieuNhanCongMay']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  congbo(item) {
    this.router.navigate(['/ChiSoGia/CongBo']);   
    localStorage.setItem('thongtinuser', JSON.stringify(item));     
  }
  xoa(item){
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeleteThongTinUser(item).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListThongTinUser();
        }
        else {
          this.toastr.error(res.message);      
        }
      });      
    }, (reason) => {
      // không
    });
  }
  refresh() {
    this.Nam = 0;
    let data = {
      CurrentPage: 1,
      PageSize: 25,
      sFilter: "",
      IDUser: this.IDUSER,
      Nam: this.Nam,
    };
    this.DanhmucService.GetListThongTinUser(data).subscribe(res => {
      this.listIPOS = res.items;
      this.dataitem.paging = res.paging;
      this.dataitem.TotalItem = res.paging.TotalItem;
    });
  }
}
