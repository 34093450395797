import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DanhmucService } from './../../services/danhmuc.service';
import { MyService } from './../../services/my.service';
import { ThongbaoComponent } from 'src/app/NgbModalAll/thongbao/thongbao.component';
import { ThemmoitreeComponent } from 'src/app/NgbModalAll/themmoitree/themmoitree.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vatlieu-congnhan-may',
  templateUrl: './vatlieu-congnhan-may.component.html',
  styleUrls: ['./vatlieu-congnhan-may.component.css']
})
export class VatlieuCongnhanMayComponent implements OnInit {
  dataCheck: any = {
    itemCheck: {},
    List_Check: [],
  }
  ListtreeVL: any = [];
  listtinh: any = [];
  List: any = [];
  dataitem: any = {};

  itemSELeCT: any = {};

  constructor(
    private modalService: NgbModal,
    private DanhmucService: DanhmucService,
    private MyService: MyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem('thongtinuser')) != null || JSON.parse(localStorage.getItem('thongtinuser')) != undefined)
    {
      this.dataitem.IDThongTinUser = JSON.parse(localStorage.getItem('thongtinuser')).ID;
      this.dataitem.Nam = JSON.parse(localStorage.getItem('thongtinuser')).NamTinh;
      this.dataitem.NamGoc = JSON.parse(localStorage.getItem('thongtinuser')).Nam;
      this.dataitem.TenUser = JSON.parse(localStorage.getItem('thongtinuser')).Ten;
    }
    this.GetListdmTinh();
  }

  GetListdmTinh() {
    this.dataitem.IDdmTinh = undefined;
    this.listtinh = [];
    this.DanhmucService.GetListdmTinhTheoThongTinUser(this.dataitem.IDThongTinUser || 0).subscribe(res => {
      this.listtinh = res;
      if(res.length > 0)
        this.dataitem.IDdmTinh = res[0].ID;
      this.GetListdmVatLieuNhanCongMay();
    });
  }
  GetListdmVatLieuNhanCongMay(isThemMoi?) {
    let data: any = {
      IDdmTinh: this.dataitem.IDdmTinh,
      IDThongTinUser: this.dataitem.IDThongTinUser,
      Nam: this.dataitem.Nam,
    }
    this.DanhmucService.GetListdmVatLieuNhanCongMayDM(data).subscribe(res => {
      this.List = Object.assign([], res);
      res.filter(obj => {
        obj.collspan = true;
        obj.check = false;
        obj.select = false;
      });
      this.ListtreeVL = this.unflatten(res);

      if (this.itemSELeCT.ID === undefined || this.itemSELeCT.ID === null) {
        this.ListtreeVL[0].select = true;
        this.itemSELeCT = this.ListtreeVL[0];
      }
      else {
        let itemFind = res.filter(e => e.ID === this.itemSELeCT.ID);
        if (itemFind != undefined) {
          itemFind[0].select = true;
          this.itemSELeCT = itemFind[0];
        }

      }
      this.ListtreeVL.forEach(element => {
        let dataFind = this.dataCheck.List_Check.find(ele => ele.ID == element.ID);
        if (dataFind !== undefined) {
          if (dataFind.collspan === false)
            element.collspan = false
          this.deQuydmLoaiCongTrinh(element.children, dataFind.children, element);
        }
      });

    });
  }
  deQuydmLoaiCongTrinh(listChild, listChildCheck, itemParent) {
    listChild.forEach(elChi => {
      let itemCheck = listChildCheck.find(ele => ele.ID == elChi.ID);

      if (itemParent.collspan == false) {
        elChi.collspan = false;
      }
      else if (itemCheck !== undefined) {
        if (itemCheck.collspan === false)
          elChi.collspan = false;
      }
      if (itemCheck === undefined)
        itemCheck = {};
      this.deQuydmLoaiCongTrinh(elChi.children, itemCheck.children, elChi);
    });
  }

  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.ID] = arrElem;
      mappedArr[arrElem.ID]['children'] = [];
    }
    for (var ID in mappedArr) {
      if (mappedArr.hasOwnProperty(ID)) {
        mappedElem = mappedArr[ID];
        if (mappedElem.ID > 0) {
          mappedArr[mappedElem['IDParent']]['children'].push(mappedElem);
        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
  SelectTREE(item) {
    this.itemSELeCT = item;
    item.select = true;
    for (let i = 0; i < this.ListtreeVL.length; i++) {
      this.ListtreeVL[i].select = this.ListtreeVL[i].ID == item.ID ? item.select : false;
      if (this.ListtreeVL[i].children.length > 0) {
        this.SelectchildrenTREE(this.ListtreeVL[i].children, item);
      }
    }
  }
  
  SelectchildrenTREE(Listchildren, item) {
    for (let i = 0; i < Listchildren.length; i++) {
      Listchildren[i].select = Listchildren[i].ID == item.ID ? item.select : false;
      if (Listchildren[i].children.length > 0) {
        this.SelectchildrenTREE(Listchildren[i].children, item);
      }
    }
  }

  themmoiTREE() {
    this.dataCheck.List_Check = this.ListtreeVL;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "themmoi";
    modalRef.componentInstance.LoaidanhMuc = "Loaivattu";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;  
    modalRef.result.then((data) => {
      this.dataCheck.itemCheck = data.data;
      this.GetListdmVatLieuNhanCongMay(true);
    }, (reason) => {
      // không
    });
  }

  capnhatTREE() {
    this.dataCheck.List_Check = this.ListtreeVL;
    const modalRef = this.modalService.open(ThemmoitreeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.type = "capnhat";
    modalRef.componentInstance.LoaidanhMuc = "Loaivattu";
    modalRef.componentInstance.IDParent = this.itemSELeCT;
    modalRef.componentInstance.Listtree = this.ListtreeVL;
    modalRef.componentInstance.List = this.List;
    modalRef.componentInstance.IDdmTinh = this.dataitem.IDdmTinh;   
    modalRef.result.then((data) => {
      this.GetListdmVatLieuNhanCongMay();
    }, (reason) => {
      // không
    });
  }

  xoaTREE() {
    const modalRef = this.modalService.open(ThongbaoComponent, { backdrop: 'static' });
    modalRef.componentInstance.thongbao = "Bạn có chắc chắn muốn xóa?";
    modalRef.result.then((any) => {
      this.DanhmucService.DeletedmVatLieuNhanCongMay(this.itemSELeCT).subscribe(res => {
        if (res.State === 1) {
          this.toastr.success(res.message);
          this.GetListdmVatLieuNhanCongMay();
        }
        else {
          this.toastr.error(res.message);
        }
      });
    }, (reason) => {
      // không
    });
  }


}
