import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTrueFalse',pure:false
})
export class FilterTrueFalsePipe implements PipeTransform {

  transform(item: Array<any>): Array<any> {
    if (!!item) {
      return item = item.filter(obj => {
        if (!obj.isXoa) {
          return obj;
        }
      });
    }
    else {
      return null;
    }
  }
}
