import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-nhaptytrong',
  templateUrl: './nhaptytrong.component.html',
  styleUrls: ['./nhaptytrong.component.css']
})
export class NhaptytrongKhuVucComponent implements OnInit {

  IDParent: any = {};
  ItemPOST: any = {};
  Nam: any;
  item: any = {};
  title: any = "";
  constructor(
    private activeModal: NgbActiveModal,
    private ApiService: ApiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.ItemPOST = Object.assign({}, this.item);
  }

  XacNhan() {
    let data: any = {}
    data = this.ItemPOST;
    data.TyTrong = this.item.TyTrong
    this.ApiService.SetTyTrongDiaPhuong(data).subscribe(res => {
      if (res.State === 1) {
        this.toastr.success(res.message);
        this.Onclose();
      }
      else {
        this.toastr.error(res.message);
      }
    });
  }

  Onclose() {
    this.activeModal.close();
  }

  Ondismiss() {
    this.activeModal.close();
  }
}